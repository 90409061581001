import React from "react"
import { Link } from "react-router-dom"
import Date from "../utils/Date"
import Sweetalert from "../utils/Sweetalert"
import API from "../utils/API"

export default function TotemValidateTableItem({ totem, deleteTotem }) {
  // State

  // Comportements
  const handleDelete = (id) => {
    Sweetalert.fireDeleteConfirm("Êtes-vous sûr ?", "Une fois supprimé, impossible de le récupérer", () => {
      API.DELETE("/admin/totem/" + id, (response) => {
        Sweetalert.fireSuccessToast(response.data.message)
        deleteTotem(id)
      })
    })
  }

  // Rendu
  return (
    <tr>
      <td>{totem.id}</td>
      <td>{Date.formatDate(totem.createdAt)}</td>
      <td className="project-actions text-right">
        <Link to={"/totem/validation/" + totem.id} className="btn btn-success btn-xs mr-2">
          <i className="fas fa-check"></i>
          <span className="ml-1">Valider</span>
        </Link>
        <button className="btn btn-danger btn-xs" onClick={() => handleDelete(totem.id)}>
          <i className="fas fa-trash"></i>
          <span className="ml-1">Supprimer</span>
        </button>
      </td>
    </tr>
  )
}
