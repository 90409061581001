import React, { useMemo, useState } from "react"
import { Link, useParams } from "react-router-dom"
import ContentHeader from "../components/ContentHeader"
import ContentMain from "../components/ContentMain"
import ContentWrapper from "../components/ContentWrapper"
import UserManageTable from "../components/UserManageTable"
import UserManageForm from "../components/UserManageForm"
import API from "../utils/API"

export default function MainUserManage({ addUser }) {
  // State
  let { teamId } = useParams()
  const [teams, setTeams] = useState([])
  const [teamsLoaded, setTeamsLoaded] = useState(false)

  // Comportements
  const getTeams = () => {
    API.GET("/admin/team", (response) => {
      setTeams(response.data)
      setTeamsLoaded(true)
    })
  }

  useMemo(() => {
    getTeams()
  }, [])

  // Rendu
  return (
    <ContentWrapper>
      <ContentHeader>
        <div className="col-sm-6">
          <h1 className="m-0">Gestion Utilisateurs</h1>
        </div>
        <div className="col-sm-6">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item">
              <Link to={"/"}>Accueil</Link>
            </li>
            {addUser ? (
              <>
                <li className="breadcrumb-item">
                  <Link to={"/utilisateur/gestion"}>Utilisateur</Link>
                </li>
                <li className="breadcrumb-item active">Nouveau</li>
              </>
            ) : (
              <li className="breadcrumb-item active">Utilisateur</li>
            )}
          </ol>
        </div>
      </ContentHeader>
      <ContentMain>
        <div className="row">
          <div className="col">
            {addUser ? (
              <UserManageForm teams={teams} teamsLoaded={teamsLoaded} teamId={teamId} />
            ) : (
              <UserManageTable teams={teams} teamsLoaded={teamsLoaded} refreshTeams={getTeams} />
            )}
          </div>
        </div>
      </ContentMain>
    </ContentWrapper>
  )
}
