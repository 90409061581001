import React from "react"
import DateUtils from "../utils/Date"
import API from "../utils/API"
import Sweetalert from "../utils/Sweetalert"
import { Link } from "react-router-dom"

export default function ClubActivityTableItem({ activity, deleteActivity }) {
  // State

  // Comportement
  const handleDelete = (id) => {
    Sweetalert.fireDeleteConfirm(
      "Êtes-vous sûr ?",
      "Cette activité ne sera plus affichée sur les écrans associés",
      () => {
        API.DELETE("/club/activity/" + id, (response) => {
          Sweetalert.fireSuccessToast(response.data.message)
          deleteActivity(id)
        })
      }
    )
  }

  // Rendu
  return (
    <tr>
      <td>{DateUtils.formatDate(activity.date)}</td>
      <td>{activity.ad.name}</td>
      <td>{DateUtils.isLater(activity.date) ? "Actif" : "Expiré"}</td>
      {activity.registerUrl ? (
        <>
          <td>Non</td>
          <td>-</td>
          <td>-</td>
        </>
      ) : (
        <>
          <td>Oui</td>
          <td>
            {activity.participantCount}/{activity.maxParticipant}
          </td>
          <td>
            {activity.waitingCount}/{activity.maxWaitingList}
          </td>
        </>
      )}
      <td>
        {activity.totems.map((totem) => (
          <span key={totem.id} className="badge badge-secondary mr-1">
            {totem.name}
          </span>
        ))}
      </td>
      <td className="project-actions text-right">
        <Link
          to={`${process.env.REACT_APP_API_HOST}/club/activity/${activity.id}/participantslist`}
          target="_blank"
          rel="noreferrer"
          className="btn btn-success btn-xs mr-2"
        >
          <i className="fas fa-download"></i>
          <span className="ml-1">Inscrits</span>
        </Link>
        <Link to={"./" + activity.id} className="btn btn-primary btn-xs mr-2">
          <i className="fas fa-folder"></i>
          <span className="ml-1">Détails</span>
        </Link>
        <button className="btn btn-danger btn-xs" onClick={() => handleDelete(activity.id)}>
          <i className="fas fa-trash"></i>
          <span className="ml-1">Supprimer</span>
        </button>
      </td>
    </tr>
  )
}
