import Swal from "sweetalert2"

const fireSuccessToast = (message) => {
  Swal.fire({
    //toast: true,
    position: "center",
    icon: "success",
    //title: message,
    showConfirmButton: false,
    timer: 750,
    //timerProgressBar: true,
  })
}

const fireWarningToast = (message) => {
  Swal.fire({
    position: "center",
    icon: "warning",
    title: message,
    showConfirmButton: true,
    confirmButtonColor: "#3085d6",
  })
}

const fireErrorToast = (message) => {
  Swal.fire({
    position: "center",
    icon: "error",
    title: message,
    showConfirmButton: true,
    confirmButtonColor: "#3085d6",
  })
}

const fireConfirm = (title, message, callback) => {
  Swal.fire({
    icon: "question",
    title: title,
    text: message,
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    confirmButtonText: "Valider",
    cancelButtonColor: "#d33",
    cancelButtonText: "Annuler",
  }).then((response) => {
    if (response.isConfirmed) callback()
  })
}

const fireDeleteConfirm = (title, message, callback) => {
  Swal.fire({
    icon: "warning",
    title: title,
    text: message,
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    confirmButtonText: "Oui, supprimer !",
    cancelButtonColor: "#d33",
    cancelButtonText: "Annuler",
  }).then((response) => {
    if (response.isConfirmed) callback()
  })
}

const fireImportant = (title, message) => {
  Swal.fire({
    icon: "warning",
    title: title,
    text: message,
    confirmButtonColor: "#3085d6",
    confirmButtonText: "Compris !",
    allowOutsideClick: false,
  })
}

const fireInfo = (title, message) => {
  Swal.fire({
    icon: "info",
    title: title,
    text: message,
    confirmButtonColor: "#3085d6",
    confirmButtonText: "Fermer",
    allowOutsideClick: false,
  })
}

const askName = (title, message, placeholder, callback) => {
  Swal.fire({
    icon: "question",
    title: title,
    text: message,
    input: "text",
    inputAttributes: {
      required: true,
      placeholder: placeholder,
    },
    validationMessage: `Le nom ne doit pas être vide`,
    confirmButtonColor: "#3085d6",
    confirmButtonText: "Valider",
    showCancelButton: true,
    cancelButtonColor: "#d33",
    cancelButtonText: "Annuler",
    allowOutsideClick: false,
  }).then((response) => {
    if (response.isConfirmed) callback(response)
  })
}

const askNumber = (title, message, min, max, placeholder, callback) => {
  Swal.fire({
    icon: "question",
    title: title,
    text: message,
    input: "number",
    inputAttributes: {
      min: min,
      max: max,
      required: true,
      placeholder: placeholder,
    },
    validationMessage: `La valeur doit être comprise entre ${min} et ${max}`,
    confirmButtonColor: "#3085d6",
    confirmButtonText: "Valider",
    showCancelButton: true,
    cancelButtonColor: "#d33",
    cancelButtonText: "Annuler",
    allowOutsideClick: false,
  }).then((response) => {
    if (response.isConfirmed) callback(response)
  })
}

const Sweetalert = {
  fireSuccessToast,
  fireWarningToast,
  fireErrorToast,
  fireConfirm,
  fireDeleteConfirm,
  fireImportant,
  fireInfo,
  askName,
  askNumber,
}

export default Sweetalert
