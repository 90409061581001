import React from "react"
import ButtonCollapse from "./ButtonCollapse"

export default function HelperCard({ className, title, children }) {
  return (
    <div className={className}>
      <div className="card card-outline card-info">
        <div className="card-header">
          <h3 className="card-title">{title}</h3>
          <div className="card-tools">
            <ButtonCollapse />
          </div>
        </div>
        <div className="card-body">{children}</div>
      </div>
    </div>
  )
}
