import React from "react"

export default function DiffusionTotemCardTotem({ totem, selectTotem }) {
  return (
    <div className="m-3" onClick={() => selectTotem(totem)}>
      <button type="button" className="btn btn-block btn-light p-0">
        <div className="info-box bg-transparent mb-0 p-0">
          <div className="info-box-content">
            <div className="d-flex justify-content-between">
              <span>
                <i className="fas fa-tv"></i> <b>{totem.name}</b>{" "}
                <i
                  className="fas fa-circle"
                  title={totem.state ? "En ligne" : "Hors ligne"}
                  style={{ color: totem.state ? "green" : "red" }}
                ></i>
              </span>
              <span>
                <i className="fas fa-broadcast-tower"></i> <em>{totem.team_totem.duration} secondes</em>
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <span>
                <i className="fas fa-map-marker" style={{ width: "20px" }}></i> {totem.address || "-"}
              </span>
              <span>
                {totem.width}x{totem.height}
              </span>
            </div>
          </div>
        </div>
      </button>
    </div>
  )
}
