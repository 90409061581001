import React from "react"

export default function Footer() {
  return (
    <footer className="main-footer">
      {/* To the right */}
      <div className="float-right d-none d-sm-inline">Guillemin Affichage WebApp v{process.env.REACT_APP_VERSION}</div>
      {/* Default to the left */}
      <strong>
        Copyright © 2023 <a href="https://guilleminaffichage.fr">Guillemin Affichage</a>.
      </strong>{" "}
      Tous droits réservés.
    </footer>
  )
}
