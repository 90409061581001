import React, { useContext } from "react"
import { Link } from "react-router-dom"
import Sweetalert from "../utils/Sweetalert"
import UserContext from "../UserContext"
import API from "../utils/API"

export default function TeamManageTableItem({ team, deleteTeam }) {
  // State
  const userContext = useContext(UserContext)
  const roleColor = {
    1: "red",
    2: "purple",
    3: "green",
    4: "blue",
  }

  // Comportements
  const handleDelete = (id) => {
    Sweetalert.fireDeleteConfirm(
      "Êtes-vous sûr ?",
      "Supprimer une équipe de cette liste supprimera toute donnée associée à cette dernière.",
      () => {
        API.DELETE("/admin/team/" + id, (response) => {
          Sweetalert.fireSuccessToast(response.data.message)
          deleteTeam(id)
        })
      }
    )
  }

  // Rendu
  return (
    <tr>
      <td>
        <span title={team.id}>#</span>
      </td>
      <td>
        {team.name} {userContext.team.id === team.id ? <strong>(Mon équipe)</strong> : ""}
      </td>
      <td>
        <i className="fas fa-user-tag" style={{ color: roleColor[team.role.id] }}></i> {team.role.name}
      </td>
      <td>{team.establishmentType !== null ? team.establishmentType.name : "-"}</td>
      <td className="project-actions text-right">
        <Link to={"/equipe/gestion/" + team.id} className="btn btn-primary btn-xs mr-2">
          <i className="fas fa-folder"></i>
          <span className="ml-1">Gestion</span>
        </Link>
        <button className="btn btn-danger btn-xs" onClick={() => handleDelete(team.id)}>
          <i className="fas fa-trash"></i>
          <span className="ml-1">Supprimer</span>
        </button>
      </td>
    </tr>
  )
}
