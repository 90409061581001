import React, { useContext, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import Sweetalert from "../utils/Sweetalert"
import Select from "./Select"
import API from "../utils/API"
import UserContext from "../UserContext"
import LoadingCard from "./LoadingCard"

export default function TotemValidateForm({ totemId }) {
  // State
  const navigate = useNavigate()
  const [loaded, setLoaded] = useState(false)
  const [teams, setTeams] = useState([])
  const [totemName, setTotemName] = useState("")
  const [totemAddress, setTotemAddress] = useState("")
  const [totemEstablishmentTypeId, setTotemEstablishmentTypeId] = useState("")
  const [totemHasNav, setTotemHasNav] = useState(true)
  const [totemLink, setTotemLink] = useState("")
  const [totemNavTeamId, setTotemNavTeamId] = useState("")
  const [totemIsLed, setTotemIsLed] = useState(true)
  const [totemWidth, setTotemWidth] = useState(1920)
  const [totemHeight, setTotemHeight] = useState(1080)
  const [error, setError] = useState("")
  const userContext = useContext(UserContext)

  // Comportements
  const getTeams = () => {
    API.GET("/admin/team", (response) => {
      setTeams(response.data)
      setLoaded(true)
    })
  }

  useMemo(() => {
    getTeams()
  }, [])

  const clubs = useMemo(() => {
    return teams.filter((team) => team.establishmentType && team.establishmentType.id === 2) // filtre sur les clubs
  }, [teams])

  const handleSubmit = (event) => {
    event.preventDefault()
    setError("")

    Sweetalert.fireConfirm(
      "Validez vous ces données ?",
      "Elles pourront être modifiées ultérieurement dans l'onglet Gestion",
      () => {
        let link = null
        let navTeamId = null
        if (totemEstablishmentTypeId === 1 && totemHasNav) link = totemLink
        if (totemEstablishmentTypeId === 2 && totemHasNav) navTeamId = totemNavTeamId
        API.POST(
          "/admin/totem/validate/" + totemId,
          {
            name: totemName,
            address: totemAddress || null,
            establishmentType: {
              id: totemEstablishmentTypeId,
              name: userContext.establishmentTypes.find(({ id }) => id === totemEstablishmentTypeId).name,
            },
            hasNav: totemHasNav,
            navTeamId: navTeamId,
            link: link,
            isLed: totemIsLed,
            width: totemWidth,
            height: totemHeight,
          },
          (response) => {
            Sweetalert.fireSuccessToast(response.data.message)
            navigate(-1)
          },
          setError
        )
      }
    )
  }

  // Rendu
  return (
    <div className="card card-primary">
      <div className="card-header">
        <h3 className="card-title">Validation d'un player</h3>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="card-body">
          <LoadingCard loaded={loaded}>
            <h5>Identifiant de l'écran : {totemId}</h5>
            <div className="form-group">
              <label>Nom de l'écran</label>
              <input
                type="text"
                className="form-control"
                placeholder="Nom"
                value={totemName}
                required
                pattern="[a-zA-Z0-9\-áàâäçéèêëíìîïñóòôöúùûüýÿæœ ]{1,255}"
                title="Chiffres, lettres, espaces ou tiret (-)"
                onChange={(event) => {
                  setTotemName(event.target.value)
                }}
              />
            </div>
            <div className="form-group">
              <label>Adresse de l'écran (optionnel)</label>
              <input
                type="text"
                className="form-control"
                placeholder="Adresse"
                value={totemAddress}
                pattern="[a-zA-Z0-9áàâäçéèêëíìîïñóòôöúùûüýÿæœ, ]{1,255}"
                title="Chiffres, lettres, espaces ou virgules entre 1 et 255 caractères"
                onChange={(event) => {
                  setTotemAddress(event.target.value)
                }}
              />
            </div>
            <div className="form-group">
              <label>Type d'établissement</label>
              <Select
                onChange={setTotemEstablishmentTypeId}
                options={userContext.establishmentTypes}
                name="totemEstablishmentType"
              />
            </div>
            <div className="form-group">
              <label>Navigation interractive</label>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={totemHasNav}
                  onChange={() => setTotemHasNav(!totemHasNav)}
                />
                <label className="form-check-label">Activé</label>
              </div>
            </div>
            {totemEstablishmentTypeId === 1 && totemHasNav && (
              <div className="form-group">
                <label>Lien de l'office de tourisme</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="https://office-de-tourisme-exemple.fr"
                  value={totemLink}
                  pattern="https:\/\/[a-zA-Z0-9:_\-.\/]{1,240}"
                  title="Lien d'un site internet commençant par https (sécurisé)"
                  required
                  onChange={(event) => {
                    setTotemLink(event.target.value)
                  }}
                />
              </div>
            )}
            {totemEstablishmentTypeId === 2 && totemHasNav && (
              <div className="form-group">
                <label>Gestion de contenu navigation active</label>
                <Select onChange={setTotemNavTeamId} options={clubs} name="totemNavTeam" />
              </div>
            )}
            <div className="form-group">
              <label>Mode écran LED</label>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={totemIsLed}
                  onChange={() => setTotemIsLed(!totemIsLed)}
                />
                <label className="form-check-label">Activé</label>
              </div>
            </div>
            <div className="form-group">
              <label>Largeur de l'écran en pixel</label>
              <input
                type="number"
                className="form-control"
                min="1"
                max="9999"
                value={totemWidth}
                required
                onChange={(event) => {
                  setTotemWidth(event.target.value)
                }}
              />
            </div>
            <div className="form-group">
              <label>Hauteur de l'écran en pixel</label>
              <input
                type="number"
                className="form-control"
                min="1"
                max="9999"
                value={totemHeight}
                required
                onChange={(event) => {
                  setTotemHeight(event.target.value)
                }}
              />
            </div>
            <p className="text-danger">{error}</p>
          </LoadingCard>
        </div>
        <div className="card-footer">
          <button type="submit" className="btn btn-primary">
            Valider
          </button>
          <button type="button" className="btn btn-default float-right" onClick={() => navigate(-1)}>
            Annuler
          </button>
        </div>
      </form>
    </div>
  )
}
