import React, { useMemo, useState } from "react"
import ContentWrapper from "../components/ContentWrapper"
import ContentHeader from "../components/ContentHeader"
import ContentMain from "../components/ContentMain"
import { Link, useParams } from "react-router-dom"
import API from "../utils/API"
import ClubActivityTable from "../components/ClubActivityTable"
import ClubActivityForm from "../components/ClubActivityForm"
import ClubActivityDetails from "../components/ClubActivityDetails"

export default function MainClubInternship({ addInternship }) {
  // State
  let { internshipId } = useParams()
  const [internships, setInternships] = useState([])
  const [loaded, setLoaded] = useState(false)

  // Comportement
  const getInternships = () => {
    API.GET("/club/internship", (response) => {
      setInternships(response.data)
      setLoaded(true)
    })
  }

  useMemo(() => {
    getInternships()
  }, [])

  const deleteInternship = (id) => {
    let internshipsCopy = internships.filter((internship) => internship.id !== id)
    setInternships(internshipsCopy)
  }

  // Rendu
  return (
    <ContentWrapper>
      <ContentHeader>
        <div className="col-sm-6">
          <h1 className="m-0">Stages du club</h1>
        </div>
        <div className="col-sm-6">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item">
              <Link to={"/"}>Accueil</Link>
            </li>
            {addInternship ? (
              <>
                <li className="breadcrumb-item">
                  <Link to={"/club/stages"}>Stages du club</Link>
                </li>
                <li className="breadcrumb-item active">Nouveau</li>
              </>
            ) : (
              <li className="breadcrumb-item active">Stages du club</li>
            )}
          </ol>
        </div>
      </ContentHeader>
      <ContentMain>
        {addInternship ? (
          <ClubActivityForm
            cardTitle="Création d'un nouveau stage"
            activityType="internship"
            refreshActivities={getInternships}
          />
        ) : internshipId ? (
          <ClubActivityDetails
            cardTitle="Détails du stage"
            activities={internships}
            activityId={internshipId}
            refreshActivities={getInternships}
          />
        ) : (
          <ClubActivityTable
            title="Liste des stages"
            activities={internships}
            refreshActivities={getInternships}
            deleteActivity={deleteInternship}
            loaded={loaded}
          />
        )}
      </ContentMain>
    </ContentWrapper>
  )
}
