import { NavLink } from "react-router-dom"
import MenuCommon from "./SidebarMenuCommon"

function MenuClub() {
  return (
    <>
      <MenuCommon />
      <li className="nav-header">Club</li>
      <li className="nav-item">
        <NavLink to="/club/presentation" className="nav-link">
          <i className="nav-icon far fa-file-alt" />
          <p>Présentation du club</p>
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink to="/club/actualites" className="nav-link">
          <i className="nav-icon far fa-newspaper" />
          <p>Actualités</p>
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink to="/club/tournois" className="nav-link">
          <i className="nav-icon fas fa-trophy" />
          <p>Tournois</p>
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink to="/club/animations" className="nav-link">
          <i className="nav-icon fas fa-bullhorn" />
          <p>Animations</p>
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink to="/club/stages" className="nav-link">
          <i className="nav-icon fas fa-chalkboard-teacher" />
          <p>Stages</p>
        </NavLink>
      </li>
    </>
  )
}

export default MenuClub
