import React, { useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import API from "../utils/API"
import Sweetalert from "../utils/Sweetalert"
import LoadingCard from "./LoadingCard"
import Select from "./Select"
import AdPreviewCard from "./AdPreviewCard"

export default function ClubNewsForm({ refreshNews }) {
  // State
  const navigate = useNavigate()
  const [totems, setTotems] = useState([])
  const [ads, setAds] = useState([])
  const [totemsLoaded, setTotemsLoaded] = useState(false)
  const [adsLoaded, setAdsLoaded] = useState(false)
  const [title, setTitle] = useState("")
  const [adSelected, setAdSelected] = useState("")
  const [totemsSelected, setTotemsSelected] = useState([])
  const [error, setError] = useState("")

  // Comportement
  const getTotems = () => {
    API.GET("/club/totem", (response) => {
      setTotems(response.data)
      setTotemsLoaded(true)
    })
  }

  const getAds = () => {
    API.GET("/ad", (response) => {
      setAds(response.data)
      setAdsLoaded(true)
    })
  }

  useMemo(() => {
    getTotems()
    getAds()
  }, [])

  const adsAvailable = useMemo(() => {
    return ads.filter((ad) => ad.adTypeId === 2 || ad.adTypeId === 3) // Filtre sur les Images & PDF
  }, [ads])

  const handleSubmit = (event) => {
    event.preventDefault()
    setError("")

    Sweetalert.fireConfirm(
      "Validez vous ces données ?",
      "Une fois créé, l'actualité sera visible sur les écrans associés",
      () => {
        API.POST(
          "/club/news",
          {
            title,
            adId: adSelected,
            totemsSelected,
          },
          (response) => {
            Sweetalert.fireSuccessToast(response.data.message)
            refreshNews()
            navigate(-1)
          },
          setError
        )
      }
    )
  }

  // Rendu
  return (
    <>
      <div className="card card-primary">
        <div className="card-header">
          <h3 className="card-title">Nouvelle actualité</h3>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="card-body">
            <LoadingCard loaded={totemsLoaded && adsLoaded}>
              <div className="form-group">
                <label>Titre</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Titre"
                  value={title}
                  pattern="[a-zA-Z0-9\-_.!?áàâäçéèêëíìîïñóòôöúùûüýÿæœ ]{1,255}"
                  title="Lettres, chiffres, espaces et caractères suivants : -_.!? entre 1 et 255 caractères"
                  required
                  onChange={(event) => {
                    setTitle(event.target.value)
                  }}
                />
              </div>
              <div className="form-group">
                <label>Ressource à afficher</label>
                <Select onChange={setAdSelected} options={adsAvailable} name="adId" />
              </div>
              <div className="form-group">
                <label>Écrans sur lesquels diffuser</label>
                <Select onChange={setTotemsSelected} isMulti={true} options={totems} name="totemsSelected" />
              </div>
              <p className="text-danger">{error}</p>
            </LoadingCard>
          </div>
          <div className="card-footer">
            <button type="submit" className="btn btn-primary">
              Valider
            </button>
            <button type="button" className="btn btn-default float-right" onClick={() => navigate(-1)}>
              Annuler
            </button>
          </div>
        </form>
      </div>
      {adSelected && <AdPreviewCard adId={adSelected} />}
    </>
  )
}
