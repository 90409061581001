import React from "react"
import API from "../utils/API"
import Sweetalert from "../utils/Sweetalert"

export default function TeamManageDetailsUsers({ user, deleteUser }) {
  // State

  // Comportements
  const handleDelete = () => {
    Sweetalert.fireDeleteConfirm(
      "Êtes-vous sûr ?",
      "Supprimer un utilisateur de cette liste supprimera toute donnée associée à ce dernier.",
      () => {
        API.DELETE("/admin/user/" + user.id, (response) => {
          Sweetalert.fireSuccessToast(response.data.message)
          deleteUser(user.id)
        })
      }
    )
  }

  // Rendu
  return (
    <tr>
      <td>
        <span title={user.id}>#</span>
      </td>
      <td>{user.name}</td>
      <td>{user.mail}</td>
      <td>
        <button className="btn btn-danger btn-xs" onClick={() => handleDelete(user.id)}>
          <i className="fas fa-trash"></i>
        </button>
      </td>
    </tr>
  )
}
