import React from "react"
import { Link } from "react-router-dom"
import Sweetalert from "../utils/Sweetalert"
import Date from "../utils/Date"
import API from "../utils/API"

export default function TotemManageTableItem({ totem, deleteTotem }) {
  // State

  // Comportements
  const handleDelete = (id) => {
    Sweetalert.fireDeleteConfirm(
      "Êtes-vous sûr ?",
      "Supprimer un totem de cette liste supprimera toute donnée associée à ce dernier, et révoquera son accès à l'API.",
      () => {
        API.DELETE("/admin/totem/" + id, (response) => {
          Sweetalert.fireSuccessToast(response.data.message)
          deleteTotem(id)
        })
      }
    )
  }

  // Rendu
  return (
    <tr>
      <td>
        <span title={totem.id}>#</span>
      </td>
      <td>{totem.name}</td>
      <td>{totem.address}</td>
      <td>
        {totem.state ? (
          <span title={"Mis à jour le " + Date.formatDate(totem.stateUpdatedAt)} className="badge badge-success">
            En ligne
          </span>
        ) : (
          <span title={"Mis à jour le " + Date.formatDate(totem.stateUpdatedAt)} className="badge badge-danger">
            Hors ligne
          </span>
        )}
      </td>
      <td>
        {totem.state ? (
          totem.powerState ? (
            <span className="badge badge-success">Allumé</span>
          ) : (
            <span className="badge badge-danger">Éteint</span>
          )
        ) : (
          "-"
        )}
      </td>
      <td>{totem.establishmentType.name}</td>
      <td>{totem.version || "-"}</td>
      <td className="project-actions text-right">
        <Link to={"/totem/gestion/" + totem.id} className="btn btn-primary btn-xs mr-2">
          <i className="fas fa-folder"></i>
          <span className="ml-1">Gestion</span>
        </Link>
        <button className="btn btn-danger btn-xs" onClick={() => handleDelete(totem.id)}>
          <i className="fas fa-trash"></i>
          <span className="ml-1">Supprimer</span>
        </button>
      </td>
    </tr>
  )
}
