import { useContext } from "react"
import { Link } from "react-router-dom"
import UserContext from "../UserContext"
import ButtonLink from "./ButtonLink"

function Navbar() {
  // State
  const userContext = useContext(UserContext)

  // Comportements
  const handleLogout = () => {
    userContext.signOut()
  }

  // Rendu
  return (
    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
      {/* Left navbar links */}
      <ul className="navbar-nav">
        <li className="nav-item">
          <ButtonLink id="sidebar-button" className="nav-link" dataWidget="pushmenu" role="button">
            <i className="fas fa-bars" />
          </ButtonLink>
        </li>
        <li className="nav-item d-sm-inline-block">
          <Link to={"/profil"} className="nav-link">
            Profil
          </Link>
        </li>
        <li className="nav-item d-sm-inline-block">
          <ButtonLink className="nav-link" onClick={handleLogout}>
            Déconnexion
          </ButtonLink>
        </li>
      </ul>
      {/* Right navbar links */}
      <ul className="navbar-nav ml-auto">
        <li className="nav-item">
          <ButtonLink className="nav-link" dataWidget="fullscreen" role="button">
            <i className="fas fa-expand-arrows-alt" />
          </ButtonLink>
        </li>
      </ul>
    </nav>
  )
}

export default Navbar
