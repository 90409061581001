import React from "react"
import Sweetalert from "../utils/Sweetalert"
import DateUtils from "../utils/Date"
import API from "../utils/API"
import { Link } from "react-router-dom"

export default function ClubNewsTableItem({ item, deleteNews }) {
  // State

  // Comportements
  const handleDelete = (id) => {
    Sweetalert.fireDeleteConfirm(
      "Êtes-vous sûr ?",
      "Cette actualité ne sera plus affichée sur les écrans associés",
      () => {
        API.DELETE("/club/news/" + id, (response) => {
          Sweetalert.fireSuccessToast(response.data.message)
          deleteNews(id)
        })
      }
    )
  }

  // Rendu
  return (
    <tr>
      <td>{item.title}</td>
      <td>{item.ad.name}</td>
      <td>
        {item.totems.map((totem) => (
          <span key={totem.id} className="badge badge-secondary mr-1">
            {totem.name}
          </span>
        ))}
      </td>
      <td>{DateUtils.formatDate(item.createdAt)}</td>
      <td className="project-actions text-right">
        <Link to={"./" + item.id} className="btn btn-primary btn-xs mr-2">
          <i className="fas fa-folder"></i>
          <span className="ml-1">Détails</span>
        </Link>
        <button className="btn btn-danger btn-xs" onClick={() => handleDelete(item.id)}>
          <i className="fas fa-trash"></i>
          <span className="ml-1">Supprimer</span>
        </button>
      </td>
    </tr>
  )
}
