import React from "react"
import API from "../utils/API"
import Sweetalert from "../utils/Sweetalert"

export default function TotemManageDetailsTeams({ totemId, team, deleteTeam }) {
  // State

  // Comportements
  const handleDelete = () => {
    Sweetalert.fireDeleteConfirm("Êtes-vous sûr ?", "Supprimer une équipe de cette liste supprimera son accès.", () => {
      API.DELETE("/admin/team/" + team.id + "/totem/" + totemId, (response) => {
        Sweetalert.fireSuccessToast(response.data.message)
        deleteTeam(team.id)
      })
    })
  }

  // Rendu
  return (
    <tr>
      <td>
        <span title={team.id}>#</span>
      </td>
      <td>{team.name}</td>
      <td>{team.role.name}</td>
      <td>{team.team_totem.duration}s</td>
      <td>
        <button className="btn btn-danger btn-xs" onClick={() => handleDelete()}>
          <i className="fas fa-trash"></i>
        </button>
      </td>
    </tr>
  )
}
