import React, { useContext, useMemo, useState } from "react"
import ReactPlayer from "react-player"
import { Document, Page, pdfjs } from "react-pdf"
import "react-pdf/dist/esm/Page/TextLayer.css"
import "react-pdf/dist/esm/Page/AnnotationLayer.css"
import UserContext from "../UserContext"

export default function AdPlayerTotem({ ad }) {
  // State
  const userContext = useContext(UserContext)
  const [paysage, setPaysage] = useState(false)
  const [playing, setPlaying] = useState(false)

  // Comportements
  useMemo(() => {
    setPaysage(ad.landscape)
  }, [ad])

  useMemo(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`
  }, [])

  const [width, height] = useMemo(() => {
    if (paysage) return [320, 180]
    else return [180, 320]
  }, [paysage])

  const style = useMemo(() => {
    if (paysage) return { width: "520px", height: "380px", backgroundImage: `url("/dist/img/Totem-paysage.png")` }
    else return { width: "380px", height: "520px", backgroundImage: `url("/dist/img/Totem-portrait.png")` }
  }, [paysage])

  // Rendu
  return (
    <div className="d-flex flex-column justify-content-center align-items-center" style={{ marginTop: "-50px" }}>
      <div className="d-flex flex-row justify-content-center align-items-center" style={style}>
        {ad.adTypeId === 1 && ( // VIDEO
          <ReactPlayer
            url={process.env.REACT_APP_API_HOST + `${userContext.role.id < 3 ? "/admin" : ""}/video/` + ad.id}
            controls={false}
            playing={playing}
            loop={true}
            width={`${width}px`}
            height={`${height}px`}
            onReady={() => setPlaying(true)}
          />
        )}
        {ad.adTypeId === 2 && ( // IMAGE
          <img
            src={process.env.REACT_APP_API_HOST + `${userContext.role.id < 3 ? "/admin" : ""}/image/` + ad.id}
            width={`${width}px`}
            height={`${height}px`}
            alt="Prévisualisation"
          />
        )}
        {ad.adTypeId === 3 && ( // PDF
          <Document
            file={{
              url: process.env.REACT_APP_API_HOST + `${userContext.role.id < 3 ? "/admin" : ""}/pdf/` + ad.id,
              withCredentials: true,
            }}
          >
            <Page pageNumber={1} width={width} height={height} />
          </Document>
        )}
      </div>
      <div>
        {ad.adTypeId === 1 &&
          (playing ? (
            <button className="btn btn-app" onClick={() => setPlaying(false)}>
              <i className="fas fa-pause"></i> Pause
            </button>
          ) : (
            <button className="btn btn-app" onClick={() => setPlaying(true)}>
              <i className="fas fa-play"></i> Jouer
            </button>
          ))}
        {paysage ? (
          <button className="btn btn-app" onClick={() => setPaysage(false)}>
            <i className="fas fa-arrows-alt-v"></i> Portrait
          </button>
        ) : (
          <button className="btn btn-app" onClick={() => setPaysage(true)}>
            <i className="fas fa-arrows-alt-h"></i> Paysage
          </button>
        )}
      </div>
    </div>
  )
}
