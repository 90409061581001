import { NavLink } from "react-router-dom"
import MenuCommon from "./SidebarMenuCommon"

function MenuAdmin() {
  return (
    <>
      <MenuCommon />
      <li className="nav-header">Gestion</li>
      <li className="nav-item">
        <NavLink to="/utilisateur/gestion" className="nav-link">
          <i className="nav-icon fas fa-users" />
          <p>Utilisateurs</p>
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink to="/equipe/gestion" className="nav-link">
          <i className="nav-icon far fa-building" />
          <p>Équipes / Entreprises</p>
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink to="/ressource/gestion" className="nav-link">
          <i className="nav-icon fas fa-play-circle" />
          <p>Ressources</p>
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink to="/totem/gestion" className="nav-link">
          <i className="nav-icon fas fa-tv" />
          <p>Écrans</p>
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink to="/totem/validation" className="nav-link">
          <i className="nav-icon fas fa-check" />
          <p>Installation d'un player</p>
        </NavLink>
      </li>
    </>
  )
}

export default MenuAdmin
