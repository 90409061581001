import React from "react"

export default function ClubPresentationAdCardItem({ ad, selectAd }) {
  return (
    <div className="m-3" onClick={() => selectAd(ad)}>
      <button type="button" className="btn btn-block btn-light p-0">
        <div className="info-box bg-transparent mb-0 p-0">
          <div className="info-box-content text-left">
            <span style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
              <i className="far fa-file-pdf"></i>
              <b> {ad.name}</b>
            </span>
          </div>
        </div>
      </button>
    </div>
  )
}
