import React, { useMemo } from "react"
import ButtonRefresh from "./ButtonRefresh"
import ButtonCollapse from "./ButtonCollapse"
import LoadingCard from "./LoadingCard"
import AdManageTableItem from "./AdManageTableItem"

export default function AdManageTable({
  ads,
  getAds,
  deleteAd,
  loaded,
  adNameFilter,
  typeFilter,
  teamFilter,
  broadcastFilter,
  broadcastTotemFilter,
}) {
  // State

  // Comportements
  const adsFiltered = useMemo(() => {
    // TODO FILTRAGE
    let adsFiltered = ads.filter(
      (ad) =>
        ad.name.includes(adNameFilter) &&
        (typeFilter === 0 || ad.adType.id === typeFilter) &&
        (teamFilter === 0 || ad.team.id === teamFilter) &&
        (broadcastFilter === 0 ||
          (broadcastFilter === 1 && ad.diffusion === 0) ||
          (broadcastFilter === 2 &&
            ad.diffusion > 0 &&
            (broadcastTotemFilter === "" || ad.totems.some((totem) => totem.id === broadcastTotemFilter))))
    )
    return adsFiltered
  }, [ads, adNameFilter, typeFilter, teamFilter, broadcastTotemFilter, broadcastFilter])

  // Rendu
  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Ressources</h3>
        <div className="card-tools">
          <ButtonRefresh onClick={getAds} />
          <ButtonCollapse />
        </div>
      </div>
      <div className="card-body table-responsive p-0" style={{ display: "block" }}>
        <LoadingCard loaded={loaded}>
          {adsFiltered.length > 0 ? (
            <table className="table table-striped projects text-nowrap">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Type</th>
                  <th>Nom</th>
                  <th>Équipe</th>
                  <th>Diffusion</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {adsFiltered.map((ad) => (
                  <AdManageTableItem ad={ad} deleteAd={deleteAd} key={ad.id} />
                ))}
              </tbody>
            </table>
          ) : (
            <div className="alert alert-info m-3">Aucune ressource à afficher.</div>
          )}
        </LoadingCard>
      </div>
    </div>
  )
}
