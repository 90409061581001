import React, { useContext, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import Sweetalert from "../utils/Sweetalert"
import Select from "./Select"
import API from "../utils/API"
import LoadingCard from "./LoadingCard"
import UserContext from "../UserContext"

export default function UserManageForm({ teams, teamsLoaded, teamId }) {
  // State
  const navigate = useNavigate()
  const [userMail, setUserMail] = useState("")
  const [userName, setUserName] = useState("")
  // En cas de nouvelle équipe
  const [userTeamName, setUserTeamName] = useState("")
  const [userTeamRoleId, setUserTeamRoleId] = useState("")
  const [userTeamEstablishmentTypeId, setUserTeamEstablishmentTypeId] = useState(null)
  // En cas d'équipe existante
  const [userTeamId, setUserTeamId] = useState("")
  // Booleen pour differencier les cas
  const [newTeam, setNewTeam] = useState(false)
  const [error, setError] = useState("")
  // Contexte pour récupérer les rôles et types d'établissement
  const userContext = useContext(UserContext)

  // Comportements
  useMemo(() => {
    if (teamId) setUserTeamId(teamId)
  }, [teamId])

  const handleTeamRoleUpdate = (id) => {
    setUserTeamRoleId(id)
    if (id !== 4) setUserTeamEstablishmentTypeId(null)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setError("")

    Sweetalert.fireConfirm(
      "Validez vous ces données ?",
      "Une fois créé, un utilisateur peut être édité dans l'onglet de gestion utilisateur",
      () => {
        let team = null
        if (newTeam)
          team = {
            name: userTeamName,
            roleId: userTeamRoleId,
            establishmentTypeId: userTeamEstablishmentTypeId,
          }
        API.POST(
          "/admin/user",
          {
            mail: userMail,
            name: userName,
            team: team,
            teamId: userTeamId,
            newTeam: newTeam,
          },
          (response) => {
            Sweetalert.fireSuccessToast(response.data.message)
            if (newTeam) navigate("/equipe/gestion/" + response.data.teamId)
            else navigate("/equipe/gestion/" + userTeamId)
          },
          setError
        )
      }
    )
  }

  return (
    <div className="card card-primary">
      <div className="card-header">
        <h3 className="card-title">Création d'un nouvel utilisateur</h3>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="card-body">
          <LoadingCard loaded={teamsLoaded}>
            <div className="form-group">
              <label>Adresse email</label>
              <input
                type="email"
                className="form-control"
                placeholder="Mail"
                value={userMail}
                required
                onChange={(event) => {
                  setUserMail(event.target.value)
                }}
              />
            </div>
            <div className="form-group">
              <label>Nom</label>
              <input
                type="text"
                className="form-control"
                placeholder="Nom"
                value={userName}
                pattern="[a-zA-Záàâäçéèêëíìîïñóòôöúùûüýÿæœ ]{1,255}"
                title="Lettres et espaces entre 1 et 255 caractères"
                required
                onChange={(event) => {
                  setUserName(event.target.value)
                }}
              />
            </div>
            {newTeam ? (
              <>
                <div className="form-group">
                  <label>Équipe</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Nom d'équipe"
                    value={userTeamName}
                    pattern="[a-zA-Z0-9áàâäçéèêëíìîïñóòôöúùûüýÿæœ ]{1,255}"
                    title="Lettres, chiffres et espaces entre 1 et 255 caractères"
                    onChange={(event) => {
                      setUserTeamName(event.target.value)
                    }}
                  />
                </div>
                <div className="form-group">
                  <label>Rôle</label>
                  <Select onChange={handleTeamRoleUpdate} options={userContext.roles} name="roleId" />
                </div>
                {userTeamRoleId === 4 && (
                  <div className="form-group">
                    <label>Type d'établissement</label>
                    <Select
                      onChange={setUserTeamEstablishmentTypeId}
                      options={userContext.establishmentTypes}
                      name="establishmentTypeId"
                    />
                  </div>
                )}
              </>
            ) : (
              <div className="form-group">
                <label>Équipe</label>
                <Select
                  onChange={setUserTeamId}
                  options={teams}
                  defaultValue={parseInt(teamId)}
                  isDisabled={teamId !== undefined}
                  name="teamId"
                />
              </div>
            )}
            {!teamId && (
              <div className="form-check">
                <label className="form-check-label" style={{ cursor: "pointer" }}>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    onChange={(event) => setNewTeam(event.target.checked)}
                  />
                  Nouvelle équipe
                </label>
              </div>
            )}
            <p className="text-danger">{error}</p>
          </LoadingCard>
        </div>
        <div className="card-footer">
          <button type="submit" className="btn btn-primary">
            Valider
          </button>
          <button type="button" className="btn btn-default float-right" onClick={() => navigate(-1)}>
            Annuler
          </button>
        </div>
      </form>
    </div>
  )
}
