import React from "react"
import { Link, useParams } from "react-router-dom"
import ContentHeader from "../components/ContentHeader"
import ContentMain from "../components/ContentMain"
import ContentWrapper from "../components/ContentWrapper"
import TeamManageTable from "../components/TeamManageTable"
//import TeamManageForm from "../components/TeamManageForm"
import TeamManageDetails from "../components/TeamManageDetails"

export default function MainTeamManage() {
  // State
  let { teamId } = useParams()

  // Comportements

  // Rendu
  return (
    <ContentWrapper>
      <ContentHeader>
        <div className="col-sm-6">
          <h1 className="m-0">Gestion Équipes</h1>
        </div>
        <div className="col-sm-6">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item">
              <Link to={"/"}>Accueil</Link>
            </li>
            {teamId ? (
              <>
                <li className="breadcrumb-item">
                  <Link to={"/equipe/gestion"}>Équipe</Link>
                </li>
                <li className="breadcrumb-item active">{teamId}</li>
              </>
            ) : (
              <li className="breadcrumb-item active">Équipe</li>
            )}
          </ol>
        </div>
      </ContentHeader>
      <ContentMain>
        <div className="row">
          <div className="col">{teamId ? <TeamManageDetails teamId={teamId} /> : <TeamManageTable />}</div>
        </div>
      </ContentMain>
    </ContentWrapper>
  )
}
