import React, { useMemo, useState } from "react"
import { Link } from "react-router-dom"
import API from "../utils/API"
import ButtonCollapse from "./ButtonCollapse"
import ButtonLink from "./ButtonLink"
import ButtonRefresh from "./ButtonRefresh"
import InputSearch from "./InputSearch"
import LoadingCard from "./LoadingCard"
import UserManageTableItem from "./UserManageTableItem"

export default function UserManageTable({ teams, teamsLoaded, refreshTeams }) {
  // State
  const [users, setUsers] = useState([])
  const [usersLoaded, setUsersLoaded] = useState(false)
  const [usersFiltered, setUsersFiltered] = useState([])
  const [search, setSearch] = useState("")
  const [actualRoleFilter, setActualRoleFilter] = useState(0)
  const roleColor = {
    0: "black",
    1: "red",
    2: "purple",
    3: "green",
    4: "blue",
  }

  // Comportements
  const getUsers = () => {
    API.GET("/admin/user", (response) => {
      setUsers(response.data)
      setUsersLoaded(true)
    })
  }

  useMemo(() => {
    getUsers()
  }, [])

  const handleRoleFilter = () => {
    let newFilter = (actualRoleFilter + 1) % Object.keys(roleColor).length
    setActualRoleFilter(newFilter)
  }

  useMemo(() => {
    if (users) {
      let usersFilteredCopy = users.filter(
        (user) =>
          (actualRoleFilter === 0 || parseInt(user.team.role.id) === actualRoleFilter) &&
          (user.mail.toLowerCase().includes(search) ||
            user.name.toLowerCase().includes(search) ||
            user.team.name.toLowerCase().includes(search) ||
            user.team.role.name.toLowerCase().includes(search))
      )
      setUsersFiltered(usersFilteredCopy)
    }
  }, [search, actualRoleFilter, users])

  const handleRefresh = () => {
    getUsers()
    refreshTeams()
  }

  const updateUser = (id, user) => {
    let usersCopy = [...users]
    let userIndex = usersCopy.findIndex((user) => user.id === id)
    usersCopy[userIndex] = user
    setUsers(usersCopy)
  }

  const deleteUser = (id) => {
    let usersCopy = users.filter((user) => user.id !== id)
    setUsers(usersCopy)
  }

  // Rendu
  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Liste des utilisateurs</h3>
        <div className="card-tools">
          <InputSearch value={search} onChange={(event) => setSearch(event.target.value.toLowerCase())} />
          <ButtonRefresh onClick={handleRefresh} />
          <ButtonCollapse />
        </div>
      </div>
      <div className="card-body table-responsive p-0" style={{ display: "block" }}>
        <LoadingCard loaded={usersLoaded && teamsLoaded}>
          {users.length > 0 ? (
            <table className="table table-striped projects text-nowrap">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Mail</th>
                  <th>Nom</th>
                  <th style={{ width: "28%" }}>
                    <ButtonLink className={"p-0"} onClick={handleRoleFilter}>
                      <i title="Rôle" className="fas fa-user-tag" style={{ color: roleColor[actualRoleFilter] }}></i>
                    </ButtonLink>{" "}
                    Équipe
                  </th>
                  <th className="text-right" style={{ width: "14%" }}>
                    <Link to={"/utilisateur/gestion/nouveau"} className="btn btn-info btn-xs mr-2">
                      <i className="fas fa-plus"></i>
                      <span className="ml-1">Ajouter</span>
                    </Link>
                  </th>
                </tr>
              </thead>
              <tbody>
                {usersFiltered.map((user) => (
                  <UserManageTableItem
                    user={user}
                    teams={teams}
                    updateUser={updateUser}
                    deleteUser={deleteUser}
                    key={user.id}
                  />
                ))}
              </tbody>
            </table>
          ) : (
            <div className="alert alert-info m-3">
              <h5>
                <i className="icon fas fa-info" /> Rien !
              </h5>
              Aucun utilisateur.
            </div>
          )}
        </LoadingCard>
      </div>
    </div>
  )
}
