import { useContext, useEffect } from "react"
import { NavLink } from "react-router-dom"
import UserContext from "../UserContext"
import SidebarMenuAdmin from "./SidebarMenuAdmin"
import SidebarMenuAnnonceur from "./SidebarMenuAnnonceur"
import SidebarMenuClub from "./SidebarMenuClub"
import SidebarMenuDev from "./SidebarMenuDev"
import SidebarMenuHotel from "./SidebarMenuHotel"

function Menu() {
  // State
  const userContext = useContext(UserContext)
  const menuList = {
    1: <SidebarMenuDev />,
    2: <SidebarMenuAdmin />,
    3: <SidebarMenuAnnonceur />,
  }
  const isEstablishment = userContext.role.id === 4
  const menuEstablishmentList = {
    1: <SidebarMenuHotel />,
    2: <SidebarMenuClub />,
  }

  // Comportements
  useEffect(() => {
    window.$('[data-widget="treeview"]').Treeview("init") // Fix for treeviews
    window.$("#sidebar-button").trigger("click") // Weird fix
    window.$("#sidebar-button").trigger("click") // for menu auto collapse
    window.$("#sidebar-overlay").click(() => {
      // Fix for small screens
      window.$(document.body).addClass(["sidebar-closed", "sidebar-collapse"])
      window.$(document.body).removeClass("sidebar-open")
    })
  }, [])

  // Render
  return (
    <nav className="mt-2">
      <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
        <li className="nav-item">
          <NavLink to="/" className="nav-link">
            <i className="nav-icon fas fa-home" />
            <p>Accueil</p>
          </NavLink>
        </li>
        {isEstablishment ? menuEstablishmentList[userContext.establishmentType.id] : menuList[userContext.role.id]}
      </ul>
    </nav>
  )
}

export default Menu
