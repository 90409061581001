import React, { useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import API from "../utils/API"
import ButtonCollapse from "./ButtonCollapse"
import ButtonRefresh from "./ButtonRefresh"
import LoadingCard from "./LoadingCard"
import Date from "../utils/Date"
import TotemManageDetailsTeams from "./TotemManageDetailsTeams"
import ButtonLink from "./ButtonLink"
import TotemManageDetailsTeamForm from "./TotemManageDetailsTeamForm"
import TotemManageDetailsForm from "./TotemManageDetailsForm"
import TotemManageDetailsPlaylistConf from "./TotemManageDetailsPlaylistConf"
import TotemManageDetailsPlaylists from "./TotemManageDetailsPlaylists"
import Sweetalert from "../utils/Sweetalert"
import TotemManageDetailsLogs from "./TotemManageDetailsLogs"

export default function TotemManageDetails({ totemId }) {
  // State
  const navigate = useNavigate()
  const [totem, setTotem] = useState(null)
  const [teams, setTeams] = useState([])
  const [loaded, setLoaded] = useState(false)
  const [editTotem, setEditTotem] = useState(false)
  const [newTeam, setNewTeam] = useState(false)
  const [brightness, setBrightness] = useState(100)

  // Comportements
  const getTotem = (id) => {
    API.GET("/admin/totem/" + id, (response) => {
      setTotem(response.data)
      setBrightness(response.data.brightness)
      setLoaded(true)
    })
  }

  const getTeams = () => {
    API.GET("/admin/team", (response) => {
      setTeams(response.data)
    })
  }

  useMemo(() => {
    getTotem(totemId)
    getTeams()
  }, [totemId])

  const updateTotem = (data) => {
    let totemCopy = { ...totem, ...data }
    setTotem(totemCopy)
  }

  const updatePlayList = (playListConfig) => {
    let totemCopy = { ...totem }
    totemCopy.playListConfig = playListConfig
    setTotem(totemCopy)
  }

  const deleteTeam = (teamId) => {
    let totemCopy = { ...totem }
    let teamsCopy = totemCopy.teams.filter((team) => team.id !== teamId)
    totemCopy.teams = teamsCopy
    setTotem(totemCopy)
  }

  const sendPowerCommand = (state) => {
    Sweetalert.fireConfirm(
      "Êtes-vous sûr ?",
      "Cette fonctionnalité est utilisée de préférence dans des tests ou lors de debug. A utiliser avec parcimonie et laisser le totem gérer cela automatiquement !",
      () => {
        API.POST("/admin/totem/" + totem.id + "/power", { state: state }, (response) => {
          Sweetalert.fireSuccessToast(response.data.message)
          let totemCopy = { ...totem }
          totemCopy.powerState = state
          setTotem(totemCopy)
        })
      }
    )
  }

  const sendMonitorCheckCommand = () => {
    API.GET("/admin/totem/" + totem.id + "/power/update", (response) => {
      Sweetalert.fireSuccessToast(response.data.message)
      setTimeout(() => {
        getTotem(totemId)
      }, 1000)
    })
  }

  const sendBrightnessCommand = () => {
    API.POST("/admin/totem/" + totem.id + "/brightness", { value: brightness }, (response) => {
      let totemCopy = { ...totem }
      totemCopy.brightness = brightness
      setTotem(totemCopy)
      Sweetalert.fireSuccessToast(response.data.message)
    })
  }

  const sendUpdateCommand = () => {
    Sweetalert.fireConfirm(
      "Êtes-vous sûr ?",
      "Cela mettra à jour instantanément le player si une mise à jour est disponible. A utiliser de préférence lors d'une faible audience !",
      () => {
        API.POST("/admin/totem/" + totem.id + "/update_old", { value: brightness }, (response) => {
          Sweetalert.fireSuccessToast(response.data.message)
        })
      }
    )
  }

  const sendRebootCommand = () => {
    Sweetalert.fireConfirm(
      "Êtes-vous sûr ?",
      "Cela redémarrera instantanément le player. A utiliser de préférence lors d'une faible audience !",
      () => {
        API.POST("/admin/totem/" + totem.id + "/reboot", { value: brightness }, (response) => {
          Sweetalert.fireSuccessToast(response.data.message)
        })
      }
    )
  }

  // Rendu
  return (
    <>
      <div className="row">
        <div className="col-xl-3">
          <div className="card card-outline card-success">
            <div className="card-header">
              <h3 className="card-title">Actions écran</h3>
              <div className="card-tools">
                <ButtonCollapse />
              </div>
            </div>
            <div className="card-body p-0" style={{ display: "block" }}>
              <LoadingCard loaded={loaded}>
                {totem && totem.state ? (
                  <>
                    <div className="form-group row m-3">
                      <label className="col-sm-6">Écran</label>
                      <div className="col-sm-6">
                        <button
                          type="button"
                          className={`btn btn-${totem.powerState ? "danger" : "success"} float-right`}
                          onClick={() => sendPowerCommand(!totem.powerState)}
                        >
                          <i className="fas fa-power-off"></i> {totem.powerState ? "Éteindre" : "Allumer"}
                        </button>
                      </div>
                    </div>
                    <div className="form-group row m-3">
                      <label className="col-sm-6">Luminosité ({brightness}%)</label>
                      <div className="col-sm-6">
                        <input
                          type="range"
                          style={{ width: "100%" }}
                          className="form-range"
                          min="0"
                          max="100"
                          value={brightness}
                          onChange={(event) => setBrightness(event.target.value)}
                        />
                      </div>
                    </div>
                    {brightness !== totem.brightness && (
                      <div className="row m-3">
                        <div className="col">
                          <button
                            type="button"
                            className="btn btn-danger float-right ml-2"
                            style={{ width: "42px" }}
                            onClick={() => setBrightness(totem.brightness)}
                          >
                            <i className="fas fa-times"></i>
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary float-right"
                            style={{ width: "42px" }}
                            onClick={() => sendBrightnessCommand()}
                          >
                            <i className="fas fa-check"></i>
                          </button>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="alert alert-info m-3">
                    Le player n'est pas connecté. Impossible de le contrôler à distance.
                  </div>
                )}
              </LoadingCard>
            </div>
          </div>
          <div className="card card-outline card-warning">
            <div className="card-header">
              <h3 className="card-title">Actions player</h3>
              <div className="card-tools">
                <ButtonCollapse />
              </div>
            </div>
            <div className="card-body p-0" style={{ display: "block" }}>
              <LoadingCard loaded={loaded}>
                {totem && totem.state ? (
                  <>
                    <div className="form-group row m-3">
                      <label className="col-sm-6">Mise à jour</label>
                      <div className="col-sm-6">
                        <button
                          type="button"
                          className={"btn btn-warning float-right"}
                          onClick={() => sendUpdateCommand()}
                        >
                          <i className="fas fa-sync"></i> Mettre à jour
                        </button>
                      </div>
                    </div>
                    <div className="form-group row m-3">
                      <label className="col-sm-6">Redémarrage</label>
                      <div className="col-sm-6">
                        <button
                          type="button"
                          className={"btn btn-danger float-right"}
                          onClick={() => sendRebootCommand()}
                        >
                          <i className="fas fa-power-off"></i> Redémarrer
                        </button>
                      </div>
                    </div>
                    {brightness !== totem.brightness && (
                      <div className="row m-3">
                        <div className="col">
                          <button
                            type="button"
                            className="btn btn-danger float-right ml-2"
                            style={{ width: "42px" }}
                            onClick={() => setBrightness(totem.brightness)}
                          >
                            <i className="fas fa-times"></i>
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary float-right"
                            style={{ width: "42px" }}
                            onClick={() => sendBrightnessCommand()}
                          >
                            <i className="fas fa-check"></i>
                          </button>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="alert alert-info m-3">
                    Le player n'est pas connecté. Impossible de le contrôler à distance.
                  </div>
                )}
              </LoadingCard>
            </div>
          </div>
        </div>
        <div className="col-xl-9">
          {editTotem ? (
            <TotemManageDetailsForm totem={totem} teams={teams} updateTotem={updateTotem} setEditTotem={setEditTotem} />
          ) : (
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">
                  Détails de l'écran : <b>{totemId}</b>
                </h3>
                <div className="card-tools">
                  <ButtonRefresh onClick={() => getTotem(totemId)} />
                  <ButtonCollapse />
                </div>
              </div>
              <div className="card-body table-responsive p-0" style={{ display: "block" }}>
                <LoadingCard loaded={loaded}>
                  {totem && (
                    <table className="table table-striped projects text-nowrap">
                      <thead>
                        <tr>
                          <th>Nom</th>
                          <th>Adresse</th>
                          <th>Statut</th>
                          <th>Etabl.</th>
                          <th>Format (Offset)</th>
                          <th>Nav active</th>
                          <th>Lien nav</th>
                          <th>Durée avant veille</th>
                          <th>Statut écran</th>
                          <th>Écran On {totem.powerOnTime.length > 5 && "(Lundi -> Dimanche)"}</th>
                          <th>Écran Off {totem.powerOffTime.length > 5 && "(Lundi -> Dimanche)"}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{totem.name}</td>
                          <td>{totem.address}</td>
                          <td>
                            {totem.state ? (
                              <span
                                title={"Mis à jour le " + Date.formatDate(totem.stateUpdatedAt)}
                                className="badge badge-success"
                              >
                                En ligne
                              </span>
                            ) : (
                              <span
                                title={"Mis à jour le " + Date.formatDate(totem.stateUpdatedAt)}
                                className="badge badge-danger"
                              >
                                Hors ligne
                              </span>
                            )}
                          </td>
                          <td>{totem.establishmentType.name}</td>
                          <td>
                            {totem.width} ({totem.widthOffset}) x {totem.height} ({totem.heightOffset})
                          </td>
                          <td>{totem.hasNav ? "Oui" : "Non"}</td>
                          <td>{totem.link || "-"}</td>
                          <td>{totem.awakeTime}s</td>
                          <td>
                            <ButtonRefresh onClick={() => sendMonitorCheckCommand()} />
                            {totem.state ? (
                              totem.powerState ? (
                                <span className="badge badge-success">Allumé</span>
                              ) : (
                                <span className="badge badge-danger">Éteint</span>
                              )
                            ) : (
                              "-"
                            )}
                          </td>
                          <td>{`${
                            totem.powerOnTime.length > 5
                              ? totem.powerOnTime.slice(6).replace(/\./g, ", ").replace(/:/g, "h") + ", "
                              : ""
                          }${totem.powerOnTime.slice(0, 5).replace(/:/g, "h")}`}</td>
                          <td>{`${
                            totem.powerOffTime.length > 5
                              ? totem.powerOffTime.slice(6).replace(/\./g, ", ").replace(/:/g, "h") + ", "
                              : ""
                          }${totem.powerOffTime.slice(0, 5).replace(/:/g, "h")}`}</td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                </LoadingCard>
              </div>
              <div className="card-footer">
                <button type="button" className="btn btn-primary" onClick={() => setEditTotem(true)}>
                  <i className="far fa-edit"></i> Éditer
                </button>
                <button type="button" className="btn btn-default float-right" onClick={() => navigate(-1)}>
                  <i className="fas fa-arrow-left"></i> Retour
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-xl-6">
          {newTeam ? (
            <TotemManageDetailsTeamForm
              totemId={totemId}
              teams={teams}
              totemTeams={totem.teams}
              getTotem={getTotem}
              setNewTeam={setNewTeam}
            />
          ) : (
            <div className="card card-outline card-primary">
              <div className="card-header">
                <h3 className="card-title">Équipes pouvant diffuser du contenu sur le Totem</h3>
                <div className="card-tools">
                  <ButtonCollapse />
                </div>
              </div>
              <div className="card-body p-0" style={{ display: "block" }}>
                <LoadingCard loaded={loaded}>
                  {totem && totem.teams.length > 0 ? (
                    <table className="table table-striped projects">
                      <thead>
                        <tr>
                          <th>Id</th>
                          <th>Nom</th>
                          <th>Rôle</th>
                          <th>Durée</th>
                          <th>
                            <button className="btn btn-info btn-xs mr-2" onClick={() => setNewTeam(true)}>
                              <i className="fas fa-plus"></i>
                            </button>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {totem.teams.map((team) => (
                          <TotemManageDetailsTeams
                            totemId={totemId}
                            team={team}
                            deleteTeam={deleteTeam}
                            key={team.id}
                          />
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="alert alert-info m-3">
                      Aucune équipe trouvée.{" "}
                      <ButtonLink
                        onClick={() => setNewTeam(true)}
                        style={{ color: "white", textDecoration: "underline" }}
                      >
                        En ajouter une
                      </ButtonLink>
                    </div>
                  )}
                </LoadingCard>
              </div>
            </div>
          )}
          <TotemManageDetailsLogs totem={totem} />
        </div>
        <div className="col-xl-3">
          <TotemManageDetailsPlaylistConf totem={totem} loaded={loaded} updatePlayList={updatePlayList} />
        </div>
        <div className="col-xl-3">
          <div className="card card-outline card-secondary">
            <div className="card-header">
              <h3 className="card-title">Dernières playlists connues</h3>
              <div className="card-tools">
                <ButtonCollapse />
              </div>
            </div>
            <div className="card-body" style={{ display: "block" }}>
              <TotemManageDetailsPlaylists totem={totem} loaded={loaded} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
