import React from "react"

export default function InputSearch({ onChange }) {
  return (
    <div className="input-group input-group-sm float-left mr-2" style={{ width: 150 }}>
      <input type="text" className="form-control" placeholder="Recherche" onChange={onChange} />
      <div className="input-group-append">
        <span className="input-group-text">
          <i className="fas fa-search"></i>
        </span>
      </div>
    </div>
  )
}
