import React, { useMemo } from "react"
import API from "../utils/API"
import Sweetalert from "../utils/Sweetalert"
import ButtonCollapse from "./ButtonCollapse"
import DiffusionAdCardItem from "./DiffusionAdCardItem"
import LoadingCard from "./LoadingCard"

export default function DiffusionAdCard({ ads, loaded, totemSelected, addAdToSelectedTotem }) {
  // State

  // Comportements
  const adsAvailable = useMemo(() => {
    if (totemSelected) {
      let vS = []
      let vA = []
      totemSelected.ads.forEach((ad) => {
        vS.push(ad.id)
      })
      ads.forEach((ad) => {
        if (!vS.includes(ad.id) /*&& ad.landscape === totemSelected.landscape*/) vA.push(ad)
      })
      return vA
    } else return []
  }, [ads, totemSelected])

  const selectAd = (ad) => {
    let maxDuration = totemSelected.team_totem.duration
    let sendRequest = (ad, nVDuration) => {
      if (ad.duration + nVDuration > maxDuration)
        Sweetalert.fireWarningToast(
          "Vous dépassez la limite de " + maxDuration + "s de diffusion autorisée pour ce totem"
        )
      else {
        API.POST(
          "/totem/" + totemSelected.id + "/ad",
          {
            id: ad.id,
            duration: nVDuration,
          },
          (response) => {
            Sweetalert.fireSuccessToast(response.data.message)
            addAdToSelectedTotem(ad, nVDuration)
          }
        )
      }
    }

    if (ad.adTypeId !== 1)
      Sweetalert.askNumber(
        "Durée d'affichage",
        "Définissez la durée pendant laquelle cette publicité s'affiche par occurence",
        1,
        10,
        "Durée en secondes (Entre 1 et 10s)",
        (response) => sendRequest(ad, parseInt(response.value))
      )
    else sendRequest(ad, 0)
  }

  // Rendu
  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Mes ressources</h3>
        <div className="card-tools">
          <ButtonCollapse />
        </div>
      </div>
      <div className="card-body p-0" style={{ display: "block" }}>
        <LoadingCard loaded={loaded}>
          {totemSelected ? (
            adsAvailable.length > 0 ? (
              adsAvailable.map((ad) => <DiffusionAdCardItem key={ad.id} ad={ad} selectAd={selectAd} />)
            ) : (
              <div className="alert alert-info m-3">
                <i className="icon fas fa-info" /> Aucune ressource disponible
              </div>
            )
          ) : (
            <div className="alert alert-info m-3">
              <i className="icon fas fa-info" /> Veuillez sélectionner un totem
            </div>
          )}
        </LoadingCard>
      </div>
    </div>
  )
}
