import React from "react"
import { Link } from "react-router-dom"

export default function MainError() {
  return (
    <div className="content-wrapper">
      {/* Content Header (Page header) */}
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Erreur</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to={"/"}>Accueil</Link>
                </li>
                <li className="breadcrumb-item active">Page non trouvée</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      {/* /.content-header */}
      {/* Main content */}
      <div className="content">
        <div className="error-page">
          <h2 className="headline text-warning"> 404</h2>
          <div className="error-content">
            <h3>
              <i className="fas fa-exclamation-triangle text-warning" /> Oops! Page non trouvée.
            </h3>
            <p>
              La page que vous recherchez n'existe pas, ou vous n'avez pas l'autorisation d'y accéder. Merci de suivre
              le lien vers <Link to={"/"}>L'accueil</Link>
            </p>
          </div>
        </div>
      </div>
      {/* /.Main content */}
    </div>
  )
}
