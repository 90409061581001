import React from "react"
import { Link, useParams } from "react-router-dom"
import ContentHeader from "../components/ContentHeader"
import ContentMain from "../components/ContentMain"
import ContentWrapper from "../components/ContentWrapper"
import HelperCard from "../components/HelperCard"
import TotemValidateForm from "../components/TotemValidateForm"
import TotemValidateTable from "../components/TotemValidateTable"

export default function MainTotemValidate() {
  // State
  let { totemId } = useParams()

  // Comportements

  // Rendu
  return (
    <ContentWrapper>
      <ContentHeader>
        <div className="col-sm-6">
          <h1 className="m-0">Installation de Players</h1>
        </div>
        <div className="col-sm-6">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item">
              <Link to={"/"}>Accueil</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={"/totem/gestion"}>Écran</Link>
            </li>
            {totemId ? (
              <>
                <li className="breadcrumb-item">
                  <Link to={"/totem/validation"}>Installation</Link>
                </li>
                <li className="breadcrumb-item active">{totemId}</li>
              </>
            ) : (
              <li className="breadcrumb-item active">Installation</li>
            )}
          </ol>
        </div>
      </ContentHeader>
      <ContentMain>
        <div className="row">
          <div className="col">{totemId ? <TotemValidateForm totemId={totemId} /> : <TotemValidateTable />}</div>
          <HelperCard className="col-xl-4" title="Comment utiliser">
            <p>Cette page sert à installer l'application sur un nouveau player.</p>
            <p>
              Lors de l'installation d'un nouvel écran, il faudra installer le logiciel player sur le player. Vous
              pouvez télécharger la dernière version en appuyant sur le bouton <i className="fas fa-download"></i> en
              haut à droite du tableau ci-contre.
            </p>
            <p>
              Une fois l'application installée, cette dernière vous demandera une validation manuelle. Deux choix
              s'offrent alors à vous :
            </p>
            <ol>
              <li>
                <p>
                  Venir sur cette page pour chercher manuellement le player à l'aide de l'identifiant affiché sur
                  l'application et le valider
                </p>
              </li>
              <li>
                <p>
                  Scanner le QR Code affiché sur l'application afin d'attérir directement sur la page de validation du
                  player (Connexion requise à cette application)
                </p>
              </li>
            </ol>
            <p>Une fois le player validé ici, ce dernier aura le droit de chercher des informations sur l'API.</p>
          </HelperCard>
        </div>
      </ContentMain>
    </ContentWrapper>
  )
}
