import React, { useMemo, useState } from "react"
import ContentWrapper from "../components/ContentWrapper"
import ContentHeader from "../components/ContentHeader"
import ContentMain from "../components/ContentMain"
import { Link, useParams } from "react-router-dom"
import API from "../utils/API"
import ClubActivityTable from "../components/ClubActivityTable"
import ClubActivityForm from "../components/ClubActivityForm"
import ClubActivityDetails from "../components/ClubActivityDetails"

export default function MainClubTournament({ addTournament }) {
  // State
  let { tournamentId } = useParams()
  const [tournaments, setTournaments] = useState([])
  const [loaded, setLoaded] = useState(false)

  // Comportement
  const getTournaments = () => {
    API.GET("/club/tournament", (response) => {
      setTournaments(response.data)
      setLoaded(true)
    })
  }

  useMemo(() => {
    getTournaments()
  }, [])

  const deleteTournament = (id) => {
    let tournamentsCopy = tournaments.filter((tournament) => tournament.id !== id)
    setTournaments(tournamentsCopy)
  }

  // Rendu
  return (
    <ContentWrapper>
      <ContentHeader>
        <div className="col-sm-6">
          <h1 className="m-0">Tournois du club</h1>
        </div>
        <div className="col-sm-6">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item">
              <Link to={"/"}>Accueil</Link>
            </li>
            {addTournament ? (
              <>
                <li className="breadcrumb-item">
                  <Link to={"/club/tournois"}>Tournois du club</Link>
                </li>
                <li className="breadcrumb-item active">Nouveau</li>
              </>
            ) : (
              <li className="breadcrumb-item active">Tournois du club</li>
            )}
          </ol>
        </div>
      </ContentHeader>
      <ContentMain>
        {addTournament ? (
          <ClubActivityForm
            cardTitle="Création d'un nouveau tournoi"
            activityType="tournament"
            refreshActivities={getTournaments}
          />
        ) : tournamentId ? (
          <ClubActivityDetails
            cardTitle="Détails du tournoi"
            activities={tournaments}
            activityId={tournamentId}
            refreshActivities={getTournaments}
          />
        ) : (
          <ClubActivityTable
            title="Liste des tournois"
            activities={tournaments}
            refreshActivities={getTournaments}
            deleteActivity={deleteTournament}
            loaded={loaded}
          />
        )}
      </ContentMain>
    </ContentWrapper>
  )
}
