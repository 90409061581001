import React from "react"

export default function ContentHeader({ children }) {
  return (
    <>
      {/* Content Header (Page header) */}
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">{children}</div>
        </div>
      </div>
      {/* /.content-header */}
    </>
  )
}
