import SidebarMenuAdmin from "./SidebarMenuAdmin"

function MenuDev() {
  return (
    <>
      <SidebarMenuAdmin />
    </>
  )
}

export default MenuDev
