import React, { useMemo, useState } from "react"
import { Link, useParams } from "react-router-dom"
import ContentHeader from "../components/ContentHeader"
import ContentMain from "../components/ContentMain"
import ContentWrapper from "../components/ContentWrapper"
import AdCard from "../components/AdCard"
import AdDropzone from "../components/AdDropzone"
import AdPlayerCard from "../components/AdPlayerCard"
import API from "../utils/API"

export default function MainAd() {
  // State
  let { adId } = useParams()
  const [ads, setAds] = useState([])
  const [adPaths, setAdPaths] = useState([])
  const [currentAdPath, setCurrentAdPath] = useState(null)
  const [adsLoaded, setAdsLoaded] = useState(false)
  const [adPathsLoaded, setAdPathsLoaded] = useState(false)

  // Comportements
  const getAds = () => {
    API.GET("/ad", (response) => {
      setAds(response.data)
      setAdsLoaded(true)
    })
  }

  const getAdPaths = () => {
    API.GET("/ad/path", (response) => {
      setAdPaths(response.data)
      setAdPathsLoaded(true)
    })
  }

  useMemo(() => {
    getAds()
    getAdPaths()
  }, [])

  const deleteAd = (id) => {
    let adsCopy = ads.filter((ad) => ad.id !== id)
    setAds(adsCopy)
  }

  const deleteAdPath = (id) => {
    let adPathsCopy = adPaths.filter((adPath) => adPath.id !== id)
    setAdPaths(adPathsCopy)
  }

  // Rendu
  return (
    <ContentWrapper>
      <ContentHeader>
        <div className="col-sm-6">
          <h1 className="m-0">Mes ressources</h1>
        </div>
        <div className="col-sm-6">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item">
              <Link to={"/"}>Accueil</Link>
            </li>
            <li className="breadcrumb-item active">Mes ressources</li>
          </ol>
        </div>
      </ContentHeader>
      <ContentMain>
        {adId ? (
          <AdPlayerCard ads={ads} adId={adId} deleteAd={deleteAd} loaded={adsLoaded && adPathsLoaded} />
        ) : (
          <>
            <div className="row">
              <div className="col">
                <AdDropzone refreshAds={getAds} currentAdPath={currentAdPath} />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <AdCard
                  ads={ads}
                  refreshAds={getAds}
                  refreshAdPaths={getAdPaths}
                  deleteAd={deleteAd}
                  adPaths={adPaths}
                  deleteAdPath={deleteAdPath}
                  currentAdPath={currentAdPath}
                  setCurrentAdPath={setCurrentAdPath}
                  loaded={adsLoaded && adPathsLoaded}
                />
              </div>
            </div>
          </>
        )}
      </ContentMain>
    </ContentWrapper>
  )
}
