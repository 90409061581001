import React from "react"
import API from "../utils/API"
import Sweetalert from "../utils/Sweetalert"
import ButtonCollapse from "./ButtonCollapse"
import DiffusionTotemCardTotem from "./DiffusionTotemCardTotem"
import DiffusionTotemCardAd from "./DiffusionTotemCardAd"
import LoadingCard from "./LoadingCard"
import ButtonRefresh from "./ButtonRefresh"

export default function DiffusionTotemCard({
  totems,
  loaded,
  totemSelected,
  selectTotem,
  refreshTotemsAds,
  validateSelectedTotem,
  updateAd,
  deleteAdFromSelectedTotem,
}) {
  // State

  // Comportements
  const deselectAd = (adId) => {
    Sweetalert.fireDeleteConfirm(
      "Êtes-vous sûr ?",
      "Si vous supprimez cette ressource du totem, elle ne sera plus diffusée.",
      () => {
        API.DELETE("/totem/" + totemSelected.id + "/ad/" + adId, (response) => {
          Sweetalert.fireSuccessToast(response.data.message)
          deleteAdFromSelectedTotem(adId)
        })
      }
    )
  }

  // Rendu
  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Totems disponibles</h3>
        <div className="card-tools">
          <ButtonRefresh onClick={refreshTotemsAds} />
          <ButtonCollapse />
        </div>
      </div>
      <div className="card-body p-0" style={{ display: "block" }}>
        <LoadingCard loaded={loaded}>
          {totemSelected ? (
            <>
              <div className="m-3">
                <div className="info-box bg-transparent mb-0 p-0">
                  <div className="info-box-content">
                    <div className="d-flex justify-content-between">
                      <span>
                        <i className="fas fa-tv"></i> <b>{totemSelected.name}</b>{" "}
                        <i
                          className="fas fa-circle"
                          title={totemSelected.state ? "En ligne" : "Hors ligne"}
                          style={{ color: totemSelected.state ? "green" : "red" }}
                        ></i>
                      </span>
                      <span>
                        <i className="fas fa-broadcast-tower"></i>{" "}
                        <em>
                          <b>{totemSelected.team_totem.duration} secondes par boucle</b>
                        </em>
                      </span>
                    </div>
                    <div className="d-flex justify-content-between">
                      <span>
                        <i className="fas fa-map-marker text-center" style={{ width: "20px" }}></i>{" "}
                        {totemSelected.address || "-"}
                      </span>
                      <span>
                        {totemSelected.width}x{totemSelected.height}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {totemSelected.ads.length > 0 ? (
                <table className="table table-striped projects">
                  <thead>
                    <tr>
                      <th>Nom</th>
                      <th>Durée</th>
                      <th title="Granularité (poids de diffusion)">#</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {totemSelected.ads.map((ad) => (
                      <DiffusionTotemCardAd
                        key={ad.id}
                        ad={ad}
                        maxDuration={totemSelected.team_totem.duration}
                        deselectAd={deselectAd}
                        updateAd={updateAd}
                      />
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="alert alert-info m-3">
                  <i className="icon fas fa-info" /> Veuillez sélectionner une ressource
                </div>
              )}
            </>
          ) : totems.length > 0 ? (
            totems.map((totem) => <DiffusionTotemCardTotem key={totem.id} totem={totem} selectTotem={selectTotem} />)
          ) : (
            <div className="alert alert-info m-3">
              <i className="icon fas fa-info" /> Aucun totem disponible
            </div>
          )}
        </LoadingCard>
      </div>
      {totemSelected && (
        <div className="card-footer">
          <button type="button" className="btn btn-default float-right" onClick={validateSelectedTotem}>
            <i className="fas fa-arrow-left"></i> Retour
          </button>
        </div>
      )}
    </div>
  )
}
