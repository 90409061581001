import React from "react"
import { Link, useParams } from "react-router-dom"
import ContentHeader from "../components/ContentHeader"
import ContentMain from "../components/ContentMain"
import ContentWrapper from "../components/ContentWrapper"
import TotemManageDetails from "../components/TotemManageDetails"
import TotemManageTable from "../components/TotemManageTable"

export default function MainTotemManage() {
  // State
  let { totemId } = useParams()

  // Comportements

  // Rendu
  return (
    <ContentWrapper>
      <ContentHeader>
        <div className="col-sm-6">
          <h1 className="m-0">Gestion Écrans</h1>
        </div>
        <div className="col-sm-6">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item">
              <Link to={"/"}>Accueil</Link>
            </li>
            {totemId ? (
              <>
                <li className="breadcrumb-item">
                  <Link to={"/totem/gestion"}>Écran</Link>
                </li>
                <li className="breadcrumb-item active">{totemId}</li>
              </>
            ) : (
              <li className="breadcrumb-item active">Écran</li>
            )}
          </ol>
        </div>
      </ContentHeader>
      <ContentMain>{totemId ? <TotemManageDetails totemId={totemId} /> : <TotemManageTable />}</ContentMain>
    </ContentWrapper>
  )
}
