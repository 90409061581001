import MenuCommon from "./SidebarMenuCommon"

function MenuHotel() {
  return (
    <>
      <MenuCommon />
    </>
  )
}

export default MenuHotel
