import React, { useMemo, useState } from "react"
import ContentWrapper from "../components/ContentWrapper"
import ContentHeader from "../components/ContentHeader"
import ContentMain from "../components/ContentMain"
import { Link, useParams } from "react-router-dom"
import ClubNewsForm from "../components/ClubNewsForm"
import ClubNewsTable from "../components/ClubNewsTable"
import API from "../utils/API"
import ClubNewsDetails from "../components/ClubNewsDetails"

export default function MainClubNews({ addNews }) {
  // State
  let { newsId } = useParams()
  const [news, setNews] = useState([])
  const [loaded, setLoaded] = useState(false)

  // Comportement
  const getNews = () => {
    API.GET("/club/news", (response) => {
      setNews(response.data)
      setLoaded(true)
    })
  }

  useMemo(() => {
    getNews()
  }, [])

  const deleteNews = (id) => {
    let newsCopy = news.filter((item) => item.id !== id)
    setNews(newsCopy)
  }

  // Rendu
  return (
    <ContentWrapper>
      <ContentHeader>
        <div className="col-sm-6">
          <h1 className="m-0">Actualités du club</h1>
        </div>
        <div className="col-sm-6">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item">
              <Link to={"/"}>Accueil</Link>
            </li>
            <li className="breadcrumb-item active">Actualités du club</li>
          </ol>
        </div>
      </ContentHeader>
      <ContentMain>
        {addNews ? (
          <ClubNewsForm refreshNews={getNews} />
        ) : newsId ? (
          <ClubNewsDetails news={news} newsId={newsId} refreshNews={getNews} />
        ) : (
          <ClubNewsTable news={news} refreshNews={getNews} deleteNews={deleteNews} loaded={loaded} />
        )}
      </ContentMain>
    </ContentWrapper>
  )
}
