import React, { useMemo, useState } from "react"
import ClubActivityTableItem from "./ClubActivityTableItem"
import InputSearch from "./InputSearch"
import ButtonRefresh from "./ButtonRefresh"
import ButtonCollapse from "./ButtonCollapse"
import LoadingCard from "./LoadingCard"
import { Link } from "react-router-dom"

export default function ClubActivityTable({ title, activities, refreshActivities, deleteActivity, loaded }) {
  // State
  const [search, setSearch] = useState("")

  // Comportement
  const activitiesFiltered = useMemo(() => {
    if (activities) {
      return activities.filter(
        (activity) =>
          activity.ad.name.toLowerCase().includes(search) ||
          (activity.email && activity.email.toLowerCase().includes(search))
      )
    } else return []
  }, [activities, search])

  // Rendu
  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">{title}</h3>
        <div className="card-tools">
          <InputSearch value={search} onChange={(event) => setSearch(event.target.value.toLowerCase())} />
          <ButtonRefresh onClick={refreshActivities} />
          <ButtonCollapse />
        </div>
      </div>
      <div className="card-body table-responsive p-0" style={{ display: "block" }}>
        <LoadingCard loaded={loaded}>
          {activities.length > 0 ? (
            <table className="table table-striped projects text-nowrap">
              <thead>
                <tr>
                  <th>Date limite d'inscription</th>
                  <th>Ressource affichée</th>
                  <th>Statut</th>
                  <th>Inscription sur écran</th>
                  <th>Inscrits</th>
                  <th>Liste d'attente</th>
                  <th>Écrans</th>
                  <th className="text-right" style={{ width: "14%" }}>
                    <Link to={"./nouveau"} className="btn btn-info btn-xs mr-2">
                      <i className="fas fa-plus"></i>
                      <span className="ml-1">Ajouter</span>
                    </Link>
                  </th>
                </tr>
              </thead>
              <tbody>
                {activitiesFiltered.map((activity) => (
                  <ClubActivityTableItem activity={activity} deleteActivity={deleteActivity} key={activity.id} />
                ))}
              </tbody>
            </table>
          ) : (
            <div className="alert alert-info m-3">
              Aucune activité trouvée. <Link to={"./nouveau"}>En ajouter</Link>
            </div>
          )}
        </LoadingCard>
      </div>
    </div>
  )
}
