import React from "react"

export default function DiffusionAdCardItem({ ad, selectAd }) {
  return (
    <div className="m-3" onClick={() => selectAd(ad)}>
      <button type="button" className="btn btn-block btn-light p-0">
        <div className="info-box bg-transparent mb-0 p-0">
          <div className="info-box-content">
            <div className="d-flex justify-content-between">
              <span style={{ maxWidth: "60%", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                {ad.adTypeId === 1 && <i className="fas fa-film"></i>}
                {ad.adTypeId === 2 && <i className="far fa-image"></i>}
                {ad.adTypeId === 3 && <i className="far fa-file-pdf"></i>}
                <b> {ad.name}</b>
              </span>
              <span>
                <i className="far fa-play-circle"></i> <em>{ad.duration > 0 ? ad.duration : "~"} secondes</em>
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <span></span>
              <span>
                {ad.width}x{ad.height}
              </span>
            </div>
          </div>
        </div>
      </button>
    </div>
  )
}
