import React from "react"

export default function ContentMain({ children }) {
  return (
    <>
      {/* Main content */}
      <div className="content">{children}</div>
      {/* /.Main content */}
    </>
  )
}
