import React, { useEffect, useMemo, useRef, useState } from "react"
import ClubPresentationTotemCardItem from "./ClubPresentationTotemCardItem"
import ButtonRefresh from "./ButtonRefresh"
import ButtonCollapse from "./ButtonCollapse"
import LoadingCard from "./LoadingCard"
import { Document, Page, pdfjs } from "react-pdf"
import "react-pdf/dist/esm/Page/TextLayer.css"
import "react-pdf/dist/esm/Page/AnnotationLayer.css"

export default function ClubPresentationTotemCard({
  totems,
  loaded,
  totemSelected,
  selectTotem,
  refreshTotemsAds,
  validateSelectedTotem,
}) {
  // State
  const pdfContainer = useRef(null)
  const [width, setWidth] = useState(700)
  const [numPages, setNumPages] = useState(null)

  // Comportements
  useMemo(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`
  }, [])

  useEffect(() => {
    if (pdfContainer.current) {
      setWidth(pdfContainer.current.offsetWidth)
    }
  }, [totemSelected])

  // Rendu
  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Totems disponibles</h3>
        <div className="card-tools">
          <ButtonRefresh onClick={refreshTotemsAds} />
          <ButtonCollapse />
        </div>
      </div>
      <div className="card-body p-0" style={{ display: "block" }} id="totemCard">
        <LoadingCard loaded={loaded}>
          {totemSelected ? (
            <>
              <div className="m-3">
                <div className="info-box bg-transparent mb-0 p-0">
                  <div className="info-box-content">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex flex-column">
                        <span>
                          <i className="fas fa-tv"></i> <b>{totemSelected.name}</b>{" "}
                          <i
                            className="fas fa-circle"
                            title={totemSelected.state ? "En ligne" : "Hors ligne"}
                            style={{ color: totemSelected.state ? "green" : "red" }}
                          ></i>
                        </span>
                        <span>
                          <i className="fas fa-map-marker text-center" style={{ width: "20px" }}></i>{" "}
                          {totemSelected.address || "-"}
                        </span>
                      </div>
                      {totemSelected && (
                        <button
                          type="button"
                          className="btn btn-default float-right"
                          onClick={validateSelectedTotem}
                          style={{ height: "38px" }}
                        >
                          <i className="fas fa-arrow-left"></i> Retour
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {totemSelected.clubPresentation ? (
                <div className="m-3 d-flex justify-content-center" ref={pdfContainer}>
                  <Document
                    file={{
                      url: process.env.REACT_APP_API_HOST + "/pdf/" + totemSelected.clubPresentation.pdfId,
                      withCredentials: true,
                    }}
                    onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                  >
                    {Array.apply(null, Array(numPages))
                      .map((x, i) => i + 1)
                      .map((page) => (
                        <Page key={"pdf_p" + page} pageNumber={page} width={width} />
                      ))}
                  </Document>
                </div>
              ) : (
                <div className="alert alert-info m-3">
                  <i className="icon fas fa-info" /> Veuillez sélectionner un fichier PDF
                </div>
              )}
            </>
          ) : totems.length > 0 ? (
            totems.map((totem) => (
              <ClubPresentationTotemCardItem key={totem.id} totem={totem} selectTotem={selectTotem} />
            ))
          ) : (
            <div className="alert alert-info m-3">
              <i className="icon fas fa-info" /> Aucun totem disponible
            </div>
          )}
        </LoadingCard>
      </div>
    </div>
  )
}
