import React, { useContext, useState } from "react"
import ContentWrapper from "../components/ContentWrapper"
import ContentHeader from "../components/ContentHeader"
import ContentMain from "../components/ContentMain"
import { Link } from "react-router-dom"
import UserContext from "../UserContext"
import API from "../utils/API"
import Sweetalert from "../utils/Sweetalert"

export default function MainProfile() {
  // State
  const userContext = useContext(UserContext)
  const [isEditingPassword, setIsEditingPassword] = useState(false)
  const [oldPassword, setOldPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("")
  const [error, setError] = useState([])

  // Comportements
  const handleEditPassword = () => {
    setOldPassword("")
    setNewPassword("")
    setNewPasswordConfirm("")
    setIsEditingPassword(true)
  }

  const handleSubmitPassword = (event) => {
    event.preventDefault()
    setError([])
    let err = []

    if (newPassword.length < 9) err.push("Le nouveau mot de passe doit contenir au moins 8 caractères")
    if (!newPassword.match(/[a-z]/g)) err.push("Le nouveau mot de passe doit contenir au moins une lettre minuscule")
    if (!newPassword.match(/[A-Z]/g)) err.push("Le nouveau mot de passe doit contenir au moins une lettre majuscule")
    if (!newPassword.match(/[0-9]/g)) err.push("Le nouveau mot de passe doit contenir au moins un chiffre")
    if (newPassword !== newPasswordConfirm) err.push("Les deux mots de passe ne sont pas identiques")

    if (err.length === 0) {
      Sweetalert.fireConfirm("Validez vous ces données ?", "", () => {
        API.PUT(
          "/user/password",
          {
            oldPassword,
            newPassword,
          },
          (response) => {
            Sweetalert.fireSuccessToast(response.data.message)
            setIsEditingPassword(false)
          },
          (error) => setError([error])
        )
      })
    } else {
      setError(err)
    }
  }

  // Rendu
  return (
    <ContentWrapper>
      <ContentHeader>
        <div className="col-sm-6">
          <h1 className="m-0">Profil</h1>
        </div>
        <div className="col-sm-6">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item">
              <Link to={"/"}>Accueil</Link>
            </li>
            <li className="breadcrumb-item active">Profil</li>
          </ol>
        </div>
      </ContentHeader>
      <ContentMain>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-4">
              <div className="card card-primary card-outline">
                {isEditingPassword ? (
                  <form onSubmit={handleSubmitPassword}>
                    <div className="card-body">
                      <div className="form-group">
                        <label>Mot de passe actuel</label>
                        <input
                          type="password"
                          className="form-control"
                          value={oldPassword}
                          required
                          onChange={(event) => {
                            setOldPassword(event.target.value)
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <label>Nouveau mot de passe</label>
                        <input
                          type="password"
                          className="form-control"
                          value={newPassword}
                          required
                          onChange={(event) => {
                            setNewPassword(event.target.value)
                          }}
                        />
                        <i>
                          <span>Le mot de passe doit contenir au moins 8 caractères, mais aussi :</span>
                          <ul className="mb-0">
                            <li>
                              <span>avoir au moins une majuscule</span>
                            </li>
                            <li>
                              <span>avoir au moins un chiffre</span>
                            </li>
                            <li>
                              <span>avoir si possible un caractère spécial (optionnel)</span>
                            </li>
                          </ul>
                          <span>afin d'éviter tout problème de piratage</span>
                        </i>
                      </div>
                      <div className="form-group">
                        <label>Confirmation nouveau mot de passe</label>
                        <input
                          type="password"
                          className="form-control"
                          value={newPasswordConfirm}
                          required
                          onChange={(event) => {
                            setNewPasswordConfirm(event.target.value)
                          }}
                        />
                      </div>
                      {error &&
                        error.map((err, i) => (
                          <p key={i} className="text-danger">
                            {err}
                          </p>
                        ))}
                    </div>
                    <div className="card-footer">
                      <button type="submit" className="btn btn-primary">
                        Valider
                      </button>
                      <button
                        type="button"
                        className="btn btn-default float-right"
                        onClick={() => setIsEditingPassword(false)}
                      >
                        Annuler
                      </button>
                    </div>
                  </form>
                ) : (
                  <div className="card-body box-profile">
                    <div className="text-center mb-2">
                      <img className="img-circle" src="/dist/img/avatar-placeholder.png" alt="Avatar" width={"200px"} />
                    </div>
                    <h3 className="profile-username text-center">{userContext.name}</h3>
                    <p className="text-muted text-center">{userContext.team.name}</p>
                    <button type="button" className="btn btn-block btn-primary" onClick={() => handleEditPassword()}>
                      <i className="fas fa-lock"></i> Modifier mon mot de passe
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="col-xl-8">
              <div className="card">
                <div className="card-body m-3">
                  <div className="d-flex flex-row align-items-center">
                    <div className="pr-2" style={{ minWidth: "40%" }}>
                      <h5>Nom complet</h5>
                    </div>
                    <h6>{userContext.name}</h6>
                  </div>
                  <hr />
                  <div className="d-flex flex-row align-items-center">
                    <div className="pr-2" style={{ minWidth: "40%" }}>
                      <h5>Adresse email</h5>
                    </div>
                    <h6>{userContext.mail}</h6>
                  </div>
                  <hr />
                  <div className="d-flex flex-row align-items-center">
                    <div className="pr-2" style={{ minWidth: "40%" }}>
                      <h5>Entreprise / Équipe</h5>
                    </div>
                    <h6>{userContext.team.name}</h6>
                  </div>
                  <hr />
                  <div className="d-flex flex-row align-items-center">
                    <div className="pr-2" style={{ minWidth: "40%" }}>
                      <h5>Rôle entreprise / équipe</h5>
                    </div>
                    <h6>{userContext.role.name}</h6>
                  </div>
                  {userContext.establishmentType && (
                    <>
                      <hr />
                      <div className="d-flex flex-row align-items-center">
                        <div className="pr-2" style={{ minWidth: "40%" }}>
                          <h5>Type d'établissement</h5>
                        </div>
                        <h6>{userContext.establishmentType.name}</h6>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ContentMain>
    </ContentWrapper>
  )
}
