import React, { useContext, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import API from "../utils/API"
import Sweetalert from "../utils/Sweetalert"
import LoadingCard from "./LoadingCard"
import AdPlayerTotem from "./AdPlayerTotem"
import UserContext from "../UserContext"

export default function AdPlayerCard({ ads, adId, deleteAd, loaded }) {
  // State
  const userContext = useContext(UserContext)
  const navigate = useNavigate()

  // Comportements
  const ad = useMemo(() => {
    return ads.find((ad) => ad.id === adId)
  }, [ads, adId])

  const handleDelete = (adId) => {
    Sweetalert.fireDeleteConfirm(
      "Êtes-vous sûr ?",
      "Supprimer une ressource peut la retirer d'une playlist d'un des totem.",
      () => {
        navigate(-1) // Obligé de le faire avant sinon une erreur pop
        API.DELETE(`${userContext.role.id < 3 ? "/admin" : ""}/ad/` + adId, (response) => {
          Sweetalert.fireSuccessToast(response.data.message)
          deleteAd(adId)
        })
      }
    )
  }

  // Rendu
  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Prévisualisation ressource</h3>
      </div>
      <div className="card-body p-0" style={{ display: "block" }}>
        <LoadingCard loaded={loaded}>
          {ad ? (
            <div className="text-center m-2">
              <p className="m-0">Nom : {ad.name}</p>
              {ad.adTypeId === 1 && <p className="m-0">Durée : {ad.duration} secondes</p>}
              <p className="m-0">Format : {ad.landscape ? "Paysage" : "Portrait"}</p>
              <p className="m-0">
                Dimensions : {ad.width} x {ad.height} pixels
              </p>
              <AdPlayerTotem ad={ad} />
            </div>
          ) : (
            <div className="alert alert-info m-3">Ressource introuvable. Elle a sûrement été supprimée.</div>
          )}
        </LoadingCard>
      </div>
      <div className="card-footer">
        {ad && (
          <button className="btn btn-danger" onClick={() => handleDelete(adId)}>
            <i className="fas fa-trash"></i> Supprimer
          </button>
        )}
        <button type="button" className="btn btn-default float-right" onClick={() => navigate(-1)}>
          <i className="fas fa-arrow-left"></i> Retour
        </button>
      </div>
    </div>
  )
}
