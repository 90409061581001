import React, { useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import AdPreviewCard from "./AdPreviewCard"
import DateUtils from "../utils/Date"
import ButtonRefresh from "./ButtonRefresh"
import ButtonCollapse from "./ButtonCollapse"
import LoadingCard from "./LoadingCard"
import Select from "./Select"
import API from "../utils/API"
import Sweetalert from "../utils/Sweetalert"

export default function ClubActivityDetails({ cardTitle, activities, activityId, refreshActivities }) {
  // State
  const navigate = useNavigate()
  const [totems, setTotems] = useState([])
  const [ads, setAds] = useState([])
  const [totemsLoaded, setTotemsLoaded] = useState(false)
  const [adsLoaded, setAdsLoaded] = useState(false)
  const [editActivity, setEditActivity] = useState(false)

  const [activityAd, setActivityAd] = useState("")
  const [activityDate, setActivityDate] = useState("")
  const [activityRegOnTotem, setActivityRegOnTotem] = useState(false)
  const [activityEmailNotif, setActivityEmailNotif] = useState(false)
  const [activityEmail, setActivityEmail] = useState("")
  const [activityMaxParticipant, setActivityMaxParticipant] = useState("")
  const [activityMaxWaitingList, setActivityMaxWaitingList] = useState("")
  const [activityUrl, setActivityUrl] = useState("")
  const [activityTotems, setActivityTotems] = useState([])

  const [error, setError] = useState("")

  // Comportements
  const getTotems = () => {
    API.GET("/club/totem", (response) => {
      setTotems(response.data)
      setTotemsLoaded(true)
    })
  }

  const getAds = () => {
    API.GET("/ad", (response) => {
      setAds(response.data)
      setAdsLoaded(true)
    })
  }

  useMemo(() => {
    getTotems()
    getAds()
  }, [])

  const adsAvailable = useMemo(() => {
    return ads.filter((ad) => ad.adTypeId === 2 || ad.adTypeId === 3) // Filtre sur les Images & PDF
  }, [ads])

  const activity = useMemo(() => {
    if (activities.length > 0) {
      let activity = activities.find((activity) => activity.id === parseInt(activityId))
      setActivityAd(activity.ad.id)
      setActivityDate(DateUtils.getDateTimeLocal(activity.date))
      setActivityRegOnTotem(activity.registerUrl ? false : true)
      setActivityEmailNotif(activity.email ? true : false)
      setActivityEmail(activity.email || "")
      setActivityMaxParticipant(activity.maxParticipant || "")
      setActivityMaxWaitingList(activity.maxWaitingList || "")
      setActivityUrl(activity.registerUrl || "")
      setActivityTotems(activity.totems)
      return activity
    } else return null
  }, [activities, activityId])

  const handleSubmit = (event) => {
    event.preventDefault()
    setError("")

    Sweetalert.fireConfirm(
      "Validez vous ces données ?",
      "Les données seront mises à jour instantanéments sur les écrans associés",
      () => {
        API.PUT(
          "/club/activity/" + activityId,
          {
            adId: activityAd,
            date: activityDate,
            email: activityRegOnTotem && activityEmailNotif && activityEmail ? activityEmail : null,
            maxParticipant: activityRegOnTotem && activityMaxParticipant ? activityMaxParticipant : null,
            maxWaitingList: activityRegOnTotem && activityMaxWaitingList ? activityMaxWaitingList : null,
            registerUrl: !activityRegOnTotem && activityUrl ? activityUrl : null,
            totemsSelected: activityTotems,
          },
          (response) => {
            Sweetalert.fireSuccessToast(response.data.message)
            refreshActivities()
            setEditActivity(false)
          },
          setError
        )
      }
    )
  }

  // Rendu
  return editActivity ? (
    <>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">{cardTitle}</h3>
          <div className="card-tools">
            <ButtonCollapse />
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="card-body" style={{ display: "block" }}>
            <div className="form-group">
              <label>Ressource à afficher</label>
              <Select onChange={setActivityAd} options={adsAvailable} defaultValue={activityAd} name="adId" />
            </div>
            <div className="form-group">
              <label>Date de limite d'inscription</label>
              <input
                type="datetime-local"
                className="form-control"
                value={activityDate}
                required
                onChange={(event) => {
                  setActivityDate(event.target.value)
                }}
              />
            </div>
            {activityRegOnTotem ? (
              <>
                <div className="form-group">
                  <label>Notification par email lors d'une inscription</label>
                  <div className="form-check">
                    <label className="form-check-label" style={{ cursor: "pointer" }}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={activityEmailNotif}
                        onChange={() => setActivityEmailNotif(!activityEmailNotif)}
                      />
                      Activé
                    </label>
                  </div>
                </div>
                {activityEmailNotif && (
                  <div className="form-group">
                    <label>Adresse Email</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      value={activityEmail}
                      required
                      onChange={(event) => {
                        setActivityEmail(event.target.value)
                      }}
                    />
                  </div>
                )}
                <div className="form-group">
                  <label>Nombre de participants</label>
                  <input
                    type="number"
                    className="form-control"
                    value={activityMaxParticipant}
                    min="0"
                    placeholder="Ex : 50"
                    required
                    onChange={(event) => {
                      setActivityMaxParticipant(event.target.value)
                    }}
                  />
                </div>
                <div className="form-group">
                  <label>Taille de la liste d'attente</label>
                  <input
                    type="number"
                    className="form-control"
                    value={activityMaxWaitingList}
                    min="0"
                    placeholder="Ex : 20"
                    required
                    onChange={(event) => {
                      setActivityMaxWaitingList(event.target.value)
                    }}
                  />
                </div>
              </>
            ) : (
              <div className="form-group">
                <label>Lien d'inscription</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="https://..."
                  value={activityUrl}
                  pattern="https:\/\/[a-zA-Z0-9:_\-.\/]{1,240}"
                  title="Lien d'un site internet commençant par https (sécurisé)"
                  required
                  onChange={(event) => {
                    setActivityUrl(event.target.value)
                  }}
                />
              </div>
            )}
            <div className="form-group">
              <label>Écrans sur lesquels diffuser</label>
              <Select
                onChange={setActivityTotems}
                isMulti={true}
                options={totems}
                defaultValue={activityTotems}
                name="totemsSelected"
              />
            </div>
            <p className="text-danger">{error}</p>
          </div>
          <div className="card-footer">
            <button type="submit" className="btn btn-primary">
              Valider
            </button>
            <button type="button" className="btn btn-default float-right" onClick={() => setEditActivity(false)}>
              Annuler
            </button>
          </div>
        </form>
      </div>
      {activityAd && <AdPreviewCard adId={activityAd} />}
    </>
  ) : (
    <>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">{cardTitle}</h3>
          <div className="card-tools">
            <ButtonRefresh onClick={() => refreshActivities()} />
            <ButtonCollapse />
          </div>
        </div>
        <div className="card-body" style={{ display: "block" }}>
          <LoadingCard loaded={totemsLoaded && adsLoaded}>
            {activity && (
              <>
                <div className="row">
                  <div className="col-lg-4">
                    <h5>
                      <b>Ressource</b>
                    </h5>
                    <p>{activity.ad.name}</p>
                  </div>
                  <div className="col-lg-4">
                    <h5>
                      <b>Date limite d'inscription</b>
                    </h5>
                    <p>{DateUtils.formatDate(activity.date)}</p>
                  </div>
                  <div className="col-lg-4">
                    <h5>
                      <b>Écrans</b>
                    </h5>
                    <p>
                      {activity.totems.map((totem) => (
                        <span key={totem.id} className="badge badge-secondary mr-1">
                          {totem.name}
                        </span>
                      ))}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4">
                    <h5>
                      <b>Statut</b>
                    </h5>
                    <p>{DateUtils.isLater(activity.date) ? "Actif" : "Expiré"}</p>
                  </div>
                  <div className="col-lg-4">
                    <h5>
                      <b>Inscription sur écran</b>
                    </h5>
                    <p>{activity.registerUrl ? "Non" : "Oui"}</p>
                  </div>
                  <div className="col-lg-4">
                    <h5>
                      <b>Email de notification</b>
                    </h5>
                    <p>{activity.email || "-"}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4">
                    <h5>
                      <b>Lien d'inscription</b>
                    </h5>
                    <p>{activity.registerUrl || "-"}</p>
                  </div>
                  <div className="col-lg-4">
                    <h5>
                      <b>Nombre de participant</b>
                    </h5>
                    <p>{activity.maxParticipant ? `${activity.participantCount}/${activity.maxParticipant}` : "-"}</p>
                  </div>
                  <div className="col-lg-4">
                    <h5>
                      <b>Liste d'attente</b>
                    </h5>
                    <p>{activity.maxWaitingList ? `${activity.waitingCount}/${activity.maxWaitingList}` : "-"}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4">
                    <h5>
                      <b>En duo</b>
                    </h5>
                    <p>{activity.isDuo ? "Oui" : "Non"}</p>
                  </div>
                  <div className="col-lg-4">
                    <h5>
                      <b>Niveau demandé</b>
                    </h5>
                    <p>{activity.isLevelRequired ? "Oui" : "Non"}</p>
                  </div>
                </div>
              </>
            )}
          </LoadingCard>
        </div>
        <div className="card-footer">
          <button type="button" className="btn btn-primary" onClick={() => setEditActivity(true)}>
            <i className="far fa-edit"></i> Éditer
          </button>
          <button type="button" className="btn btn-default float-right" onClick={() => navigate(-1)}>
            <i className="fas fa-arrow-left"></i> Retour
          </button>
        </div>
      </div>
      {activity && <AdPreviewCard adId={activity.ad.id} />}
    </>
  )
}
