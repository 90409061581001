import React from "react"
import ContentHeader from "../components/ContentHeader"
import ContentMain from "../components/ContentMain"
import ContentWrapper from "../components/ContentWrapper"

export default function MainHome() {
  return (
    <ContentWrapper>
      <ContentHeader>
        <div className="col-sm-6">
          <h1 className="m-0">Page d'accueil</h1>
        </div>
        <div className="col-sm-6">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item active">Accueil</li>
          </ol>
        </div>
      </ContentHeader>
      <ContentMain>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6">
              <div className="card card-primary card-outline">
                <div className="card-body">
                  <h5 className="card-title">Bienvenue !</h5>
                  <p className="card-text">
                    Cet outil a été conçu pour gérer les totems déployés par Guillemin Affichage !
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ContentMain>
    </ContentWrapper>
  )
}
