import React, { useMemo } from "react"
import { Link } from "react-router-dom"
import LoadingCard from "./LoadingCard"
import ButtonCollapse from "./ButtonCollapse"
import ClubPresentationAdCardItem from "./ClubPresentationAdCardItem"
import API from "../utils/API"
import Sweetalert from "../utils/Sweetalert"
import ButtonLink from "./ButtonLink"

export default function ClubPresentationAdCard({ ads, loaded, totemSelected, selectPresentation, removePresentation }) {
  // State

  // Comportements
  const adsAvailable = useMemo(() => {
    if (totemSelected) {
      return ads.filter((ad) => ad.adTypeId === 3) // Filtre sur les PDF
    } else return []
  }, [ads, totemSelected])

  const selectPdf = (ad) => {
    API.POST(
      "/club/totem/" + totemSelected.id + "/presentation",
      {
        pdfId: ad.id,
      },
      (response) => {
        Sweetalert.fireSuccessToast(response.data.message)
        selectPresentation(ad.id)
      }
    )
  }

  const removePdf = () => {
    API.DELETE("/club/totem/" + totemSelected.id + "/presentation", (response) => {
      Sweetalert.fireSuccessToast(response.data.message)
      removePresentation()
    })
  }

  // Rendu
  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Mes fichiers PDF</h3>
        <div className="card-tools">
          <ButtonCollapse />
        </div>
      </div>
      <div className="card-body p-0" style={{ display: "block" }}>
        <LoadingCard loaded={loaded}>
          {totemSelected ? (
            totemSelected.clubPresentation ? (
              <div className="alert alert-info m-3">
                <i className="icon fas fa-info" /> Une présentation est déjà définie pour ce Totem.
                <ButtonLink onClick={removePdf} style={{ color: "white", textDecoration: "underline" }}>
                  Modifier
                </ButtonLink>
              </div>
            ) : adsAvailable.length > 0 ? (
              adsAvailable.map((ad) => <ClubPresentationAdCardItem key={ad.id} ad={ad} selectAd={selectPdf} />)
            ) : (
              <div className="alert alert-info m-3">
                <i className="icon fas fa-info" /> Aucun fichier PDF disponible.{" "}
                <Link to={"/ressources"}>En rajouter</Link>
              </div>
            )
          ) : (
            <div className="alert alert-info m-3">
              <i className="icon fas fa-info" /> Veuillez sélectionner un totem
            </div>
          )}
        </LoadingCard>
      </div>
    </div>
  )
}
