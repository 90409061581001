import React, { useMemo, useState } from "react"
import API from "../utils/API"
import Sweetalert from "../utils/Sweetalert"
import Select from "./Select"

export default function TeamManageDetailsForm({ teamId, totems, teamTotems, getTeam, setNewTotem }) {
  // State
  const [totemsAvailable, setTotemsAvailable] = useState([])
  const [newTotemId, setNewTotemId] = useState("")
  const [newTotemDuration, setNewTotemDuration] = useState("")
  const [error, setError] = useState("")

  // Comportements
  const setTotems = (totems, teamTotems) => {
    let totemsAlreadyAdded = []
    teamTotems.forEach((totem) => totemsAlreadyAdded.push(totem.id))
    setTotemsAvailable(totems.filter((totem) => !totemsAlreadyAdded.includes(totem.id)))
  }

  useMemo(() => {
    setTotems(totems, teamTotems)
  }, [totems, teamTotems])

  const handleSubmit = (event) => {
    event.preventDefault()
    setError("")

    Sweetalert.fireConfirm(
      "Validez vous ces données ?",
      "Une fois validé, l'équipe aura accès à certaines fonctionnalités de ce totem",
      () => {
        API.POST(
          "/admin/team/" + teamId + "/totem",
          {
            totemId: newTotemId,
            duration: newTotemDuration,
          },
          (response) => {
            Sweetalert.fireSuccessToast(response.data.message)
            getTeam(teamId)
            setNewTotem(false)
          }
        )
      }
    )
  }

  // Rendu
  return (
    <div className="card card-warning">
      <div className="card-header">
        <h3 className="card-title">Ajout d'un nouveau totem</h3>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="card-body">
          <div className="form-group">
            <label>Totem</label>
            <Select onChange={setNewTotemId} options={totemsAvailable} name="totemId" />
          </div>
          <div className="form-group">
            <label>Durée de diffusion (en secondes)</label>
            <input
              type="number"
              className="form-control"
              value={newTotemDuration}
              min="1"
              required
              onChange={(event) => {
                setNewTotemDuration(event.target.value)
              }}
            />
          </div>
          <p className="text-danger">{error}</p>
        </div>
        <div className="card-footer">
          <button type="submit" className="btn btn-primary">
            Valider
          </button>
          <button type="button" className="btn btn-default float-right" onClick={() => setNewTotem(false)}>
            Annuler
          </button>
        </div>
      </form>
    </div>
  )
}
