import { NavLink } from "react-router-dom"

function MenuCommon() {
  return (
    <>
      <li className="nav-item">
        <NavLink to="/ressources" className="nav-link">
          <i className="nav-icon fas fa-play" />
          <p>Mes ressources</p>
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink to="/diffusion" className="nav-link">
          <i className="nav-icon fas fa-broadcast-tower" />
          <p>Diffusion publicitaire</p>
        </NavLink>
      </li>
    </>
  )
}

export default MenuCommon
