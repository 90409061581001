const formatDate = (dateText) => {
  let date = new Date(dateText)
  return date.toLocaleString("fr-FR").replace(" ", " à ")
}

const getDateTimeLocal = (dateText) => {
  let date = new Date(dateText)
  let dateTimeLocalSplitted = date.toLocaleString("fr-FR").split(" ")
  let dateLocalSplitted = dateTimeLocalSplitted[0].split("/")
  return dateLocalSplitted[2] + "-" + dateLocalSplitted[1] + "-" + dateLocalSplitted[0] + "T" + dateTimeLocalSplitted[1]
}

const isLater = (dateText) => {
  let date = new Date(dateText)
  return date > new Date()
}

const exportedObject = { formatDate, getDateTimeLocal, isLater }

export default exportedObject
