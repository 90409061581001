import React, { useMemo, useState } from "react"
import ContentWrapper from "../components/ContentWrapper"
import ContentHeader from "../components/ContentHeader"
import ContentMain from "../components/ContentMain"
import { Link } from "react-router-dom"
import ClubPresentationAdCard from "../components/ClubPresentationAdCard"
import API from "../utils/API"
import ClubPresentationTotemCard from "../components/ClubPresentationTotemCard"

export default function MainClubPresentation() {
  // State
  const [ads, setAds] = useState([])
  const [adsLoaded, setAdsLoaded] = useState(false)
  const [totems, setTotems] = useState([])
  const [totemsLoaded, setTotemsLoaded] = useState(false)
  const [totemSelected, setTotemSelected] = useState(null)

  // Comportements
  const getAds = () => {
    API.GET("/ad", (response) => {
      setAds(response.data)
      setAdsLoaded(true)
    })
  }

  const getTotems = () => {
    API.GET("/club/totem", (response) => {
      setTotems(response.data)
      setTotemsLoaded(true)
    })
  }

  const refreshTotemsAds = () => {
    setAdsLoaded(false)
    setTotemsLoaded(false)
    getAds()
    getTotems()
  }

  useMemo(() => {
    getAds()
    getTotems()
  }, [])

  const selectPresentation = (pdfId) => {
    let totemSelectedCopy = { ...totemSelected }
    totemSelectedCopy.clubPresentation = {
      pdfId: pdfId,
      totemId: totemSelectedCopy.id,
    }
    setTotemSelected(totemSelectedCopy)
  }

  const removePresentation = () => {
    let totemSelectedCopy = { ...totemSelected }
    totemSelectedCopy.clubPresentation = null
    setTotemSelected(totemSelectedCopy)
  }

  const validateSelectedTotem = () => {
    let totemsCopy = [...totems]
    let selectedTotemIndex = totemsCopy.findIndex((totem) => totem.id === totemSelected.id)
    totemsCopy[selectedTotemIndex] = { ...totemSelected }
    setTotems(totemsCopy)
    setTotemSelected(null)
  }

  // Rendu
  return (
    <ContentWrapper>
      <ContentHeader>
        <div className="col-sm-6">
          <h1 className="m-0">Présentation du club</h1>
        </div>
        <div className="col-sm-6">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item">
              <Link to={"/"}>Accueil</Link>
            </li>
            <li className="breadcrumb-item active">Présentation du club</li>
          </ol>
        </div>
      </ContentHeader>
      <ContentMain>
        <div className="row">
          <div className="col-lg-4">
            <ClubPresentationAdCard
              ads={ads}
              loaded={adsLoaded}
              totemSelected={totemSelected}
              selectPresentation={selectPresentation}
              removePresentation={removePresentation}
            />
          </div>
          <div className="col-lg-8">
            <ClubPresentationTotemCard
              totems={totems}
              loaded={totemsLoaded}
              totemSelected={totemSelected}
              selectTotem={setTotemSelected}
              refreshTotemsAds={refreshTotemsAds}
              validateSelectedTotem={validateSelectedTotem}
            />
          </div>
        </div>
      </ContentMain>
    </ContentWrapper>
  )
}
