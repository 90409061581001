import React, { useContext, useState } from "react"
import Sweetalert from "../utils/Sweetalert"
import UserContext from "../UserContext"
import ButtonEdit from "./ButtonEdit"
import InputEdit from "./InputEdit"
import API from "../utils/API"
import SelectEdit from "./SelectEdit"

export default function UserManageTableItem({ user, teams, updateUser, deleteUser }) {
  // State
  const [editMail, setEditMail] = useState(false)
  const [editName, setEditName] = useState(false)
  const [editTeam, setEditTeam] = useState(false)
  const [newValue, setNewValue] = useState("")
  const userContext = useContext(UserContext)
  const roleColor = {
    1: "red",
    2: "purple",
    3: "green",
    4: "blue",
  }

  // Comportements
  const handleDelete = (id) => {
    Sweetalert.fireDeleteConfirm(
      "Êtes-vous sûr ?",
      "Supprimer un utilisateur de cette liste supprimera toute donnée associée à ce dernier.",
      () => {
        API.DELETE("/admin/user/" + id, (response) => {
          Sweetalert.fireSuccessToast(response.data.message)
          deleteUser(id)
        })
      }
    )
  }

  const handleEditSubmit = (field) => {
    Sweetalert.fireConfirm(
      "Validez vous cette mise à jour ?",
      "Attention, mettre à jour un utilisateur le forcera à se reconnecter à l'application.",
      () => {
        let userCopy = { ...user }
        switch (field) {
          case "mail":
            userCopy.mail = newValue
            break

          case "name":
            userCopy.name = newValue
            break

          case "team":
            userCopy.team = teams.find((team) => team.id === parseInt(newValue))
            break

          default:
            break
        }
        API.PUT(
          "/admin/user/" + user.id,
          {
            mail: userCopy.mail,
            name: userCopy.name,
            teamId: userCopy.team.id,
          },
          (response) => {
            if (userCopy.id === userContext.id) {
              Sweetalert.fireSuccessToast("Utilisateur mis à jour, redirection vers la page de login...")
              setTimeout(() => {
                userContext.signOut()
              }, 2500)
            } else Sweetalert.fireSuccessToast(response.data.message)
            updateUser(user.id, userCopy)
            cancelEdit()
          }
        )
      }
    )
  }

  const cancelEdit = () => {
    setEditMail(false)
    setEditName(false)
    setEditTeam(false)
  }

  const handleEdit = (field) => {
    cancelEdit()
    switch (field) {
      case "mail":
        setNewValue(user.mail)
        setEditMail(true)
        break

      case "name":
        setNewValue(user.name)
        setEditName(true)
        break

      case "team":
        setNewValue(user.team.id)
        setEditTeam(true)
        break

      default:
        break
    }
  }

  // Rendu
  return (
    <tr>
      <td>
        <span title={user.id}>#</span>
      </td>
      <td>
        {editMail ? (
          <InputEdit
            type={"email"}
            value={newValue}
            onChange={(event) => setNewValue(event.target.value)}
            onSubmit={() => handleEditSubmit("mail")}
            onCancel={cancelEdit}
          />
        ) : (
          <>
            {user.mail} <ButtonEdit onClick={() => handleEdit("mail")} />
          </>
        )}
      </td>
      <td>
        {editName ? (
          <InputEdit
            value={newValue}
            pattern={"[a-zA-Záàâäçéèêëíìîïñóòôöúùûüýÿæœ ]{1,255}"}
            title={"Lettres et espaces entre 1 et 255 caractères"}
            onChange={(event) => setNewValue(event.target.value)}
            onSubmit={() => handleEditSubmit("name")}
            onCancel={cancelEdit}
          />
        ) : (
          <>
            {user.name} {userContext.id === user.id ? <strong>(Moi)</strong> : ""}{" "}
            <ButtonEdit onClick={() => handleEdit("name")} />
          </>
        )}
      </td>
      <td>
        {editTeam ? (
          <SelectEdit
            defaultValue={user.team.id}
            onChange={setNewValue}
            onSubmit={() => handleEditSubmit("team")}
            onCancel={cancelEdit}
            options={teams}
            name="teamId"
          />
        ) : (
          <>
            <i
              title={user.team.role.name}
              className="fas fa-user-tag"
              style={{ color: roleColor[user.team.role.id] }}
            ></i>{" "}
            {user.team.name} <ButtonEdit onClick={() => handleEdit("team")} />
          </>
        )}
      </td>
      <td className="project-actions text-right">
        <button className="btn btn-danger btn-xs" onClick={() => handleDelete(user.id)}>
          <i className="fas fa-trash"></i>
          <span className="ml-1">Supprimer</span>
        </button>
      </td>
    </tr>
  )
}
