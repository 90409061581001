import React, { useState, useMemo } from "react"
import LoadingCard from "./LoadingCard"
import API from "../utils/API"
import ButtonCollapse from "./ButtonCollapse"
import DateUtils from "../utils/Date"
import Sweetalert from "../utils/Sweetalert"
import ButtonLink from "./ButtonLink"

export default function TotemManageDetailsLogs({ totem }) {
  // State
  const [logs, setLogs] = useState([])
  const [loaded, setLoaded] = useState(false)

  // Comportements
  const getLogs = (totem) => {
    API.GET("/admin/totem/" + totem.id + "/logs", (response) => {
      setLogs(response.data)
      setLoaded(true)
    })
  }

  useMemo(() => {
    if (totem) getLogs(totem)
  }, [totem])

  // Rendu
  return (
    <LoadingCard loaded={loaded}>
      <div className="card card-outline card-secondary">
        <div className="card-header">
          <h3 className="card-title">Logs du player</h3>
          <div className="card-tools">
            <ButtonCollapse />
          </div>
        </div>
        <div className="card-body p-0" style={{ display: "block" }}>
          <LoadingCard loaded={loaded}>
            {logs && logs.length > 0 ? (
              <table className="table table-striped projects">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Message</th>
                  </tr>
                </thead>
                <tbody>
                  {logs.map((log) => (
                    <tr key={log.id}>
                      <td>{DateUtils.formatDate(log.createdAt)}</td>
                      <td>
                        <ButtonLink
                          onClick={() => {
                            Sweetalert.fireInfo(DateUtils.formatDate(log.createdAt), log.message)
                          }}
                          style={{
                            maxWidth: "320px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {log.message}
                        </ButtonLink>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="alert alert-info m-3">Aucun log trouvé.</div>
            )}
          </LoadingCard>
        </div>
      </div>
    </LoadingCard>
  )
}
