import React, { useEffect, useMemo } from "react"
import ButtonCollapse from "./ButtonCollapse"
import { useRef } from "react"
import { useState } from "react"
import { Document, Page, pdfjs } from "react-pdf"
import "react-pdf/dist/esm/Page/TextLayer.css"
import "react-pdf/dist/esm/Page/AnnotationLayer.css"
import LoadingCard from "./LoadingCard"
import API from "../utils/API"

export default function AdPreviewCard({ adId }) {
  // State
  const [ad, setAd] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const pdfContainer = useRef(null)
  const [width, setWidth] = useState(500)
  const [numPages, setNumPages] = useState(null)

  // Comportement
  useMemo(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`
  }, [])

  useMemo(() => {
    API.GET("/ad/" + adId, (response) => {
      setAd(response.data)
      setLoaded(true)
    })
  }, [adId])

  useEffect(() => {
    if (pdfContainer.current) {
      setWidth(pdfContainer.current.offsetWidth)
    }
  }, [ad])

  // Rendu
  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Prévisualisation de la ressource</h3>
        <div className="card-tools">
          <ButtonCollapse />
        </div>
      </div>
      <div className="card-body" style={{ display: "block" }}>
        <LoadingCard loaded={loaded}>
          {ad &&
            ad.adTypeId === 2 && ( // IMAGE
              <div className="m-3">
                <img src={process.env.REACT_APP_API_HOST + "/image/" + ad.id} width={"100%"} alt="Prévisualisation" />
              </div>
            )}
          {ad &&
            ad.adTypeId === 3 && ( // PDF
              <div className="m-3 d-flex justify-content-center" ref={pdfContainer}>
                <Document
                  file={{
                    url: process.env.REACT_APP_API_HOST + "/pdf/" + ad.id,
                    withCredentials: true,
                  }}
                  onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                >
                  {Array.apply(null, Array(numPages))
                    .map((x, i) => i + 1)
                    .map((page) => (
                      <Page key={"pdf_p" + page} pageNumber={page} width={width} />
                    ))}
                </Document>
              </div>
            )}
        </LoadingCard>
      </div>
    </div>
  )
}
