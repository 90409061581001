import { useContext } from "react"
import { Link } from "react-router-dom"
import UserContext from "../UserContext"
import SidebarMenu from "./SidebarMenu"

function Sidebar() {
  const userContext = useContext(UserContext)

  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      {/* Brand Logo */}
      <Link
        to={"/"}
        className={"brand-link"}
        style={{ background: "linear-gradient(0deg, rgba(79,89,98,1) 0%, rgba(0,0,0,0) 4%) white" }}
      >
        <img src="/dist/img/GuilleminAffichageLogo.png" alt="Guillemin Affichage" width={"100%"} />
      </Link>
      {/* Sidebar */}
      <div className="sidebar">
        {/* Sidebar user panel (optional) */}
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          <div className="info">
            <span className="d-block" style={{ color: "grey" }}>
              {userContext.name}
            </span>
            <span className="d-block" style={{ color: "grey" }}>
              Équipe : {userContext.team.name}
            </span>
          </div>
        </div>
        <SidebarMenu />
      </div>
      {/* /.sidebar */}
    </aside>
  )
}

export default Sidebar
