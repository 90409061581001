import React, { useMemo, useState } from "react"
//import { Link } from "react-router-dom"
import API from "../utils/API"
import ButtonCollapse from "./ButtonCollapse"
import ButtonRefresh from "./ButtonRefresh"
import InputSearch from "./InputSearch"
import LoadingCard from "./LoadingCard"
import TeamManageTableItem from "./TeamManageTableItem"

export default function TeamManageTable() {
  // State
  const [loaded, setLoaded] = useState(false)
  const [teams, setTeams] = useState([])
  const [teamsFiltered, setTeamsFiltered] = useState([])
  const [search, setSearch] = useState("")

  // Comportements
  const getTeams = () => {
    API.GET("/admin/team", (response) => {
      setTeams(response.data)
      setLoaded(true)
    })
  }

  useMemo(() => {
    getTeams()
  }, [])

  const deleteTeam = (id) => {
    let teamsCopy = teams.filter((team) => team.id !== id)
    setTeams(teamsCopy)
  }

  useMemo(() => {
    if (teams) {
      let teamsFilteredCopy = teams.filter(
        (team) =>
          team.name.toLowerCase().includes(search) ||
          team.role.name.toLowerCase().includes(search) ||
          (team.establishmentType !== null && team.establishmentType.name.toLowerCase().includes(search))
      )
      setTeamsFiltered(teamsFilteredCopy)
    }
  }, [search, teams])

  // Rendu
  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Liste des équipes</h3>
        <div className="card-tools">
          <InputSearch value={search} onChange={(event) => setSearch(event.target.value.toLowerCase())} />
          <ButtonRefresh onClick={getTeams} />
          <ButtonCollapse />
        </div>
      </div>
      <div className="card-body table-responsive p-0" style={{ display: "block" }}>
        <LoadingCard loaded={loaded}>
          {teams.length > 0 ? (
            <table className="table table-striped projects text-nowrap">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Nom</th>
                  <th>
                    <i className="fas fa-user-tag"></i> Rôle
                  </th>
                  <th>Type d'établissement</th>
                  <th className="text-right">
                    {/*<Link to={"/equipe/gestion/nouveau"} className="btn btn-info btn-xs mr-2">
                      <i className="fas fa-plus"></i>
                      <span className="ml-1">Ajouter</span>
                    </Link>*/}
                  </th>
                </tr>
              </thead>
              <tbody>
                {teamsFiltered.map((team) => (
                  <TeamManageTableItem team={team} deleteTeam={deleteTeam} key={team.id} />
                ))}
              </tbody>
            </table>
          ) : (
            <div className="alert alert-info m-3">
              <h5>
                <i className="icon fas fa-info" /> Rien !
              </h5>
              Aucune équipe.
            </div>
          )}
        </LoadingCard>
      </div>
    </div>
  )
}
