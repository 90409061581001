import React from "react"
import ButtonCollapse from "./ButtonCollapse"
import { useMemo } from "react"
import LoadingCard from "./LoadingCard"
import { Link } from "react-router-dom"
import Sweetalert from "../utils/Sweetalert"
import API from "../utils/API"

export default function AdManageTotemTable({ ads, adId, deleteTotemFromAd, loaded }) {
  // State

  // Comportements
  const ad = useMemo(() => {
    return ads.find((ad) => ad.id === adId)
  }, [ads, adId])

  const handleDelete = (id) => {
    Sweetalert.fireDeleteConfirm(
      "Êtes-vous sûr ?",
      "Vous êtes sur le point de retirer la publicité de la liste de diffusion de ce Totem.",
      () => {
        API.DELETE("/admin/totem/" + id + "/ad/" + adId, (response) => {
          Sweetalert.fireSuccessToast(response.data.message)
          deleteTotemFromAd(adId, id)
        })
      }
    )
  }

  // Rendu
  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Diffusion (Équipe / Entreprise : {ad && ad.team.name})</h3>
        <div className="card-tools">
          <ButtonCollapse />
        </div>
      </div>
      <div className="card-body table-responsive p-0" style={{ display: "block" }}>
        <LoadingCard loaded={loaded}>
          {ad && ad.totems.length > 0 ? (
            <table className="table table-striped projects text-nowrap">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Nom</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {ad.totems.map((totem) => (
                  <tr key={totem.id}>
                    <td>
                      <span title={totem.id}>#</span>
                    </td>
                    <td>
                      <Link to={"/totem/gestion/" + totem.id}>{totem.name}</Link>
                    </td>
                    <td className="project-actions text-right">
                      <button className="btn btn-danger btn-xs" onClick={() => handleDelete(totem.id)}>
                        <i className="fas fa-trash"></i>
                        <span className="ml-1">Supprimer</span>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="alert alert-info m-3">Diffusé sur aucun Totem.</div>
          )}
        </LoadingCard>
      </div>
    </div>
  )
}
