import React, { useCallback, useMemo, useState } from "react"
import ButtonCollapse from "./ButtonCollapse"
import ButtonRefresh from "./ButtonRefresh"
import InputSearch from "./InputSearch"
import LoadingCard from "./LoadingCard"
import AdCardItem from "./AdCardItem"
import AdCardFolder from "./AdCardFolder"
import Sweetalert from "../utils/Sweetalert"
import API from "../utils/API"

export default function AdCard({
  ads,
  refreshAds,
  refreshAdPaths,
  deleteAd,
  adPaths,
  deleteAdPath,
  currentAdPath,
  setCurrentAdPath,
  loaded,
}) {
  // State
  const [search, setSearch] = useState("")

  // Comportements
  const filterAds = (ads, currentAdPath, search) => {
    return ads.filter((ad) => currentAdPath === ad.adPathId && ad.name.toLowerCase().includes(search))
  }

  const filterAdPaths = (adPaths, currentAdPath, search) => {
    return adPaths.filter((adPath) => currentAdPath === adPath.parent && adPath.name.toLowerCase().includes(search))
  }

  const adsFiltered = useMemo(() => filterAds(ads, currentAdPath, search), [ads, currentAdPath, search])
  const adPathsFiltered = useMemo(() => filterAdPaths(adPaths, currentAdPath, search), [adPaths, currentAdPath, search])

  const buildPath = useCallback(() => {
    let path = []
    let current

    const updateCurrent = (id) => {
      current = adPaths.find((adPath) => adPath.id === id)
    }

    updateCurrent(currentAdPath)

    while (current) {
      path.unshift(current.name)
      updateCurrent(current.parent)
    }

    return path.join(" / ")
  }, [adPaths, currentAdPath])

  const handleRefresh = () => {
    refreshAds()
    refreshAdPaths()
  }

  const getParentAdPathId = (currentAdPath) => {
    let parentPath = adPaths.find((adPath) => adPath.id === currentAdPath)
    return parentPath ? parentPath.parent : null
  }

  const createFolder = () => {
    Sweetalert.askName(
      "Nom du dossier",
      "Définissez le nom du dossier à créer",
      "Nom (Exemple : Dossier 1)",
      (response) => {
        API.POST(
          "/ad/path",
          {
            name: response.value,
            parent: currentAdPath,
          },
          (response) => {
            Sweetalert.fireSuccessToast(response.data.message)
            refreshAdPaths()
          }
        )
      }
    )
  }

  // Rendu
  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Ressources - {currentAdPath ? "/ " + buildPath() : "/"}</h3>
        <div className="card-tools">
          <button className="btn btn-info btn-xs mr-2" onClick={createFolder}>
            <i className="fas fa-plus"></i>
            <span className="ml-1">Nouveau dossier</span>
          </button>
          <InputSearch value={search} onChange={(event) => setSearch(event.target.value.toLowerCase())} />
          <ButtonRefresh onClick={handleRefresh} />
          <ButtonCollapse />
        </div>
      </div>
      <div className="card-body p-0" style={{ display: "block" }}>
        <LoadingCard loaded={loaded}>
          {adPathsFiltered.length > 0 || adsFiltered.length > 0 || currentAdPath ? (
            <ul className="users-list clearfix">
              {currentAdPath && (
                <AdCardFolder
                  folderName="Retour"
                  folderId={getParentAdPathId(currentAdPath)}
                  setCurrentAdPath={setCurrentAdPath}
                  deleteAdPath={null}
                />
              )}
              {adPathsFiltered.map((adPath) => (
                <AdCardFolder
                  key={adPath.id}
                  folderName={adPath.name}
                  folderId={adPath.id}
                  setCurrentAdPath={setCurrentAdPath}
                  deleteAdPath={deleteAdPath}
                />
              ))}
              {adsFiltered.map((ad) => (
                <AdCardItem ad={ad} key={ad.id} deleteAd={deleteAd} />
              ))}
            </ul>
          ) : (
            <div className="alert alert-info m-3">
              <i className="icon fas fa-info" /> Aucune ressource trouvée
            </div>
          )}
        </LoadingCard>
      </div>
    </div>
  )
}
