import axios from "axios"
import ErrorHandler from "./ErrorHandler"

const a = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  withCredentials: true,
})

const GET = (resource, callback, errorCallback) => {
  a.get(resource)
    .then((response) => {
      callback(response)
    })
    .catch((error) => {
      ErrorHandler.handleAxiosError(error, errorCallback)
    })
}

const POST = (resource, data, callback, errorCallback) => {
  a.post(resource, data)
    .then((response) => {
      callback(response)
    })
    .catch((error) => {
      ErrorHandler.handleAxiosError(error, errorCallback)
    })
}

const PUT = (resource, data, callback, errorCallback) => {
  a.put(resource, data)
    .then((response) => {
      callback(response)
    })
    .catch((error) => {
      ErrorHandler.handleAxiosError(error, errorCallback)
    })
}

const DELETE = (resource, callback, errorCallback) => {
  a.delete(resource)
    .then((response) => {
      callback(response)
    })
    .catch((error) => {
      ErrorHandler.handleAxiosError(error, errorCallback)
    })
}

const CUSTOM = (method, url, data, callback, errorCallback) => {
  a({
    method: method,
    url: url,
    data: data,
  })
    .then((response) => {
      callback(response)
    })
    .catch((error) => {
      errorCallback(error)
    })
}

const API = { GET, POST, PUT, DELETE, CUSTOM }

export default API
