import { useState } from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import LoadingScreen from "./utils/LoadingScreen"
import UserContext from "./UserContext"
import PrivateLoginRoutes from "./utils/PrivateLoginRoutes"
import Main from "./pages/Main"
import Login from "./pages/Login"
import API from "./utils/API"

export default function App() {
  // State
  const [authenticated, setAuthenticated] = useState(false)
  const [authInfos, setAuthInfos] = useState({
    mail: null,
    name: null,
    team: null,
    role: null,
    establishmentType: null,
  })

  // Comportements
  const signIn = (authInfosCopy, callback) => {
    if (authInfosCopy.role.id === 1 || authInfosCopy.role.id === 2) {
      // If Admin or Dev
      API.GET("/admin/role", (response) => {
        authInfosCopy.roles = response.data
        API.GET("/admin/establishmentType", (response) => {
          authInfosCopy.establishmentTypes = response.data
          setAuthenticated(true)
          setAuthInfos({ ...authInfosCopy, signOut })
          callback()
        })
      })
    } else {
      setAuthenticated(true)
      setAuthInfos({ ...authInfosCopy, signOut })
      callback()
    }
  }

  const signOut = () => {
    API.GET("/logout", () => {
      setAuthenticated(false)
      setAuthInfos({
        mail: null,
        name: null,
        team: null,
        role: null,
        establishmentType: null,
      })
    })
  }

  // Render
  return (
    <BrowserRouter>
      <LoadingScreen signIn={signIn}>
        <UserContext.Provider value={authInfos}>
          <Routes>
            <Route element={<PrivateLoginRoutes authenticated={authenticated} />}>
              <Route path="/*" element={<Main />} />
            </Route>
            <Route path="/login" element={<Login signIn={signIn} />} />
          </Routes>
        </UserContext.Provider>
      </LoadingScreen>
    </BrowserRouter>
  )
}
