import React, { useContext } from "react"
import { Route, Routes } from "react-router-dom"
import UserContext from "../UserContext"
import PrivateRoleRoutes from "../utils/PrivateRoleRoutes"
import MainDiffusion from "./MainDiffusion"
import MainError from "./MainError"
import MainHome from "./MainHome"
import MainTeamManage from "./MainTeamManage"
import MainTotemManage from "./MainTotemManage"
import MainTotemValidate from "./MainTotemValidate"
import MainUserManage from "./MainUserManage"
import MainAd from "./MainAd"
import MainProfile from "./MainProfile"
import MainAdManage from "./MainAdManage"
import MainClubPresentation from "./MainClubPresentation"
import MainClubNews from "./MainClubNews"
import MainClubTournament from "./MainClubTournament"
import MainClubAnimation from "./MainClubAnimation"
import MainClubInternship from "./MainClubInternship"

export default function MainRouter() {
  // State
  const userContext = useContext(UserContext)
  const ROLE_DEVEL = 1
  const ROLE_ADMIN = 2
  const ROLE_ANNON = 3
  const ROLE_ETABL = 4
  const ETAB_HOTEL = 1
  const ETAB_CLUB = 2

  // Comportements

  // Rendu
  return (
    <Routes>
      <Route path="/" element={<MainHome />} />
      {/* Routes pour Admin et développeurs */}
      <Route element={<PrivateRoleRoutes role={userContext.role} rolesNeeded={[ROLE_DEVEL, ROLE_ADMIN]} />}>
        <Route path="/utilisateur/gestion" element={<MainUserManage />} />
        <Route path="/utilisateur/gestion/nouveau" element={<MainUserManage addUser={true} />} />
        <Route path="/utilisateur/gestion/nouveau/:teamId" element={<MainUserManage addUser={true} />} />
        <Route path="/equipe/gestion" element={<MainTeamManage />} />
        <Route path="/equipe/gestion/:teamId" element={<MainTeamManage />} />
        <Route path="/ressource/gestion" element={<MainAdManage />} />
        <Route path="/ressource/gestion/:adId" element={<MainAdManage />} />
        <Route path="/totem/gestion" element={<MainTotemManage />} />
        <Route path="/totem/gestion/:totemId" element={<MainTotemManage />} />
        <Route path="/totem/validation" element={<MainTotemValidate />} />
        <Route path="/totem/validation/:totemId" element={<MainTotemValidate />} />
      </Route>
      {/* Routes pour Annonceurs */}
      <Route element={<PrivateRoleRoutes role={userContext.role} rolesNeeded={[ROLE_ANNON]} />}>{/* TODO */}</Route>
      {/* Routes pour Etablissements */}
      {/* Routes pour Hotel */}
      <Route
        element={
          <PrivateRoleRoutes
            role={userContext.role}
            rolesNeeded={[ROLE_ETABL]}
            establishment={userContext.establishment}
            establishmentNeeded={[ETAB_HOTEL]}
          />
        }
      >
        {/* RIEN POUR LE MOMENT */}
      </Route>
      {/* Routes pour Club */}
      <Route
        element={
          <PrivateRoleRoutes
            role={userContext.role}
            rolesNeeded={[ROLE_ETABL]}
            establishmentType={userContext.establishmentType}
            establishmentNeeded={[ETAB_CLUB]}
          />
        }
      >
        <Route path="/club/presentation" element={<MainClubPresentation />} />
        <Route path="/club/actualites" element={<MainClubNews />} />
        <Route path="/club/actualites/nouveau" element={<MainClubNews addNews={true} />} />
        <Route path="/club/actualites/:newsId" element={<MainClubNews />} />
        <Route path="/club/tournois" element={<MainClubTournament />} />
        <Route path="/club/tournois/nouveau" element={<MainClubTournament addTournament={true} />} />
        <Route path="/club/tournois/:tournamentId" element={<MainClubTournament />} />
        <Route path="/club/animations" element={<MainClubAnimation />} />
        <Route path="/club/animations/nouveau" element={<MainClubAnimation addAnimation={true} />} />
        <Route path="/club/animations/:animationId" element={<MainClubAnimation />} />
        <Route path="/club/stages" element={<MainClubInternship />} />
        <Route path="/club/stages/nouveau" element={<MainClubInternship addInternship={true} />} />
        <Route path="/club/stages/:internshipId" element={<MainClubInternship />} />
      </Route>
      {/* Routes communes */}
      <Route path="/profil" element={<MainProfile />} />
      <Route path="/ressources" element={<MainAd />} />
      <Route path="/ressources/:adId" element={<MainAd />} />
      <Route path="/diffusion" element={<MainDiffusion />} />
      {/* Route d'erreur */}
      <Route path="*" element={<MainError />} />
    </Routes>
  )
}
