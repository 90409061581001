import React, { useState } from "react"
import API from "../utils/API"
import Sweetalert from "../utils/Sweetalert"
import ButtonEdit from "./ButtonEdit"
import InputEdit from "./InputEdit"

export default function TeamManageDetailsTotems({ totem, teamId, updateTeamTotem, deleteTotem }) {
  // State
  const [editDuration, setEditDuration] = useState(false)
  const [newValue, setNewValue] = useState("")

  // Comportements
  const handleEdit = () => {
    setEditDuration(true)
    setNewValue(totem.team_totem.duration)
  }

  const cancelEdit = () => {
    setEditDuration(false)
  }

  const handleEditSubmit = () => {
    API.PUT(
      "/admin/team/" + teamId + "/totem/" + totem.id,
      {
        duration: newValue,
      },
      (response) => {
        Sweetalert.fireSuccessToast(response.data.message)
        updateTeamTotem(totem.id, newValue)
        cancelEdit()
      }
    )
  }

  const handleDelete = () => {
    Sweetalert.fireDeleteConfirm(
      "Êtes-vous sûr ?",
      "Supprimer un totem de cette liste enlèvera les droits de diffusion de l'équipe sur ce dernier",
      () => {
        API.DELETE("/admin/team/" + teamId + "/totem/" + totem.id, (response) => {
          Sweetalert.fireSuccessToast(response.data.message)
          deleteTotem(totem.id)
        })
      }
    )
  }

  // Rendu
  return (
    <tr>
      <td>
        <span title={totem.id}>#</span>
      </td>
      <td>{totem.name}</td>
      <td>{totem.address}</td>
      <td>
        {editDuration ? (
          <InputEdit
            type="number"
            value={newValue}
            onChange={(event) => setNewValue(event.target.value)}
            onSubmit={() => handleEditSubmit()}
            onCancel={cancelEdit}
          />
        ) : (
          <>
            {totem.team_totem.duration}s <ButtonEdit onClick={() => handleEdit()} />
          </>
        )}
      </td>
      <td>
        <button className="btn btn-danger btn-xs" onClick={() => handleDelete()}>
          <i className="fas fa-trash"></i>
        </button>
      </td>
    </tr>
  )
}
