import React, { useContext, useMemo, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import UserContext from "../UserContext"
import API from "../utils/API"
import DateUtils from "../utils/Date"
import Sweetalert from "../utils/Sweetalert"
import ButtonCollapse from "./ButtonCollapse"
import ButtonEdit from "./ButtonEdit"
import ButtonLink from "./ButtonLink"
import ButtonRefresh from "./ButtonRefresh"
import InputEdit from "./InputEdit"
import LoadingCard from "./LoadingCard"
import SelectEdit from "./SelectEdit"
import TeamManageDetailsForm from "./TeamManageDetailsForm"
import TeamManageDetailsTotems from "./TeamManageDetailsTotems"
import TeamManageDetailsUsers from "./TeamManageDetailsUsers"

export default function TeamManageDetails({ teamId }) {
  // State
  const navigate = useNavigate()
  const [team, setTeam] = useState(null)
  const [totems, setTotems] = useState([])
  const [loaded, setLoaded] = useState(false)
  const [newTotem, setNewTotem] = useState(false)
  const [editName, setEditName] = useState(false)
  const [editRole, setEditRole] = useState(false)
  const [editEstablishmentType, setEditEstablishmentType] = useState(false)
  const [newValue, setNewValue] = useState("")
  const userContext = useContext(UserContext)

  // Comportements
  const getTeam = (teamId) => {
    API.GET("/admin/team/" + teamId, (response) => {
      setTeam(response.data)
      setLoaded(true)
    })
  }

  const getTotems = () => {
    API.GET("/admin/totem", (response) => {
      setTotems(response.data)
    })
  }

  useMemo(() => {
    getTeam(teamId)
    getTotems()
  }, [teamId])

  const handleEditSubmit = (field) => {
    Sweetalert.fireConfirm(
      "Validez vous cette mise à jour ?",
      "Attention, mettre à jour une équipe forcera ses utilisateurs à se reconnecter à l'application.",
      () => {
        let teamCopy = { ...team }
        let establishmentTypeId = null
        switch (field) {
          case "name":
            teamCopy.name = newValue
            break

          case "role":
            teamCopy.role = userContext.roles.find((role) => role.id === parseInt(newValue))
            if (teamCopy.role.id !== 4) teamCopy.establishmentType = null
            break

          case "establishmentType":
            teamCopy.establishmentType = userContext.establishmentTypes.find(
              (establishmentType) => establishmentType.id === parseInt(newValue)
            )
            break

          default:
            break
        }
        if (teamCopy.establishmentType !== null) establishmentTypeId = teamCopy.establishmentType.id
        API.PUT(
          "/admin/team/" + team.id,
          {
            name: teamCopy.name,
            roleId: teamCopy.role.id,
            establishmentTypeId: establishmentTypeId,
          },
          (response) => {
            if (teamCopy.id === userContext.team.id) {
              Sweetalert.fireSuccessToast("Équipe mise à jour, redirection vers la page de login...")
              setTimeout(() => {
                userContext.signOut()
              }, 2500)
            } else Sweetalert.fireSuccessToast(response.data.message)
            setTeam(teamCopy)
            cancelEdit()
          }
        )
      }
    )
  }

  const cancelEdit = () => {
    setEditName(false)
    setEditRole(false)
    setEditEstablishmentType(false)
  }

  const handleEdit = (field) => {
    cancelEdit()
    switch (field) {
      case "name":
        setNewValue(team.name)
        setEditName(true)
        break

      case "role":
        setNewValue(team.role.id)
        setEditRole(true)
        break

      case "establishmentType":
        if (team.establishmentType) setNewValue(team.establishmentType.id)
        else setNewValue(1)
        setEditEstablishmentType(true)
        break

      default:
        break
    }
  }

  const deleteUser = (userId) => {
    let teamCopy = { ...team }
    let usersCopy = teamCopy.users.filter((user) => user.id !== userId)
    teamCopy.users = usersCopy
    setTeam(teamCopy)
  }

  const updateTeamTotem = (totemId, newDuration) => {
    let teamCopy = { ...team }
    let totemIndex = teamCopy.totems.findIndex((totem) => totem.id === totemId)
    teamCopy.totems[totemIndex].team_totem.duration = newDuration
    setTeam(teamCopy)
  }

  const deleteTotem = (totemId) => {
    let teamCopy = { ...team }
    let totemsCopy = teamCopy.totems.filter((totem) => totem.id !== totemId)
    teamCopy.totems = totemsCopy
    setTeam(teamCopy)
  }

  // Rendu
  return (
    <>
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Détails équipe</h3>
              <div className="card-tools">
                <ButtonRefresh
                  onClick={() => {
                    getTeam(teamId)
                  }}
                />
                <ButtonCollapse />
              </div>
            </div>
            <div className="card-body table-responsive p-0" style={{ display: "block" }}>
              <LoadingCard loaded={loaded}>
                {team && (
                  <table className="table table-striped projects text-nowrap">
                    <thead>
                      <tr>
                        <th>Id</th>
                        <th>Nom</th>
                        <th>Rôle</th>
                        <th>Type d'établissement</th>
                        <th>Créé le</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <span title={team.id}>#</span>
                        </td>
                        <td>
                          {editName ? (
                            <InputEdit
                              value={newValue}
                              pattern={"[a-zA-Záàâäçéèêëíìîïñóòôöúùûüýÿæœ ]{1,255}"}
                              title={"Lettres et espaces entre 1 et 255 caractères"}
                              onChange={(event) => setNewValue(event.target.value)}
                              onSubmit={() => handleEditSubmit("name")}
                              onCancel={cancelEdit}
                            />
                          ) : (
                            <>
                              {team.name}
                              <ButtonEdit onClick={() => handleEdit("name")} />
                            </>
                          )}
                        </td>
                        <td>
                          {editRole ? (
                            <SelectEdit
                              defaultValue={team.role.id}
                              onChange={setNewValue}
                              onSubmit={() => handleEditSubmit("role")}
                              onCancel={cancelEdit}
                              options={userContext.roles}
                              name="roleId"
                            />
                          ) : (
                            <>
                              {team.role.name} <ButtonEdit onClick={() => handleEdit("role")} />
                            </>
                          )}
                        </td>
                        <td>
                          {editEstablishmentType ? (
                            <SelectEdit
                              defaultValue={team.establishmentType ? team.establishmentType.id : 1}
                              onChange={setNewValue}
                              onSubmit={() => handleEditSubmit("establishmentType")}
                              onCancel={cancelEdit}
                              options={userContext.establishmentTypes}
                              name="establishmentTypeId"
                            />
                          ) : (
                            <>
                              {team.establishmentType ? team.establishmentType.name : "-"}{" "}
                              {team.role.id === 4 && <ButtonEdit onClick={() => handleEdit("establishmentType")} />}
                            </>
                          )}
                        </td>
                        <td>{DateUtils.formatDate(team.createdAt)}</td>
                      </tr>
                    </tbody>
                  </table>
                )}
              </LoadingCard>
            </div>
            <div className="card-footer">
              <button type="button" className="btn btn-default float-right" onClick={() => navigate(-1)}>
                <i className="fas fa-arrow-left"></i> Retour
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="card card-outline card-primary">
            <div className="card-header">
              <h3 className="card-title">Utilisateurs de l'équipe</h3>
              <div className="card-tools">
                <ButtonCollapse />
              </div>
            </div>
            <div className="card-body p-0" style={{ display: "block" }}>
              <LoadingCard loaded={loaded}>
                {team && team.users.length > 0 ? (
                  <table className="table table-striped projects">
                    <thead>
                      <tr>
                        <th>Id</th>
                        <th>Nom</th>
                        <th>Mail</th>
                        <th>
                          <Link className="btn btn-info btn-xs mr-2" to={"/utilisateur/gestion/nouveau/" + teamId}>
                            <i className="fas fa-plus"></i>
                          </Link>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {team.users.map((user) => (
                        <TeamManageDetailsUsers user={user} deleteUser={deleteUser} key={user.id} />
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="alert alert-info m-3">
                    Aucun utilisateur dans l'équipe.{" "}
                    <Link to={"/utilisateur/gestion/nouveau/" + teamId}>En ajouter un</Link>
                  </div>
                )}
              </LoadingCard>
            </div>
          </div>
        </div>
        <div className="col">
          {newTotem ? (
            <TeamManageDetailsForm
              teamId={teamId}
              totems={totems}
              teamTotems={team.totems}
              getTeam={getTeam}
              setNewTotem={setNewTotem}
            />
          ) : (
            <div className="card card-outline card-warning">
              <div className="card-header">
                <h3 className="card-title">Totems de l'équipe</h3>
                <div className="card-tools">
                  <ButtonCollapse />
                </div>
              </div>
              <div className="card-body p-0" style={{ display: "block" }}>
                <LoadingCard loaded={loaded}>
                  {team && team.totems.length > 0 ? (
                    <table className="table table-striped projects">
                      <thead>
                        <tr>
                          <th>Id</th>
                          <th>Nom</th>
                          <th>adresse</th>
                          <th>durée pub</th>
                          <th>
                            <button className="btn btn-info btn-xs mr-2" onClick={() => setNewTotem(true)}>
                              <i className="fas fa-plus"></i>
                            </button>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {team.totems.map((totem) => (
                          <TeamManageDetailsTotems
                            totem={totem}
                            teamId={team.id}
                            updateTeamTotem={updateTeamTotem}
                            deleteTotem={deleteTotem}
                            key={totem.id}
                          />
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div className="alert alert-info m-3">
                      Aucun totem associé.{" "}
                      <ButtonLink
                        onClick={() => setNewTotem(true)}
                        style={{ color: "white", textDecoration: "underline" }}
                      >
                        En ajouter un
                      </ButtonLink>
                    </div>
                  )}
                </LoadingCard>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
