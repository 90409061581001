import React from "react"
import { Link } from "react-router-dom"
import API from "../utils/API"
import Date from "../utils/Date"
import Sweetalert from "../utils/Sweetalert"

export default function AdCardItem({ ad, deleteAd }) {
  // State

  // Comportements
  const handleDelete = (adId) => {
    Sweetalert.fireDeleteConfirm(
      "Êtes-vous sûr ?",
      "Supprimer une ressource peut potentiellement l'enlever d'un totem sur lequel elle est diffusée",
      () => {
        API.DELETE("/ad/" + adId, (response) => {
          Sweetalert.fireSuccessToast(response.data.message)
          deleteAd(adId)
        })
      }
    )
  }

  // Rendu
  return (
    <li className="pt-4 pb-4" style={{ height: "200px" }}>
      <Link to={"/ressources/" + ad.id}>
        {ad.adTypeId === 1 && <i className="fas fa-film" style={{ fontSize: "3rem" }}></i>}
        {ad.adTypeId === 2 && <i className="far fa-image" style={{ fontSize: "3rem" }}></i>}
        {ad.adTypeId === 3 && <i className="far fa-file-pdf" style={{ fontSize: "3rem" }}></i>}
        <span className="users-list-name">{ad.name}</span>
        <span className="users-list-date">
          {ad.width > ad.height ? "Paysage" : "Portrait"} : {ad.width}x{ad.height}
        </span>
        {ad.adTypeId === 1 && <span className="users-list-date">Durée : {ad.duration} secondes</span>}
        <span className="users-list-date">Ajouté le {Date.formatDate(ad.createdAt)}</span>
      </Link>
      <button className="btn btn-danger btn-xs" onClick={() => handleDelete(ad.id)}>
        <i className="fas fa-trash"></i> Supprimer
      </button>
    </li>
  )
}
