import React from "react"

export default function ButtonLink({ id, className, onClick, dataWidget, role, style, children }) {
  return (
    <button
      id={id}
      className={className}
      onClick={onClick}
      data-widget={dataWidget}
      role={role}
      style={{ background: "none", border: "none", ...style }}
    >
      {children}
    </button>
  )
}
