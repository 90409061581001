import React, { useMemo, useState } from "react"
import ClubNewsTableItem from "./ClubNewsTableItem"
import InputSearch from "./InputSearch"
import ButtonRefresh from "./ButtonRefresh"
import ButtonCollapse from "./ButtonCollapse"
import LoadingCard from "./LoadingCard"
import { Link } from "react-router-dom"

export default function ClubNewsTable({ news, refreshNews, deleteNews, loaded }) {
  // State
  const [search, setSearch] = useState("")

  // Comportement
  const newsFiltered = useMemo(() => {
    if (news) {
      return news.filter(
        (item) => item.ad.name.toLowerCase().includes(search) || item.title.toLowerCase().includes(search)
      )
    } else return []
  }, [news, search])

  // Rendu
  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Actualités</h3>
        <div className="card-tools">
          <InputSearch value={search} onChange={(event) => setSearch(event.target.value.toLowerCase())} />
          <ButtonRefresh onClick={refreshNews} />
          <ButtonCollapse />
        </div>
      </div>
      <div className="card-body table-responsive p-0" style={{ display: "block" }}>
        <LoadingCard loaded={loaded}>
          {news.length > 0 ? (
            <table className="table table-striped projects text-nowrap">
              <thead>
                <tr>
                  <th>Titre</th>
                  <th>Ressource affichée</th>
                  <th>Écrans</th>
                  <th>Date de création</th>
                  <th className="text-right" style={{ width: "14%" }}>
                    <Link to={"./nouveau"} className="btn btn-info btn-xs mr-2">
                      <i className="fas fa-plus"></i>
                      <span className="ml-1">Ajouter</span>
                    </Link>
                  </th>
                </tr>
              </thead>
              <tbody>
                {newsFiltered.map((item) => (
                  <ClubNewsTableItem item={item} deleteNews={deleteNews} key={item.id} />
                ))}
              </tbody>
            </table>
          ) : (
            <div className="alert alert-info m-3">
              Aucune actualité trouvée. <Link to={"./nouveau"}>En ajouter</Link>
            </div>
          )}
        </LoadingCard>
      </div>
    </div>
  )
}
