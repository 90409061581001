import React, { useState, useMemo } from "react"
import LoadingCard from "./LoadingCard"
import { Link } from "react-router-dom"

export default function TotemManageDetailsPlaylists({ totem, loaded }) {
  // State
  const [playLists, setPlayLists] = useState([[], []]) // ePlayList, aPlayList

  // Comportements
  useMemo(() => {
    if (totem) {
      setPlayLists(JSON.parse(totem.playLists))
    } else setPlayLists([[], []])
  }, [totem])

  // Rendu
  return (
    <LoadingCard loaded={loaded}>
      {playLists[0].length > 0 || playLists[1].length > 0 ? (
        <>
          <h5>Playlist Établissement</h5>
          {playLists[0].length > 0 ? (
            playLists[0].map((loop, i) => (
              <div key={`e-loop-${i}`}>
                <h6>Boucle {i + 1}</h6>
                {totem.version !== "0.2.6" &&
                  loop.map((item, j) => (
                    <div
                      key={`e-item-${i}-${j}`}
                      className="alert alert-success py-1 px-3"
                      style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                    >
                      {item.adTypeId === 1 && <i className="fas fa-film"></i>}
                      {item.adTypeId === 2 && <i className="far fa-image"></i>}
                      {item.adTypeId === 3 && <i className="far fa-file-pdf"></i>}{" "}
                      <Link to={"/ressource/gestion/" + item.id}>{item.name}</Link>
                    </div>
                  ))}
              </div>
            ))
          ) : (
            <div className="alert alert-info py-1 px-3">Aucune playlist Établissement.</div>
          )}
          <h5>Playlist Annonceur</h5>
          {playLists[1].length > 0 ? (
            playLists[1].map((loop, i) => (
              <div key={`a-loop-${i}`}>
                <h6>Boucle {i + 1}</h6>
                {totem.version !== "0.2.6" &&
                  loop.map((item, j) => (
                    <div
                      key={`a-item-${i}-${j}`}
                      className="alert alert-warning py-1 px-3"
                      style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                    >
                      {item.adTypeId === 1 && <i className="fas fa-film"></i>}
                      {item.adTypeId === 2 && <i className="far fa-image"></i>}
                      {item.adTypeId === 3 && <i className="far fa-file-pdf"></i>}{" "}
                      <Link to={"/ressource/gestion/" + item.id} style={{ color: "black" }}>
                        {item.name}
                      </Link>
                    </div>
                  ))}
              </div>
            ))
          ) : (
            <div className="alert alert-info py-1 px-3">Aucune playlist Annonceur.</div>
          )}
          <span>
            <i>
              Note : Ces playlists sont remontées par le player, il est possible qu'elles ne soient pas à jour si le
              player est hors ligne !
            </i>
          </span>
        </>
      ) : (
        <div className="alert alert-info m-0">Aucune playlist trouvée.</div>
      )}
    </LoadingCard>
  )
}
