import React, { useState, useMemo } from "react"
import API from "../utils/API"
import Sweetalert from "../utils/Sweetalert"
import ButtonCollapse from "./ButtonCollapse"
import ButtonLink from "./ButtonLink"
import LoadingCard from "./LoadingCard"

export default function TotemManageDetailsPlaylistConf({ totem, loaded, updatePlayList }) {
  // State
  const [playListConfig, setPlayListConfig] = useState([])
  const [editPlaylistConfig, setEditPlaylistConfig] = useState(false)
  const [currentId, setCurrentId] = useState(0)

  // Comportements
  useMemo(() => {
    if (totem) {
      setPlayListConfig(JSON.parse(totem.playListConfig))
    } else setPlayListConfig([])
  }, [totem])

  useMemo(() => {
    let id = 0
    playListConfig.forEach((item) => {
      if (item.id > id) id = item.id
    })
    setCurrentId(id)
  }, [playListConfig])

  const addItem = (itemName) => {
    let id = currentId + 1
    let playListConfigCopy = [...playListConfig]
    playListConfigCopy.push({ id: id, name: itemName })
    setCurrentId(id)
    setPlayListConfig(playListConfigCopy)
  }

  const removeItem = (id) => {
    let playListConfigCopy = playListConfig.filter((item) => item.id !== id)
    setPlayListConfig(playListConfigCopy)
  }

  const handlePlayListConfigSubmit = () => {
    Sweetalert.fireConfirm(
      "Validez vous ces données ?",
      "Une fois validé, le Totem génèrera une nouvelle playlist",
      () => {
        let pLConfigStr = JSON.stringify(playListConfig)
        API.PUT(
          "/admin/totem/" + totem.id,
          {
            playListConfig: pLConfigStr,
          },
          (response) => {
            Sweetalert.fireSuccessToast(response.data.message)
            updatePlayList(pLConfigStr)
            cancelEdit()
          }
        )
      }
    )
  }

  const cancelEdit = () => {
    if (totem) {
      setPlayListConfig(JSON.parse(totem.playListConfig))
    } else setPlayListConfig([])
    setEditPlaylistConfig(false)
  }

  // Rendu
  return (
    <div className="card card-outline card-warning">
      <div className="card-header">
        <h3 className="card-title">Paramètres de la playlist</h3>
        <div className="card-tools">
          <ButtonCollapse />
        </div>
      </div>
      <div className="card-body p-0" style={{ display: "block" }}>
        <LoadingCard loaded={loaded}>
          {playListConfig.length > 0 ? (
            playListConfig.map((item, i) => (
              <div
                className={`${i === 0 ? "mt-3" : "mt-0"} ml-3 mr-3 ${
                  i + 1 === playListConfig.length ? "mb-3" : "mb-0"
                }`}
                key={item.id}
              >
                <div
                  className={item.name === "A" ? "info-box mb-0 bg-warning" : "info-box mb-0 bg-success"}
                  style={{ minHeight: "30px", height: "35px" }}
                >
                  <div className="info-box-content">
                    <div className="d-flex justify-content-between">
                      <span>
                        <i className="fas fa-broadcast-tower"></i> {item.name === "A" ? "Annonceur" : "Établissement"}
                      </span>
                      {editPlaylistConfig && (
                        <span>
                          <ButtonLink onClick={() => removeItem(item.id)}>
                            <i className="fas fa-times"></i>
                          </ButtonLink>
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                {i + 1 !== playListConfig.length && (
                  <div className="d-flex justify-content-center">
                    <span>
                      <i className="fas fa-arrow-down"></i>
                    </span>
                  </div>
                )}
              </div>
            ))
          ) : (
            <div className="alert alert-info m-3">Aucune configuration trouvée.</div>
          )}
          {editPlaylistConfig && (
            <div className="m-3">
              <button type="button" className="btn btn-success" onClick={() => addItem("E")}>
                <i className="fas fa-plus"></i> Établissement
              </button>
              <button type="button" className="btn btn-warning float-right" onClick={() => addItem("A")}>
                <i className="fas fa-plus"></i> Annonceur
              </button>
            </div>
          )}
        </LoadingCard>
      </div>
      <div className="card-footer">
        {editPlaylistConfig ? (
          <>
            <button type="button" className="btn btn-primary" onClick={() => handlePlayListConfigSubmit()}>
              Valider
            </button>
            <button type="button" className="btn btn-default float-right" onClick={() => cancelEdit()}>
              Annuler
            </button>
          </>
        ) : (
          <button type="button" className="btn btn-primary" onClick={() => setEditPlaylistConfig(true)}>
            <i className="far fa-edit"></i> Éditer
          </button>
        )}
      </div>
    </div>
  )
}
