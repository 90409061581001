import React, { useCallback, useMemo, useState } from "react"
import { useDropzone } from "react-dropzone"
import ButtonCollapse from "./ButtonCollapse"
import FormData from "form-data"
import API from "../utils/API"
import Sweetalert from "../utils/Sweetalert"

export default function AdDropzone({ refreshAds, currentAdPath }) {
  // State
  const [loading, setLoading] = useState(false)

  // Comportements
  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        setLoading(true)
        let data = new FormData()
        acceptedFiles.forEach((file, i) => {
          data.append(`file-${i}`, file)
        })
        data.append("adPathId", currentAdPath)

        API.POST(
          "/upload/ad",
          data,
          (response) => {
            if (response.status === 206) Sweetalert.fireWarningToast(response.data.message)
            else Sweetalert.fireSuccessToast(response.data.message)
            setLoading(false)
            setTimeout(() => {
              refreshAds()
            }, 500)
          },
          (error) => {
            Sweetalert.fireErrorToast(error)
            setLoading(false)
          }
        )
      } else Sweetalert.fireWarningToast("Le fichier ne correspond pas aux critères")
    },
    [refreshAds, currentAdPath]
  )

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: {
      "video/avi": [".avi"],
      "video/mp4": [".mp4"],
      "video/mpeg": [".mpeg"],
      "video/webm": [".webm"],
      "image/jpeg": [".jpg", ".jpeg"],
      "image/png": [".png"],
      "application/pdf": [".pdf"],
    },
    maxSize: /* 20MB */ 20 * 1024 * 1024,
    onDrop,
    disabled: loading,
  })

  const style = useMemo(
    () => ({
      ...{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px",
        borderWidth: 2,
        borderRadius: 2,
        borderColor: "#eeeeee",
        borderStyle: "dashed",
        backgroundColor: "#fafafa",
        color: "#bdbdbd",
        outline: "none",
        transition: "border .24s ease-in-out",
      },
      ...(isFocused
        ? {
            borderColor: "#2196f3",
          }
        : {}),
      ...(isDragAccept
        ? {
            borderColor: "#00e676",
          }
        : {}),
      ...(isDragReject
        ? {
            borderColor: "#ff1744",
          }
        : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  )

  // Rendu
  return (
    <div className="card card-outline card-primary">
      <div className="card-header">
        <h3 className="card-title">Dépôt de ressources</h3>
        <div className="card-tools">
          <ButtonCollapse />
        </div>
      </div>
      <div className="card-body">
        <div>
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            {loading ? (
              <i className="fas fa-2x fa-sync-alt fa-spin"></i>
            ) : (
              <>
                <p>Déposez vos ressources dans cette zone ou cliquez dessus pour sélectionner vos fichiers</p>
                <em>Formats acceptés : *.avi ; *.mp4 ; *.mpeg ; *.webm ; *.png ; *.jpg ; *.jpeg ; *.pdf</em>
                <em>Taille maximum 20Mo, vidéo de maximum 10 secondes</em>
                <em>Deux ressources de même type (video, image...) ne doivent pas porter le même nom !</em>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
