import React, { useMemo, useState } from "react"
import ContentWrapper from "../components/ContentWrapper"
import ContentHeader from "../components/ContentHeader"
import ContentMain from "../components/ContentMain"
import { Link, useParams } from "react-router-dom"
import ButtonCollapse from "../components/ButtonCollapse"
import LoadingCard from "../components/LoadingCard"
import API from "../utils/API"
import Select from "../components/Select"
import AdManageTable from "../components/AdManageTable"
import AdPlayerCard from "../components/AdPlayerCard"
import AdManageTotemTable from "../components/AdManageTotemTable"

export default function MainAdManage() {
  // State
  let { adId } = useParams()
  const [ads, setAds] = useState([])
  const [teams, setTeams] = useState([])
  const [totems, setTotems] = useState([])
  const [adNameFilter, setAdNameFilter] = useState("")
  const [typeFilter, setTypeFilter] = useState(0)
  const [teamFilter, setTeamFilter] = useState(0)
  const [broadcastFilter, setBroadcastFilter] = useState(0)
  const [broadcastTotemFilter, setBroadcastTotemFilter] = useState("")
  const [adsLoaded, setAdsLoaded] = useState(false)
  const [teamsLoaded, setTeamsLoaded] = useState(false)
  const [totemsLoaded, setTotemsLoaded] = useState(false)
  const broadcastOptions = [
    { id: 0, name: "Toutes" },
    { id: 1, name: "Uniquement non diffusées" },
    { id: 2, name: "Uniquement diffusées" },
  ]
  const adTypes = [
    { id: 0, name: "Tous" },
    { id: 1, name: "Vidéo" },
    { id: 2, name: "Image" },
    { id: 3, name: "PDF" },
  ]

  // Comportements
  const getAds = () => {
    API.GET("/admin/ad", (response) => {
      setAds(response.data)
      setAdsLoaded(true)
    })
  }

  const getTeams = () => {
    API.GET("/admin/team", (response) => {
      setTeams(response.data)
      setTeamsLoaded(true)
    })
  }

  const getTotems = () => {
    API.GET("/admin/totem", (response) => {
      setTotems(response.data)
      setTotemsLoaded(true)
    })
  }

  useMemo(() => {
    getAds()
    getTeams()
    getTotems()
  }, [])

  const deleteAd = (id) => {
    let adsCopy = ads.filter((ad) => ad.id !== id)
    setAds(adsCopy)
  }

  const deleteTotemFromAd = (adId, totemId) => {
    let adsCopy = [...ads]
    let adIndex = adsCopy.findIndex((ad) => ad.id === adId)
    let adTotemsCopy = adsCopy[adIndex].totems.filter((totem) => totem.id !== totemId)
    adsCopy[adIndex].totems = adTotemsCopy
    setAds(adsCopy)
  }

  // Rendu
  return (
    <ContentWrapper>
      <ContentHeader>
        <div className="col-sm-6">
          <h1 className="m-0">Gestion Ressources</h1>
        </div>
        <div className="col-sm-6">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item">
              <Link to={"/"}>Accueil</Link>
            </li>
            <li className="breadcrumb-item active">Ressource</li>
          </ol>
        </div>
      </ContentHeader>
      <ContentMain>
        {adId ? (
          <div className="row">
            <div className="col-lg-8">
              <AdPlayerCard ads={ads} adId={adId} deleteAd={deleteAd} loaded={adsLoaded} />
            </div>
            <div className="col-lg-4">
              <AdManageTotemTable ads={ads} adId={adId} deleteTotemFromAd={deleteTotemFromAd} loaded={adsLoaded} />
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col">
              <div className="card card-outline card-secondary">
                <div className="card-header">
                  <h3 className="card-title">Filtres</h3>
                  <div className="card-tools">
                    <ButtonCollapse />
                  </div>
                </div>
                <div className="card-body" style={{ display: "block" }}>
                  <LoadingCard loaded={teamsLoaded && totemsLoaded}>
                    <div className="row">
                      <div className="col-lg-3">
                        <div className="form-group">
                          <label>Nom de la ressource</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Nom"
                            value={adNameFilter}
                            onChange={(event) => {
                              setAdNameFilter(event.target.value)
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="form-group">
                          <label>Type de ressource</label>
                          <Select onChange={setTypeFilter} options={adTypes} defaultValue={0} name="adTypeFilter" />
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="form-group">
                          <label>Équipe</label>
                          <Select
                            onChange={setTeamFilter}
                            options={[{ id: 0, name: "Toutes" }, ...teams]}
                            defaultValue={0}
                            name="teamFilter"
                          />
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="form-group">
                          <label>Ressource diffusée</label>
                          <Select
                            onChange={setBroadcastFilter}
                            options={broadcastOptions}
                            defaultValue={0}
                            name="broadcastFilter"
                          />
                        </div>
                        {broadcastFilter === 2 && (
                          <div className="form-group">
                            <label>Diffusée sur :</label>
                            <Select
                              onChange={setBroadcastTotemFilter}
                              options={[{ id: "", name: "Tous les totems" }, ...totems]}
                              defaultValue={""}
                              name="broadcastTotemFilter"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </LoadingCard>
                </div>
              </div>
              <AdManageTable
                ads={ads}
                getAds={getAds}
                deleteAd={deleteAd}
                loaded={adsLoaded}
                adNameFilter={adNameFilter}
                typeFilter={typeFilter}
                teamFilter={teamFilter}
                broadcastFilter={broadcastFilter}
                broadcastTotemFilter={broadcastTotemFilter}
              />
            </div>
          </div>
        )}
      </ContentMain>
    </ContentWrapper>
  )
}
