import React from "react"
import ReactLoading from "react-loading"
import API from "./API"

export default class LoadingScreen extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      done: undefined,
    }
  }

  componentDidMount() {
    setTimeout(() => {
      API.CUSTOM(
        "get",
        "/jwtcheck",
        null,
        (response) => {
          this.props.signIn(response.data, () => this.setState({ done: true }))
        },
        () => {
          this.setState({ done: true })
        }
      )
    }, 1000)
  }

  render() {
    return (
      <>
        {!this.state.done ? (
          <div
            style={{
              width: "100%",
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#282c34",
            }}
          >
            <ReactLoading type={"bars"} color={"white"} height={"200px"} width={"200px"} className="loading-screen" />
          </div>
        ) : (
          this.props.children
        )}
      </>
    )
  }
}
