import React, { useMemo, useState } from "react"
import ContentWrapper from "../components/ContentWrapper"
import ContentHeader from "../components/ContentHeader"
import ContentMain from "../components/ContentMain"
import { Link, useParams } from "react-router-dom"
import API from "../utils/API"
import ClubActivityTable from "../components/ClubActivityTable"
import ClubActivityForm from "../components/ClubActivityForm"
import ClubActivityDetails from "../components/ClubActivityDetails"

export default function MainClubAnimation({ addAnimation }) {
  // State
  let { animationId } = useParams()
  const [animations, setAnimations] = useState([])
  const [loaded, setLoaded] = useState(false)

  // Comportement
  const getAnimations = () => {
    API.GET("/club/animation", (response) => {
      setAnimations(response.data)
      setLoaded(true)
    })
  }

  useMemo(() => {
    getAnimations()
  }, [])

  const deleteAnimation = (id) => {
    let animationsCopy = animations.filter((animation) => animation.id !== id)
    setAnimations(animationsCopy)
  }

  // Rendu
  return (
    <ContentWrapper>
      <ContentHeader>
        <div className="col-sm-6">
          <h1 className="m-0">Animations du club</h1>
        </div>
        <div className="col-sm-6">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item">
              <Link to={"/"}>Accueil</Link>
            </li>
            {addAnimation ? (
              <>
                <li className="breadcrumb-item">
                  <Link to={"/club/animations"}>Animations du club</Link>
                </li>
                <li className="breadcrumb-item active">Nouveau</li>
              </>
            ) : (
              <li className="breadcrumb-item active">Animations du club</li>
            )}
          </ol>
        </div>
      </ContentHeader>
      <ContentMain>
        {addAnimation ? (
          <ClubActivityForm
            cardTitle="Création d'une nouvelle animation"
            activityType="animation"
            refreshActivities={getAnimations}
          />
        ) : animationId ? (
          <ClubActivityDetails
            cardTitle="Détails de l'animation"
            activities={animations}
            activityId={animationId}
            refreshActivities={getAnimations}
          />
        ) : (
          <ClubActivityTable
            title="Liste des animations"
            activities={animations}
            refreshActivities={getAnimations}
            deleteActivity={deleteAnimation}
            loaded={loaded}
          />
        )}
      </ContentMain>
    </ContentWrapper>
  )
}
