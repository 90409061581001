import React from "react"
import Footer from "../components/Footer"
import Navbar from "../components/Navbar"
import Sidebar from "../components/Sidebar"
import MainRouter from "./MainRouter"

export default function Main() {
  // State

  // Comportements

  // Render
  return (
    <div className="wrapper">
      <Navbar />
      <Sidebar />
      <MainRouter />
      <Footer />
      <div id="sidebar-overlay"></div>
    </div>
  )
}
