import React from "react"
import Sweetalert from "../utils/Sweetalert"
import API from "../utils/API"
import { Link } from "react-router-dom"

export default function AdManageTableItem({ ad, deleteAd }) {
  // State

  // Comportements
  const handleDelete = (id) => {
    Sweetalert.fireDeleteConfirm(
      "Êtes-vous sûr ?",
      "Supprimer une ressource de cette liste peut la retirer d'une playlist d'un des totem.",
      () => {
        API.DELETE("/admin/ad/" + id, (response) => {
          Sweetalert.fireSuccessToast(response.data.message)
          deleteAd(id)
        })
      }
    )
  }

  // Rendu
  return (
    <tr>
      <td>
        <span title={ad.id}>#</span>
      </td>
      <td>{ad.adType.name}</td>
      <td>{ad.name}</td>
      <td>{ad.team.name}</td>
      <td>{ad.diffusion} totem(s)</td>
      <td className="project-actions text-right">
        <Link to={"/ressource/gestion/" + ad.id} className="btn btn-primary btn-xs mr-2">
          <i className="far fa-eye"></i>
          <span className="ml-1">Visualiser</span>
        </Link>
        <button className="btn btn-danger btn-xs" onClick={() => handleDelete(ad.id)}>
          <i className="fas fa-trash"></i>
          <span className="ml-1">Supprimer</span>
        </button>
      </td>
    </tr>
  )
}
