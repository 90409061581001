import { useState } from "react"
import { useNavigate } from "react-router-dom"
import API from "../utils/API"

function Login({ signIn }) {
  // State
  const [mail, setMail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")
  const navigate = useNavigate()

  // Comportements
  const handleSubmit = (event) => {
    event.preventDefault()
    setError("")

    API.POST(
      "/login",
      {
        mail,
        password,
      },
      (response) => {
        signIn(
          {
            id: response.data.id,
            mail: response.data.mail,
            name: response.data.name,
            team: response.data.team,
            role: response.data.role,
            establishmentType: response.data.establishmentType,
          },
          () => navigate("/")
        )
      },
      setError
    )
  }

  // Render
  return (
    <div className="hold-transition login-page">
      <div className="login-box">
        <div className="login-logo">
          <img src="/dist/img/GuilleminAffichageLogo.png" alt="Guillemin Affichage" width="100%" />
        </div>
        <div className="card">
          <div className="card-body login-card-body">
            <p className="login-box-msg">Connectez-vous pour accéder à votre espace de gestion</p>
            <form action="#" onSubmit={handleSubmit}>
              <div className="input-group mb-3">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  value={mail}
                  onChange={(event) => {
                    setMail(event.target.value)
                  }}
                  required
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-envelope" />
                  </div>
                </div>
              </div>
              <div className="input-group mb-3">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Mot de passe"
                  value={password}
                  onChange={(event) => {
                    setPassword(event.target.value)
                  }}
                  required
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock" />
                  </div>
                </div>
              </div>
              <div className="row">
                <p className="text-danger">{error}</p>
              </div>
              <div className="row">
                <div className="col-7">{/* <a href="/forgotpassword">Mot de passe oublié ?</a> */}</div>
                <div className="col-5">
                  <button type="submit" className="btn btn-primary btn-block">
                    Connexion
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
