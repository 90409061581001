import React from "react"
import ReactSelect from "react-select"

export default function Select({ onChange, options, defaultValue, isDisabled, isMulti, name }) {
  return (
    <ReactSelect
      onChange={(option) => {
        if (isMulti) onChange(option)
        else onChange(option.id)
      }}
      placeholder={"Sélectionner..."}
      menuPortalTarget={document.querySelector("body")}
      defaultValue={isMulti ? defaultValue : options.find((option) => option.id === defaultValue)}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      noOptionsMessage={() => "Aucune option disponible"}
      options={options}
      isDisabled={isDisabled}
      isMulti={isMulti}
      name={name}
      required={true}
    />
  )
}
