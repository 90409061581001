import React, { useState } from "react"
import API from "../utils/API"
import truncate from "../utils/Truncate"
import Sweetalert from "../utils/Sweetalert"
import ButtonEdit from "./ButtonEdit"
import InputEdit from "./InputEdit"

export default function DiffusionTotemCardAd({ ad, maxDuration, deselectAd, updateAd }) {
  // State
  const [newValue, setNewValue] = useState(null)
  const [editGranularity, setEditGranularity] = useState(false)
  const [editDuration, setEditDuration] = useState(false)

  // Comportements
  const cancelEdit = () => {
    setEditGranularity(false)
    setEditDuration(false)
  }

  const handleEdit = (field) => {
    if (field === "duration") {
      cancelEdit()
      setNewValue(ad.totem_ad.notVideoDuration)
      setEditDuration(true)
    } else {
      cancelEdit()
      setNewValue(ad.totem_ad.granularity)
      setEditGranularity(true)
    }
  }

  const handleEditSubmit = (field) => {
    let //oldDuration = ad.totem_ad.notVideoDuration,
      newNVDuration = ad.totem_ad.notVideoDuration
    let //oldGranularity = ad.totem_ad.granularity,
      newGranularity = ad.totem_ad.granularity
    //let oldIsOneOnTwo = ad.totem_ad.isOneOnTwo,
    //  newIsOneOnTwo = ad.totem_ad.isOneOnTwo
    if (field === "duration") {
      newNVDuration = newValue
    } /*if (field === "granularity")*/ else {
      newGranularity = newValue
    } //else {
    //newIsOneOnTwo = !ad.totem_ad.isOneOnTwo
    //newGranularity = 1
    //}
    //let deltaDuration =
    //  (ad.duration + newNVDuration) * newGranularity * (newIsOneOnTwo ? 0.5 : 1) -
    //  (ad.duration + oldDuration) * oldGranularity * (oldIsOneOnTwo ? 0.5 : 1)
    if (ad.duration + newNVDuration > maxDuration) {
      Sweetalert.fireWarningToast(
        "Vous dépassez la limite de " + maxDuration + "s de diffusion par boucle autorisée pour ce totem"
      )
      if (field !== "isOneOnTwo") cancelEdit()
    } else {
      API.PUT(
        "/totem/" + ad.totem_ad.totemId + "/ad/" + ad.id,
        {
          granularity: newGranularity,
          notVideoDuration: newNVDuration,
          //isOneOnTwo: newIsOneOnTwo,
        },
        (response) => {
          Sweetalert.fireSuccessToast(response.data.message)
          updateAd(ad.id, newGranularity, parseInt(newNVDuration))
          cancelEdit()
        }
      )
    }
  }

  // Rendu
  return (
    <tr>
      <td>{truncate(ad.name, 30)}</td>
      <td>
        {editDuration ? (
          <InputEdit
            type="number"
            min="1"
            value={newValue}
            onChange={(event) => setNewValue(event.target.value)}
            onSubmit={() => handleEditSubmit("duration")}
            onCancel={() => setEditDuration(false)}
          />
        ) : (
          <>
            {ad.duration + ad.totem_ad.notVideoDuration} s{" "}
            {ad.totem_ad.notVideoDuration > 0 && <ButtonEdit onClick={() => handleEdit("duration")} />}
          </>
        )}
      </td>
      <td>
        {editGranularity ? (
          <InputEdit
            type="number"
            min="1"
            value={newValue}
            onChange={(event) => setNewValue(event.target.value)}
            onSubmit={() => handleEditSubmit("granularity")}
            onCancel={() => setEditGranularity(false)}
          />
        ) : (
          <>
            {ad.totem_ad.granularity} x
            <ButtonEdit onClick={() => handleEdit("granularity")} />
          </>
        )}
      </td>
      <td className="project-actions text-right">
        <button className="btn btn-danger btn-xs" onClick={() => deselectAd(ad.id)}>
          <i className="fas fa-trash"></i>
        </button>
      </td>
    </tr>
  )
}
