import React from "react"

export default function InputEdit({ type = "text", value, min, max, pattern, title, onChange, onSubmit, onCancel }) {
  const handleSubmit = (event) => {
    event.preventDefault()
    onSubmit()
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="input-group input-group-sm float-left" style={{ width: "100%" }}>
        <input
          type={type}
          className="form-control"
          value={value}
          min={min}
          max={max}
          onChange={onChange}
          pattern={pattern}
          title={title}
          required
        />
        <div className="input-group-append">
          <button className="input-group-text" type="submit" style={{ backgroundColor: "#3085d6" }}>
            <i className="fas fa-check" style={{ color: "white" }}></i>
          </button>
        </div>
        <div className="input-group-append">
          <button className="input-group-text" onClick={onCancel} style={{ backgroundColor: "#d33" }}>
            <i className="fas fa-times" style={{ width: "14px", color: "white" }}></i>
          </button>
        </div>
      </div>
    </form>
  )
}
