import React, { useMemo, useState } from "react"
import { Link } from "react-router-dom"
import ContentHeader from "../components/ContentHeader"
import ContentMain from "../components/ContentMain"
import ContentWrapper from "../components/ContentWrapper"
import DiffusionTotemCard from "../components/DiffusionTotemCard"
import DiffusionAdCard from "../components/DiffusionAdCard"
import API from "../utils/API"

export default function MainDiffusion() {
  // State
  const [ads, setAds] = useState([])
  const [adsLoaded, setAdsLoaded] = useState(false)
  const [totems, setTotems] = useState([])
  const [totemsLoaded, setTotemsLoaded] = useState(false)
  const [totemSelected, setTotemSelected] = useState(null)

  // Comportements
  const getAds = () => {
    API.GET("/ad", (response) => {
      setAds(response.data)
      setAdsLoaded(true)
    })
  }

  const getTotems = () => {
    API.GET("/totem", (response) => {
      setTotems(response.data)
      setTotemsLoaded(true)
    })
  }

  const refreshTotemsAds = () => {
    setAdsLoaded(false)
    setTotemsLoaded(false)
    getAds()
    getTotems()
  }

  useMemo(() => {
    getAds()
    getTotems()
  }, [])

  const addAdToSelectedTotem = (ad, nVDuration) => {
    let totemSelectedCopy = { ...totemSelected }
    totemSelectedCopy.ads.push({
      totem_ad: { totemId: totemSelected.id, granularity: 1, notVideoDuration: nVDuration },
      ...ad,
    })
    setTotemSelected(totemSelectedCopy)
  }

  const updateAd = (adId, granularity, nVDuration, isOneOnTwo) => {
    let totemSelectedCopy = { ...totemSelected }
    let adIndex = totemSelectedCopy.ads.findIndex((ad) => ad.id === adId)
    totemSelectedCopy.ads[adIndex].totem_ad.granularity = granularity
    totemSelectedCopy.ads[adIndex].totem_ad.notVideoDuration = nVDuration
    totemSelectedCopy.ads[adIndex].totem_ad.isOneOnTwo = isOneOnTwo
    setTotemSelected(totemSelectedCopy)
  }

  const deleteAdFromSelectedTotem = (adId) => {
    let totemSelectedCopy = { ...totemSelected }
    let adsCopy = totemSelectedCopy.ads.filter((v) => v.id !== adId)
    totemSelectedCopy.ads = adsCopy
    setTotemSelected(totemSelectedCopy)
  }

  const validateSelectedTotem = () => {
    let totemsCopy = [...totems]
    let selectedTotemIndex = totemsCopy.findIndex((totem) => totem.id === totemSelected.id)
    totemsCopy[selectedTotemIndex] = { ...totemSelected }
    setTotems(totemsCopy)
    setTotemSelected(null)
  }

  // Rendu
  return (
    <ContentWrapper>
      <ContentHeader>
        <div className="col-sm-6">
          <h1 className="m-0">Diffusion</h1>
        </div>
        <div className="col-sm-6">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item">
              <Link to={"/"}>Accueil</Link>
            </li>
            <li className="breadcrumb-item active">Diffusion</li>
          </ol>
        </div>
      </ContentHeader>
      <ContentMain>
        <div className="row">
          <div className="col-xl-4">
            <DiffusionAdCard
              ads={ads}
              loaded={adsLoaded}
              totemSelected={totemSelected}
              addAdToSelectedTotem={addAdToSelectedTotem}
            />
          </div>
          <div className="col-xl-8">
            <DiffusionTotemCard
              totems={totems}
              loaded={totemsLoaded}
              totemSelected={totemSelected}
              selectTotem={setTotemSelected}
              refreshTotemsAds={refreshTotemsAds}
              validateSelectedTotem={validateSelectedTotem}
              updateAd={updateAd}
              deleteAdFromSelectedTotem={deleteAdFromSelectedTotem}
            />
          </div>
        </div>
      </ContentMain>
    </ContentWrapper>
  )
}
