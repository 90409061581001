import React, { useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import Select from "./Select"
import API from "../utils/API"
import Sweetalert from "../utils/Sweetalert"
import LoadingCard from "./LoadingCard"
import AdPreviewCard from "./AdPreviewCard"

export default function ClubActivityForm({ cardTitle, activityType, refreshActivities }) {
  // State
  const navigate = useNavigate()
  const [totems, setTotems] = useState([])
  const [ads, setAds] = useState([])
  const [totemsLoaded, setTotemsLoaded] = useState(false)
  const [adsLoaded, setAdsLoaded] = useState(false)
  const [adSelected, setAdSelected] = useState("")
  const [date, setDate] = useState("")
  const [regOnTotem, setRegOnTotem] = useState(false)
  const [emailNotif, setEmailNotif] = useState(false)
  const [email, setEmail] = useState("")
  const [maxParticipant, setMaxParticipant] = useState("")
  const [maxWaitingList, setMaxWaitingList] = useState("")
  const [isDuo, setIsDuo] = useState(false)
  const [isLevelRequired, setIsLevelRequired] = useState(false)
  const [url, setUrl] = useState("")
  const [totemsSelected, setTotemsSelected] = useState([])
  const [error, setError] = useState("")

  // Comportement
  const getTotems = () => {
    API.GET("/club/totem", (response) => {
      setTotems(response.data)
      setTotemsLoaded(true)
    })
  }

  const getAds = () => {
    API.GET("/ad", (response) => {
      setAds(response.data)
      setAdsLoaded(true)
    })
  }

  useMemo(() => {
    getTotems()
    getAds()
  }, [])

  const adsAvailable = useMemo(() => {
    return ads.filter((ad) => ad.adTypeId === 2 || ad.adTypeId === 3) // Filtre sur les Images & PDF
  }, [ads])

  const handleSubmit = (event) => {
    event.preventDefault()
    setError("")

    Sweetalert.fireConfirm(
      "Validez vous ces données ?",
      "Une fois créé, l'activité sera visible sur les écrans associés",
      () => {
        API.POST(
          "/club/" + activityType,
          {
            adId: adSelected,
            date,
            email: regOnTotem && emailNotif && email ? email : null,
            maxParticipant: regOnTotem && maxParticipant ? maxParticipant : null,
            maxWaitingList: regOnTotem && maxWaitingList ? maxWaitingList : null,
            isDuo,
            isLevelRequired,
            registerUrl: !regOnTotem && url ? url : null,
            totemsSelected,
          },
          (response) => {
            Sweetalert.fireSuccessToast(response.data.message)
            refreshActivities()
            navigate(-1)
          },
          setError
        )
      }
    )
  }

  // Rendu
  return (
    <>
      <div className="card card-primary">
        <div className="card-header">
          <h3 className="card-title">{cardTitle}</h3>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="card-body">
            <LoadingCard loaded={totemsLoaded && adsLoaded}>
              <div className="form-group">
                <label>Ressource à afficher</label>
                <Select onChange={setAdSelected} options={adsAvailable} name="adId" />
              </div>
              <div className="form-group">
                <label>Date de limite d'inscription</label>
                <input
                  type="datetime-local"
                  className="form-control"
                  value={date}
                  required
                  onChange={(event) => {
                    setDate(event.target.value)
                  }}
                />
              </div>
              <div className="form-group">
                <label>Inscription sur l'écran interactif</label>
                <div className="form-check">
                  <label className="form-check-label" style={{ cursor: "pointer" }}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={regOnTotem}
                      onChange={() => setRegOnTotem(!regOnTotem)}
                    />
                    Activé
                  </label>
                </div>
              </div>
              {regOnTotem ? (
                <>
                  <div className="form-group">
                    <label>Notification par email lors d'une inscription</label>
                    <div className="form-check">
                      <label className="form-check-label" style={{ cursor: "pointer" }}>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={emailNotif}
                          onChange={() => setEmailNotif(!emailNotif)}
                        />
                        Activé
                      </label>
                    </div>
                  </div>
                  {emailNotif && (
                    <div className="form-group">
                      <label>Adresse Email</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        value={email}
                        required
                        onChange={(event) => {
                          setEmail(event.target.value)
                        }}
                      />
                    </div>
                  )}
                  <div className="form-group">
                    <label>Nombre de participants</label>
                    <input
                      type="number"
                      className="form-control"
                      value={maxParticipant}
                      min="0"
                      placeholder="Ex : 50"
                      required
                      onChange={(event) => {
                        setMaxParticipant(event.target.value)
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label>Taille de la liste d'attente</label>
                    <input
                      type="number"
                      className="form-control"
                      value={maxWaitingList}
                      min="0"
                      placeholder="Ex : 20"
                      required
                      onChange={(event) => {
                        setMaxWaitingList(event.target.value)
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label>En duo</label>
                    <div className="form-check">
                      <label className="form-check-label" style={{ cursor: "pointer" }}>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={isDuo}
                          onChange={() => setIsDuo(!isDuo)}
                        />
                        Oui
                      </label>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Niveau demandé</label>
                    <div className="form-check">
                      <label className="form-check-label" style={{ cursor: "pointer" }}>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={isLevelRequired}
                          onChange={() => setIsLevelRequired(!isLevelRequired)}
                        />
                        Oui
                      </label>
                    </div>
                  </div>
                </>
              ) : (
                <div className="form-group">
                  <label>Lien d'inscription</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="https://..."
                    value={url}
                    pattern="https:\/\/[a-zA-Z0-9:_\-.\/]{1,240}"
                    title="Lien d'un site internet commençant par https (sécurisé)"
                    required
                    onChange={(event) => {
                      setUrl(event.target.value)
                    }}
                  />
                </div>
              )}
              <div className="form-group">
                <label>Écrans sur lesquels diffuser</label>
                <Select onChange={setTotemsSelected} isMulti={true} options={totems} name="totemsSelected" />
              </div>
              <p className="text-danger">{error}</p>
            </LoadingCard>
          </div>
          <div className="card-footer">
            <button type="submit" className="btn btn-primary">
              Valider
            </button>
            <button type="button" className="btn btn-default float-right" onClick={() => navigate(-1)}>
              Annuler
            </button>
          </div>
        </form>
      </div>
      {adSelected && <AdPreviewCard adId={adSelected} />}
    </>
  )
}
