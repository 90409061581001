import React, { useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import API from "../utils/API"
import Sweetalert from "../utils/Sweetalert"
import ButtonCollapse from "./ButtonCollapse"
import Select from "./Select"
import ButtonRefresh from "./ButtonRefresh"
import LoadingCard from "./LoadingCard"
import AdPreviewCard from "./AdPreviewCard"

export default function ClubNewsDetails({ news, newsId, refreshNews }) {
  // State
  const navigate = useNavigate()
  const [totems, setTotems] = useState([])
  const [ads, setAds] = useState([])
  const [totemsLoaded, setTotemsLoaded] = useState(false)
  const [adsLoaded, setAdsLoaded] = useState(false)
  const [editNews, setEditNews] = useState(false)

  const [newsTitle, setNewsTitle] = useState("")
  const [newsAd, setNewsAd] = useState("")
  const [newsTotems, setNewsTotems] = useState([])

  const [error, setError] = useState("")

  // Comportements
  const getTotems = () => {
    API.GET("/club/totem", (response) => {
      setTotems(response.data)
      setTotemsLoaded(true)
    })
  }

  const getAds = () => {
    API.GET("/ad", (response) => {
      setAds(response.data)
      setAdsLoaded(true)
    })
  }

  useMemo(() => {
    getTotems()
    getAds()
  }, [])

  const adsAvailable = useMemo(() => {
    return ads.filter((ad) => ad.adTypeId === 2 || ad.adTypeId === 3) // Filtre sur les Images & PDF
  }, [ads])

  const item = useMemo(() => {
    if (news.length > 0) {
      let item = news.find((item) => item.id === parseInt(newsId))
      setNewsTitle(item.title)
      setNewsAd(item.ad.id)
      setNewsTotems(item.totems)
      return item
    } else return null
  }, [news, newsId])

  const handleSubmit = (event) => {
    event.preventDefault()
    setError("")

    Sweetalert.fireConfirm(
      "Validez vous ces données ?",
      "Les données seront mises à jour instantanéments sur les écrans associés",
      () => {
        API.PUT(
          "/club/news/" + newsId,
          {
            title: newsTitle,
            adId: newsAd,
            totemsSelected: newsTotems,
          },
          (response) => {
            Sweetalert.fireSuccessToast(response.data.message)
            refreshNews()
            setEditNews(false)
          },
          setError
        )
      }
    )
  }

  // Rendu
  return editNews ? (
    <>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Détails de l'actualité</h3>
          <div className="card-tools">
            <ButtonCollapse />
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="card-body" style={{ display: "block" }}>
            <div className="form-group">
              <label>Titre</label>
              <input
                type="text"
                className="form-control"
                placeholder="Titre"
                value={newsTitle}
                pattern="[a-zA-Z0-9\-_.!?áàâäçéèêëíìîïñóòôöúùûüýÿæœ ]{1,255}"
                title="Lettres, chiffres, espaces et caractères suivants : -_.!? entre 1 et 255 caractères"
                required
                onChange={(event) => {
                  setNewsTitle(event.target.value)
                }}
              />
            </div>
            <div className="form-group">
              <label>Ressource à afficher</label>
              <Select onChange={setNewsAd} options={adsAvailable} defaultValue={newsAd} name="adId" />
            </div>
            <div className="form-group">
              <label>Écrans sur lesquels diffuser</label>
              <Select
                onChange={setNewsTotems}
                isMulti={true}
                options={totems}
                defaultValue={newsTotems}
                name="totemsSelected"
              />
            </div>
            <p className="text-danger">{error}</p>
          </div>
          <div className="card-footer">
            <button type="submit" className="btn btn-primary">
              Valider
            </button>
            <button type="button" className="btn btn-default float-right" onClick={() => setEditNews(false)}>
              Annuler
            </button>
          </div>
        </form>
      </div>
      {newsAd && <AdPreviewCard adId={newsAd} />}
    </>
  ) : (
    <>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Détails de l'actualité</h3>
          <div className="card-tools">
            <ButtonRefresh onClick={() => refreshNews()} />
            <ButtonCollapse />
          </div>
        </div>
        <div className="card-body" style={{ display: "block" }}>
          <LoadingCard loaded={totemsLoaded && adsLoaded}>
            {item && (
              <>
                <div className="row">
                  <div className="col-lg-4">
                    <h5>
                      <b>Titre</b>
                    </h5>
                    <p>{item.title}</p>
                  </div>
                  <div className="col-lg-4">
                    <h5>
                      <b>Ressource</b>
                    </h5>
                    <p>{item.ad.name}</p>
                  </div>
                  <div className="col-lg-4">
                    <h5>
                      <b>Écrans</b>
                    </h5>
                    <p>
                      {item.totems.map((totem) => (
                        <span key={totem.id} className="badge badge-secondary mr-1">
                          {totem.name}
                        </span>
                      ))}
                    </p>
                  </div>
                </div>
              </>
            )}
          </LoadingCard>
        </div>
        <div className="card-footer">
          <button type="button" className="btn btn-primary" onClick={() => setEditNews(true)}>
            <i className="far fa-edit"></i> Éditer
          </button>
          <button type="button" className="btn btn-default float-right" onClick={() => navigate(-1)}>
            <i className="fas fa-arrow-left"></i> Retour
          </button>
        </div>
      </div>
      {item && <AdPreviewCard adId={item.ad.id} />}
    </>
  )
}
