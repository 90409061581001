import React from "react"

export default function LoadingCard({ loaded, children }) {
  return loaded ? (
    children
  ) : (
    <div className="overlay m-5">
      <i className="fas fa-2x fa-sync-alt fa-spin"></i>
    </div>
  )
}
