import React, { useMemo, useState } from "react"
import API from "../utils/API"
import Sweetalert from "../utils/Sweetalert"
import Select from "./Select"

export default function TotemManageDetailsTeamForm({ totemId, teams, totemTeams, getTotem, setNewTeam }) {
  // State
  const [newTeamId, setNewTeamId] = useState("")
  const [newTeamDuration, setNewTeamDuration] = useState("")
  const [error, setError] = useState("")

  // Comportements
  const teamsAvailable = useMemo(() => {
    let teamsAlreadyAdded = []
    totemTeams.forEach((team) => teamsAlreadyAdded.push(team.id))
    return teams.filter((team) => !teamsAlreadyAdded.includes(team.id))
  }, [teams, totemTeams])

  const handleSubmit = (event) => {
    event.preventDefault()
    setError("")

    Sweetalert.fireConfirm(
      "Validez vous ces données ?",
      "Une fois validé, l'équipe aura accès à certaines fonctionnalités de ce totem",
      () => {
        API.POST(
          "/admin/team/" + newTeamId + "/totem",
          {
            totemId: totemId,
            duration: newTeamDuration,
          },
          (response) => {
            Sweetalert.fireSuccessToast(response.data.message)
            getTotem(totemId)
            setNewTeam(false)
          }
        )
      }
    )
  }

  // Rendu
  return (
    <div className="card card-primary">
      <div className="card-header">
        <h3 className="card-title">Ajout d'une nouvelle équipe</h3>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="card-body">
          <div className="form-group">
            <label>Équipe</label>
            <Select onChange={setNewTeamId} options={teamsAvailable} name="teamId" />
          </div>
          <div className="form-group">
            <label>Durée de diffusion (en secondes)</label>
            <input
              type="number"
              className="form-control"
              value={newTeamDuration}
              min="1"
              required
              onChange={(event) => {
                setNewTeamDuration(event.target.value)
              }}
            />
          </div>
          <p className="text-danger">{error}</p>
        </div>
        <div className="card-footer">
          <button type="submit" className="btn btn-primary">
            Valider
          </button>
          <button type="button" className="btn btn-default float-right" onClick={() => setNewTeam(false)}>
            Annuler
          </button>
        </div>
      </form>
    </div>
  )
}
