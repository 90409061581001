import React from "react"
import ButtonLink from "./ButtonLink"
import API from "../utils/API"
import Sweetalert from "../utils/Sweetalert"

export default function AdCardFolder({ folderName, folderId, setCurrentAdPath, deleteAdPath }) {
  // State

  // Comportements
  const handleDelete = (adPathId) => {
    Sweetalert.fireDeleteConfirm(
      "Êtes-vous sûr ?",
      "Supprimer un dossier remettra les ressources qu'il contient dans le dossier racine",
      () => {
        API.DELETE("/ad/path/" + adPathId, (response) => {
          Sweetalert.fireSuccessToast(response.data.message)
          deleteAdPath(adPathId)
        })
      }
    )
  }

  // Rendu
  return (
    <li className="pt-4 pb-4" style={{ height: "200px" }}>
      <ButtonLink
        style={{ width: "100%" }}
        type="button"
        onClick={() => {
          setCurrentAdPath(folderId)
        }}
      >
        <i className="fas fa-folder" style={{ fontSize: "3rem", color: "#007bff" }}></i>
        <span className="users-list-name">{folderName}</span>
      </ButtonLink>
      {deleteAdPath !== null && (
        <button className="btn btn-danger btn-xs" onClick={() => handleDelete(folderId)}>
          <i className="fas fa-trash"></i> Supprimer
        </button>
      )}
    </li>
  )
}
