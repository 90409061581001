import React from "react"
import ReactSelect from "react-select"

export default function SelectEdit({ onSubmit, onCancel, onChange, options, defaultValue, isDisabled, name }) {
  return (
    <div className="input-group input-group-sm float-left" style={{ width: "100%" }}>
      <ReactSelect
        onChange={(option) => {
          onChange(option.id)
        }}
        placeholder={"Sélectionner..."}
        menuPortalTarget={document.querySelector("body")}
        defaultValue={options.find((option) => option.id === defaultValue)}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        noOptionsMessage={() => "Aucune option disponible"}
        options={options}
        isDisabled={isDisabled}
        name={name}
        required={true}
      />
      <div className="input-group-append">
        <button className="input-group-text" onClick={onSubmit} style={{ backgroundColor: "#3085d6" }}>
          <i className="fas fa-check" style={{ color: "white" }}></i>
        </button>
      </div>
      <div className="input-group-append">
        <button className="input-group-text" onClick={onCancel} style={{ backgroundColor: "#d33" }}>
          <i className="fas fa-times" style={{ width: "14px", color: "white" }}></i>
        </button>
      </div>
    </div>
  )
}
