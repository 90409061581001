import MenuCommon from "./SidebarMenuCommon"

function MenuAnnonceur() {
  return (
    <>
      <MenuCommon />
    </>
  )
}

export default MenuAnnonceur
