import React, { useMemo, useState } from "react"
import TotemValidateTableItem from "./TotemValidateTableItem"
import ButtonCollapse from "./ButtonCollapse"
import ButtonRefresh from "./ButtonRefresh"
import LoadingCard from "./LoadingCard"
import API from "../utils/API"

export default function TotemValidateTable() {
  // State
  const [loaded, setLoaded] = useState(false)
  const [totems, setTotems] = useState([])

  // Comportements
  const getTotems = () => {
    API.GET("/admin/totem/validate", (response) => {
      setTotems(response.data)
      setLoaded(true)
    })
  }

  useMemo(() => {
    getTotems()
  }, [])

  const deleteTotem = (id) => {
    let totemsCopy = totems.filter((totem) => totem.id !== id)
    setTotems(totemsCopy)
  }

  // Rendu
  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Player à valider</h3>
        <div className="card-tools">
          <a
            className="btn btn-tool"
            href={`${process.env.REACT_APP_API_HOST}/admin/playerapp`}
            target="_blank"
            rel="noreferrer"
            title="Télécharger la dernière version"
          >
            <i className="fas fa-download"></i>
          </a>
          <ButtonRefresh onClick={getTotems} />
          <ButtonCollapse />
        </div>
      </div>
      <div className="card-body table-responsive p-0" style={{ display: "block" }}>
        <LoadingCard loaded={loaded}>
          {totems.length > 0 ? (
            <table className="table table-striped projects text-nowrap">
              <thead>
                <tr>
                  <th>Identifiant</th>
                  <th>Ajouté le</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {totems.map((totem) => (
                  <TotemValidateTableItem totem={totem} deleteTotem={deleteTotem} key={totem.id} />
                ))}
              </tbody>
            </table>
          ) : (
            <div className="alert alert-success m-3">
              <h5>
                <i className="icon fas fa-check" /> Rien à valider !
              </h5>
              Il n'y a plus de Player à valider pour le moment.
            </div>
          )}
        </LoadingCard>
      </div>
    </div>
  )
}
