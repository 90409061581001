import Sweetalert from "./Sweetalert"

const getAxiosErrorMessage = (error) => {
  if (error.response) {
    let message = ""
    let status = error.response.status
    switch (true) {
      case status === 302:
        message = "Vous êtes déconnecté, redirection vers la page de login..."
        setTimeout(() => {
          window.location.replace("/login")
        }, 2000)
        break

      case /^4/.test(status):
        message = error.response.data.message
        break

      case /^5/.test(status):
        message = "Une erreur serveur est survenue. Merci de reporter le problème à l'administrateur."
        break

      default:
        message = "Une erreur est survenue lors du traitement de la requête."
        break
    }
    return message
  } else return "Erreur de communication avec l'API, veuillez contacter un administrateur."
}

const handleAxiosError = (error, callbackError) => {
  let message = getAxiosErrorMessage(error)
  if (message) {
    if (callbackError) callbackError(message)
    else Sweetalert.fireErrorToast(message)
  }
}

const exportedObject = { getAxiosErrorMessage, handleAxiosError }

export default exportedObject
