import React, { useMemo, useState } from "react"
import TotemManageTableItem from "./TotemManageTableItem"
import ButtonCollapse from "./ButtonCollapse"
import ButtonRefresh from "./ButtonRefresh"
import InputSearch from "./InputSearch"
import ButtonLink from "./ButtonLink"
import LoadingCard from "./LoadingCard"
import API from "../utils/API"

export default function TotemManageTable() {
  // State
  const [loaded, setLoaded] = useState(false)
  const [totems, setTotems] = useState([])
  const [totemsFiltered, setTotemsFiltered] = useState([])
  const [search, setSearch] = useState("")
  const [actualStatusFilter, setActualStatusFilter] = useState(0)
  const statusFiltersComp = [
    <span className="badge badge-info">T</span>,
    <span className="badge badge-success">E</span>,
    <span className="badge badge-danger">H</span>,
  ]

  // Comportements
  const getTotems = () => {
    API.GET("/admin/totem", (response) => {
      setTotems(response.data)
      setLoaded(true)
    })
  }

  useMemo(() => {
    getTotems()
  }, [])

  const deleteTotem = (id) => {
    let totemsCopy = totems.filter((totem) => totem.id !== id)
    setTotems(totemsCopy)
  }

  const handleStatusFilter = () => {
    let newFilter = (actualStatusFilter + 1) % statusFiltersComp.length
    setActualStatusFilter(newFilter)
  }

  useMemo(() => {
    const statusFilters = [[false, true], [true], [false]]

    if (totems) {
      let statusFilter = statusFilters[actualStatusFilter]
      let totemsFilteredCopy = totems.filter(
        (totem) =>
          statusFilter.includes(totem.state) &&
          (totem.name.toLowerCase().includes(search) ||
            totem.address.toLowerCase().includes(search) ||
            totem.establishmentType.name.toLowerCase().includes(search))
      )
      setTotemsFiltered(totemsFilteredCopy)
    }
  }, [search, actualStatusFilter, totems])

  // Rendu
  return (
    <div className="row">
      <div className="col">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">Liste des écrans validés</h3>
            <div className="card-tools">
              <InputSearch value={search} onChange={(event) => setSearch(event.target.value.toLowerCase())} />
              <ButtonRefresh onClick={getTotems} />
              <ButtonCollapse />
            </div>
          </div>
          <div className="card-body table-responsive p-0" style={{ display: "block" }}>
            <LoadingCard loaded={loaded}>
              {totems.length > 0 ? (
                <table className="table table-striped projects text-nowrap">
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Nom</th>
                      <th>Adresse</th>
                      <th>
                        Statut{" "}
                        <ButtonLink className={"p-0"} onClick={handleStatusFilter}>
                          {statusFiltersComp[actualStatusFilter]}
                        </ButtonLink>
                      </th>
                      <th>Écran</th>
                      <th>Etabl.</th>
                      <th>Version</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {totemsFiltered.map((totem) => (
                      <TotemManageTableItem totem={totem} deleteTotem={deleteTotem} key={totem.id} />
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="alert alert-info m-3">
                  <h5>
                    <i className="icon fas fa-info" /> Rien !
                  </h5>
                  Il n'y a pas encore de totem avec le logiciel installé et validé.
                </div>
              )}
            </LoadingCard>
          </div>
        </div>
      </div>
    </div>
  )
}
